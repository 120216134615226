import React from 'react';
import './PressCoverage.scss';
import { Link } from "react-router-dom";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Footer from '../Components/Footer/Footer'
import { connect } from 'react-redux';
import { getpress } from '../../redux/actions'
import moment from 'moment';

class PressCoverage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  componentDidMount() {

    this.props.getpress()
  }

  render() {
    document.title = "InMemoryOf | Press Coverage";
    document.getElementsByTagName("META")[1].content = "IN MEMORY OF was created to help family and friends commemorate their loved ones through memorial donations";
    document.documentElement.lang = 'en';
    return (
      <div className="ConatactUS">
        <div className="navhe"></div>
        <div className="container">
          <div className="py-2">
            <Link to="" className="fz12 fzcm px-2 fw500">الرئيسية</Link>
            <span className="fz12 fw500 mt-2">&gt;	</span>
          </div>
          <p className="borderdashed m-0"></p>
          {/* <h3 className="fzcblack black fz24 fw500 py-3 ">من نحن</h3>
          <p className="fzcm fz16 fw500 m-0">وكلاء خدمة العملاء دائما هنا من اجلك. جميع اسئلتكم سوف يتم الرد عليها سواء كانت كبيره او صغيره. نرجوا منك البحث عن اجابه لسؤالك ضمن الأسئلة الشائعة أو </p>
          <p className="fz16 fw500">التواصل معنا عبر نموذج الاستفسارات او من خلال الايميل الخاص بنا</p> */}
        </div>
        <section className="container">
          {/* {this.props.AllPressCoverage ? this.props.AllPressCoverage.map((x, i) => {
            return (
              <React.Fragment key={i}>
                <a href={x.coverage_link} target="_block" className=" row m-0   d-flex align-items-center" key={i}>
                  <div className="col-md-2 text-center">
                    <figure className="figure">
                      <img src={x.coverage_image} className="" alt="coverage_image" style={{ width: "100%", height: 110, objectFit: "contain" }} />
                    </figure>
                  </div>
                  <div className="col-md-10">
                    <h3 className="fz16 fw500">{x.coverage_by}</h3>
                    <p className=" fz14 fzcmuted mb-1"> {moment(x.coverage_tstamp).format('LL')}</p>
                    <p className="fz18 fw500 fh35 pt-1" >{x.about_us}</p>
                  </div>
                </a>
                <p className="borderdashed"></p>
              </React.Fragment>
            )
          }) : ''} */}

          <p className="text-center fz24 fw600 p-3">coming soon</p>
          {/* <p className="borderdashed"></p> */}

          {/* <p   className="btn bgcwhite pointer col mx-2 bordercm fzcm  fw500 px-4 my-3 fz18" >
                حمل المزيد
                <img src={arrowcm} alt="arrow"/>
              </p> */}
        </section>

        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    AllPressCoverage: state.NGOS.AllPressCoverage
  };
}
const mapDispatchToProps = ({
  getpress: getpress
})
export default connect(mapStateToProps, mapDispatchToProps)(PressCoverage);