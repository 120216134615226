import React from "react";
import "./CampaignDonation.scss";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { createDonation, getAreas, getcities,HandleSuccessPayment,HandlecancelledPayment, HandleErrorPayment } from "../../redux/actions/index";

import Favorite from "../../Images/Favorite.svg";
import informationbutton from "../../Images/informationbutton.svg";
import Visa from "../../Images/Visa.png";
import close from "../../Images/close.svg";
import { LINKIMAGES } from "../../redux/actions/types";
import Loading from "../../Images/loading.svg";

class Modalpayment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      donation: {
        user_id: null,
        user_name: "",
        email: "",
        msisdn: "",
        amount: "",
        channel: 1,
        campaign_id: "",
        city: null,
        area: null,
        address: "",
        comment: "",
        hide_data: false,
        type: false,
        payment_status: 1,
        ngoId: 0
      }
    };
  }
  componentDidMount() {
    this.props.getcities();
    
    if (this.props.IsLoggedIn) {
      this.setState({
        donation: {
          ...this.state.donation,
          user_id: this.props.user.user_id ? this.props.user.user_id : null,
          user_name: this.props.user.user_name,
          email: this.props.user.email,
          hide_data: this.props.user.hide_data,
          ngoId: this.props.campaignDetails.ngo_id
        }
      });
    }
    // const script = document.createElement('script');

    // script.src = "https://banquemisr.gateway.mastercard.com/checkout/version/55/checkout.js";
    // script.data-error = "errorCallback";
    // script.data-cancel = "cancelCallback";
    // script.data-complete = "successCallBack";
    // script.async = true;
    console.log(window)
  }

  handleChangeradio = x => {
    console.log(x);
    this.setState({
      // ...this.state,
      donation: {
        ...this.state.donation,
        type: x,
        campaign_id: this.props.campaignDetails.campaign_id,
        ngoId: this.props.campaignDetails.ngo_id
      }
    });
  };
  handleChangeChackbox = e => {
    this.setState({
      // ...this.state,
      donation: {
        ...this.state.donation,
        hide_data: !this.state.donation.hide_data
      }
    });
  };
  handleChange = e => {
    this.setState({
      // ...this.state,
      donation: {
        ...this.state.donation,
        [e.target.name]: e.target.value
      }
    });
    console.log(this.state);
  };

  handleChangecities = e => {
    this.setState(
      {
        // ...this.state,
        donation: {
          ...this.state.donation,
          [e.target.name]: e.target.value
        }
      },
      () => this.props.getAreas(this.state.donation.city)
    );
    console.log(this.state);
  };
  validate = () => {
    let erroruser_name = "";
    let erroremail = "";
    let errormsisdn = "";
    let erroramount = "";
    let errorcity = "";
    let errorarea = "";
    let erroraddress = "";
    let errorcash = "";

    if (this.state.donation.user_name.length < 2) {
      erroruser_name = "اكتب الاسم  صحيح";
    } else {
      if (!this.state.donation.user_name.match(/^[ء-ي0-9 a-zA-Z]+$/)) {
        erroruser_name = "يجب ان لا تحتوي علي اي رموز  :!@#$%&";
      }
    }
    if (!this.state.donation.email.includes("@")) {
      erroremail = "بريد الكتروني غير صحبح";
    }
    if (this.state.donation.type !== 2) {
      if (this.state.donation.msisdn == null) {
        errormsisdn = "رقم الهاتف غير صحيح يجب ان يبداء ب *********01";
      } else {
        if (
          isNaN(this.state.donation.msisdn) ||
          this.state.donation.msisdn.length !== 11
        ) {
          errormsisdn = "رقم الهاتف غير صحيح يجب ان يبداء ب *********01";
        }
      }
    }
    if (isNaN(this.state.donation.amount) || this.state.donation.amount < 100) {
      erroramount = "اقل قيمه هي 100 جنيه";
    }
    if (this.state.donation.type === 1 && this.state.donation.city < 1) {
      errorcity = "يجب اختيار المحافظه";
    }
    if (this.state.donation.type === 1 && this.state.donation.area < 1) {
      errorarea = "يجب اختيار المنطقه";
    }
    if (
      this.state.donation.type === 1 &&
      this.state.donation.address.length < 10
    ) {
      erroraddress = "يجب كتابة عنوان تفصيلي ";
    }
    if (this.state.donation.type === false) {
      errorcash = "يجب اختيار طريقة الدفع";
    }
    if (
      erroruser_name ||
      erroremail ||
      errormsisdn ||
      erroramount ||
      errorcity ||
      errorarea ||
      erroraddress ||
      errorcash
    ) {
      this.setState({
        erroruser_name,
        erroremail,
        errormsisdn,
        erroramount,
        errorcity,
        errorarea,
        erroraddress,
        errorcash
      });
      return false;
    }
    return true;
  };
  handleSubmit = e => {
    e.preventDefault();
    // perform all neccassary validations
    const isValid = this.validate();
    if (isValid) {
      this.props.createDonation(this.state.donation);
      this.setState(
        {
          erroruser_email: "",
          erroremail: "",
          errormsisdn: "",
          erroramount: "",
          errorcity: "",
          errorarea: "",
          erroraddress: "",
          errorcash: "",
          donation: {
            user_id: null,
            user_name: "",
            email: "",
            msisdn: null,
            amount: "",
            channel: 1,
            campaign_id: "",
            city: null,
            area: null,
            address: "",
            comment: "",
            hide_data: false,
            type: false
          }
        },
        () => {
          if (this.props.IsLoggedIn) {
            this.setState({
              donation: {
                ...this.state.donation,
                user_id: this.props.user.user_id
                  ? this.props.user.user_id
                  : null,
                user_name: this.props.user.user_name,
                email: this.props.user.email,
                hide_data: this.props.user.hide_data
              }
            });
          }
        }
      );
    }
  };
  render() {
    //console.log(this.props.campaignDetails);
    return (
      <div
        className="modal fade bd-example-modal-xl modalpayment"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myExtraLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <a id="mylink" href="" target="_blank"></a>
          {this.props.Loading == "PAY_L" ? (
            <div className="LoadingPOPUP text-center pt-5">
              <img
                src={Loading}
                alt="Loading"
                style={{ width: 200, right: "45%", top: "30%" }}
                className="mt-md-5 fixed-top"
              />
            </div>
          ) : (
            ""
          )}
          <form onSubmit={this.handleSubmit}>
            <div className="modal-content p-2">
              <div className="px-md-4 d-flex justify-content-between">
                <div className="d-inline p-1">
                  <p className="fz18 fw500 fzcblack d-inline">
                    {" "}
                    حملة تبرع جماعي لإحياء ذكري{" "}
                  </p>
                  <p className="fz22 fw500 d-inline">
                    {" "}
                    {this.props.campaignDetails.campaign_title}{" "}
                  </p>
                </div>
                <img
                  id="closemodal"
                  src={close}
                  alt="close"
                  className="mt-1"
                  data-dismiss="modal"
                  style={{ width: 30 }}
                />
              </div>
              <hr />
              <div className="row m-0">
                <div className="col-md-6">
                  <p className="fz16 fw500 px-2 m-0 fzcblack  pt-0 pb-3">
                    <span className="fzcmuted px-1 "> لصالح </span>
                    {this.props.campaignDetails.project_ar_name}
                  </p>
                  <div className="row m-0">
                    <label
                      htmlFor="amount"
                      className="col-md-2 px-0 m-0 col-form-label  fz16 fw500 fzcmuted"
                    >
                      قيمة التبرع <span className="require">*</span>
                    </label>
                    <div className=" col-md-5 col-10 ">
                      <input
                        type="text"
                        className="form-control  "
                        id="amount"
                        placeholder="اقل قيمة للتبرع 100 جنيه مصرى"
                        name="amount"
                        value={this.state.donation.amount}
                        onChange={this.handleChange}
                      />
                      <div
                        style={{ fontSize: 12, color: "red" }}
                        className="py-1 m-0"
                      >
                        {this.state.erroramount}
                      </div>
                    </div>
                    <span>ج.م</span>
                  </div>

                  <div className="row m-0 py-3">
                    <label
                      htmlFor="name"
                      className=" px-0 col-form-label col-sm-12 fz16 fw500 fzcmuted"
                    >
                      طريقة الدفع <span className="require">*</span>
                    </label>
                    <div className=" mx-auto  text-center">
                      <input
                        type="radio"
                        className=""
                        name="cash"
                        id="true"
                        value={true}
                        onChange={() => this.handleChangeradio(1)}
                        checked={
                          this.state.donation.type === 1 ? "checked" : ""
                        }
                        placeholder=""
                      />
                      <p className=" fz16 fw500 fzcmuted m-1" htmlFor="true">
                        {" "}
                        نقدى
                      </p>
                      <p className=" fz14 fw500 fzcmuted">
                        تُحَصَل عن طريق مندوب
                      </p>
                    </div>
                    {this.props.campaignDetails.EPayment ? (
                      <div className=" mx-auto  text-center">
                        <input
                          type="radio"
                          className=""
                          name="cash"
                          id="false"
                          value={false}
                          onChange={() => this.handleChangeradio(2)}
                          checked={
                            this.state.donation.type === 2 ? "checked" : ""
                          }
                          placeholder=""
                        />
                        <p className=" fz16 fw500 fzcmuted m-1" htmlFor="false">
                          بالبطاقة البنكية
                        </p>
                        <img src={Visa} alt="Visa" />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div
                    style={{ fontSize: 12, color: "red" }}
                    className="py-1 m-0"
                  >
                    {this.state.errorcash}
                  </div>
                </div>
                <div className="col-md-6 p-3 px-md-5 mt-md-4">
                  <div className="bordercm2  bgcwhite b_r  m">
                    <div className="col-12 text-left  px-2">
                      <img
                        src={informationbutton}
                        alt="iconmoreinfo"
                        className="text-left"
                        style={{ width: 15 }}
                        data-toggle="tooltip"
                        data-placement="top"
                        title="وحدة اكتمل تمويلها الى الآن"
                      />
                    </div>
                    <div className="row m-0 px-3 pb-4">
                      <div className="col-10 px-0 py-0">
                        <p className="fzcmuted m-0 fz16">تم تحصيل</p>
                        <p className="fzcm fz24 mb-1  fw600 m-0 py-1">
                          {this.props.thousands_separators(
                            this.props.campaignDetails.campaign_collected
                          )}{" "}
                          <span className="fz14 fw300 fzcblack">
                            {" "}
                            من{" "}
                            <span className="fw600 fz14">
                              {" "}
                              {this.props.thousands_separators(
                                this.props.campaignDetails.project_amount
                              )}{" "}
                            </span>{" "}
                            ج.م{" "}
                          </span>
                        </p>
                        <div className="progress " style={{ height: 10 }}>
                          <div
                            className="progress-bar bgcm"
                            role="progressbar"
                            style={{
                              width:
                                (this.props.campaignDetails.campaign_collected /
                                  this.props.campaignDetails.project_amount) *
                                  100 +
                                "%"
                            }}
                            aria-valuenow={75}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          ></div>
                        </div>
                      </div>
                      <div className="col-2 p-1 d-flex">
                        <div className="hrv"></div>
                        <div className="">
                          <p className="fzcm fz20 text-center fw600 m-0 mt-2">
                            {this.props.thousands_separators(
                              this.props.campaignDetails.units_completed
                            )}{" "}
                          </p>
                          <p className="fzcm fz12 text-center m-0">
                            وحدة اكتمل تمويلها الى الآن
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="px-md-5">
                <p className="fz18 fw500 px-2 m-0 mx-3  fzcblack labelstyle2">
                  بيانات المتبرع
                </p>
                <div className=" b_r border row p-3 m-0">
                  {this.props.IsLoggedIn ? (
                    <div className="form-group col-md-6 py-1 m-0  row">
                      <label
                        htmlFor="user_name"
                        className="col-sm-2 px-0 fz16 fw500 fzcmuted col-form-label d-flex justify-content-between m-auto"
                      >
                        الاسم <span className="require">*</span>
                      </label>
                      <div className="col-sm-9 col-md-8 m-auto">
                        <input
                          type="text"
                          className="form-control"
                          name="user_name"
                          value={this.state.donation.user_name}
                          onChange={this.handleChange}
                        />
                        <div
                          style={{ fontSize: 12, color: "red" }}
                          className="py-1 m-0"
                        >
                          {this.state.erroruser_name}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="form-group col-md-6 py-1 m-0  row">
                      <label
                        htmlFor="user_name"
                        className="col-sm-2 px-0 fz16 fw500 fzcmuted col-form-label d-flex justify-content-between m-auto"
                      >
                        الاسم <span className="require">*</span>
                      </label>
                      <div className="col-sm-9 col-md-8 m-auto">
                        <input
                          type="text"
                          className="form-control"
                          name="user_name"
                          value={this.state.donation.user_name}
                          onChange={this.handleChange}
                        />
                        <div
                          style={{ fontSize: 12, color: "red" }}
                          className="py-1 m-0"
                        >
                          {this.state.erroruser_name}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="form-group col-md-6 py-1 m-0  row">
                    <label
                      htmlFor="msisdn"
                      className="col-sm-2 fz16 fw500 fzcmuted px-0 col-form-label d-flex justify-content-between m-auto"
                    >
                      {" "}
                      الهاتف
                      {this.state.donation.type == 2 ? (
                        ""
                      ) : (
                        <span className="require">*</span>
                      )}
                    </label>
                    <div className="col-sm-9 col-md-8 m-auto">
                      <input
                        type="text"
                        className="form-control"
                        id="msisdn"
                        name="msisdn"
                        value={this.state.donation.msisdn}
                        onChange={this.handleChange}
                      />
                      <div
                        style={{ fontSize: 12, color: "red" }}
                        className="py-1 m-0"
                      >
                        {this.state.errormsisdn}
                      </div>
                    </div>
                  </div>

                  {this.props.IsLoggedIn ? (
                    <div className="form-group col-md-6 py-1 m-0  row">
                      <label
                        htmlFor="email"
                        className="col-sm-2 px-0 fz16 fw500 fzcmuted col-form-label d-flex justify-content-between m-auto"
                      >
                        {" "}
                        البريد الالكتروني <span className="require">
                          *
                        </span>{" "}
                      </label>
                      <div className="col-sm-9 col-md-8 m-auto">
                        <input
                          type="text"
                          className="form-control"
                          id="email"
                          name="email"
                          value={this.props.user.email}
                          disabled
                        />
                        <div
                          style={{ fontSize: 12, color: "red" }}
                          className="py-1 m-0"
                        >
                          {this.state.erroremail}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="form-group col-md-6 py-1 m-0  row">
                      <label
                        htmlFor="email"
                        className="col-sm-2 px-0 fz16 fw500 fzcmuted col-form-label d-flex justify-content-between m-auto"
                      >
                        {" "}
                        البريد الالكتروني <span className="require">
                          *
                        </span>{" "}
                      </label>
                      <div className="col-sm-9 col-md-8 m-auto">
                        <input
                          type="text"
                          className="form-control"
                          id="email"
                          name="email"
                          value={this.state.donation.email}
                          onChange={this.handleChange}
                        />
                        <div
                          style={{ fontSize: 12, color: "red" }}
                          className="py-1 m-0"
                        >
                          {this.state.erroremail}
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.donation.type === 1 ? (
                    <>
                      <div className="text-center fzcbs col-12 mb-2">
                        <span className="fzcblack fz18 fw500 labelstyle2 px-1">
                          عنوان لتحصيل قيمة التبرع
                        </span>

                        <p className="borderdashed mx-3"></p>
                      </div>

                      <div className="form-group col-md-6 m-0 py-1 row ">
                        <label
                          htmlFor="city"
                          className="col-sm-2 px-0 fz16 fw500 fzcmuted col-form-label m-auto d-flex justify-content-between"
                        >
                          المحافظة <span className="require">*</span>
                        </label>
                        <div className="col-sm-9 col-md-8 m-auto">
                          <select
                            className="form-control"
                            id="city"
                            name="city"
                            value={this.state.donation.city}
                            onChange={this.handleChangecities}
                          >
                            <option value="0">اختار المحافظه</option>

                            {this.props.cities.map((x, i) => {
                              return (
                                <>
                                  <option value={x.id}>{x.ar_name}</option>
                                </>
                              );
                            })}
                          </select>
                          <div
                            style={{ fontSize: 12, color: "red" }}
                            className="py-1 m-0 "
                          >
                            {this.state.errorcity}
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-md-6  m-0 py-1 row">
                        {this.props.Areas.length > 0 ? (
                          <>
                            <label
                              htmlFor="area"
                              className="col-sm-2 px-0 fz16 fw500 fzcmuted col-form-label m-auto d-flex justify-content-between"
                            >
                              اختر المنطقة <span className="require">*</span>
                            </label>
                            <div className="col-sm-9 col-md-8 m-auto">
                              <select
                                className="form-control"
                                name="area"
                                id="area"
                                value={this.state.donation.area}
                                onChange={this.handleChange}
                              >
                                <option value="0">اختار المنطقه </option>

                                {this.props.Areas.map((x, i) => {
                                  return (
                                    <option key={i} value={x.id}>
                                      {x.ar_name}
                                    </option>
                                  );
                                })}
                              </select>

                              <div
                                style={{ fontSize: 12, color: "red" }}
                                className="py-1 m-0  "
                              >
                                {this.state.errorarea}
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="form-group col-md-6  m-0 py-1 row ">
                        <label
                          htmlFor="address"
                          className="col-sm-2 px-0 fz16 fw500 fzcmuted col-form-label m-auto d-flex justify-content-between"
                        >
                          العنوان<span className="require">*</span>
                        </label>
                        <div className="col-sm-9 col-md-8 m-auto">
                          <input
                            type="text"
                            className="form-control"
                            id="address"
                            name="address"
                            value={this.state.donation.address}
                            onChange={this.handleChange}
                          />
                          <div
                            style={{ fontSize: 12, color: "red" }}
                            className="py-1 m-0 "
                          >
                            {this.state.erroraddress}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <div className="form-group col-12  m-0 py-3 row ">
                  <label
                    htmlFor="comment"
                    className="col-sm-2 px-0 fz16 fw500 fzcmuted col-form-label m-auto "
                  >
                    كلمه للمتوفى
                  </label>
                  <div className=" col-10 p-0">
                    <input
                      type="text"
                      className="form-control mt-2 "
                      id="comment"
                      placeholder="لا تزيد عن 40 حرف"
                      name="comment"
                      value={this.state.donation.comment}
                      onChange={this.handleChange}
                    />
                    <p className="fz14 fzcmuted m-0">
                      سوف تظهر هذه الكلمة بجانب قيمة تبرعك في قائمة المتبرعين
                    </p>
                  </div>
                </div>

                <div className="form-group  m-0 py-3  ">
                  <input
                    type="checkbox"
                    className="fz16 d-inline mx-2 rela"
                    id="hide_data"
                    name="hide_data"
                    placeholder="لا تزيد عن 40 حرف"
                    value={this.state.donation.hide_data}
                    onClick={this.handleChangeChackbox}
                    checked={this.state.donation.hide_data ? "checked" : ""}
                  />
                  <label
                    htmlFor="hide_data"
                    className="px-0 d-inline fz14 fw500 fzcmuted col-form-label m-auto "
                  >
                    لا اريد اظهار اسمى في قائمة المتبرعين (تظهر قيمة التبرع باسم
                    فاعل خير)
                  </label>
                </div>
              </div>
              <hr />
              <div className="text-center my-2">
                <button
                  type="submit"
                  className="btn btnw bgcm fzcwhite  px-4 m-2 fz20 text-center"
                >
                  <img
                    src={Favorite}
                    className="mx-1"
                    style={{ width: 25 }}
                    alt="arrow"
                  />
                  تبرع
                </button>
                <input type="button" value="Pay with Lightbox" onClick={()=>window.Checkout.showLightbox()}id="showLightbox"style={{display:"none"}} />
                <input type="button"  onClick={()=>this.props.HandleSuccessPayment()}id="HandleSuccessPayment"style={{display:"none"}} />
                <input type="button"  onClick={()=>this.props.HandlecancelledPayment()}id="HandlecancelledPayment"style={{display:"none"}} />
                <input type="button"  onClick={()=>this.props.HandleErrorPayment()}id="HandleErrorPayment"style={{display:"none"}} />

                
                {/* <input type="button" value="Pay with Payment Page" onClick={window.Checkout.showPaymentPage()}id="showPaymentPage" /> */}
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    errorMessageBackEnd: state.Errors.errorMessageBackEnd
      ? state.Errors.errorMessageBackEnd
      : "",
    errorGlobal: state.Errors.errorGlobal ? state.Errors.errorGlobal : "",
    Areas: state.Campaigns.Areas,
    cities: state.Campaigns.cities,
    IsLoggedIn: state.User ? state.User.IsLoggedIn : "",
    user: state.User.user,
    Loading: state.Campaigns.Loading ? state.Campaigns.Loading : false
  };
};
const mapDispatchToProps = {
  createDonation: createDonation,
  getcities: getcities,
  getAreas: getAreas,
  HandleSuccessPayment,
  HandlecancelledPayment,
  HandleErrorPayment
};
export default connect(mapStateToProps, mapDispatchToProps)(Modalpayment);
