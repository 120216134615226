import React from 'react';
import './CampaignDonation.scss';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { getCampaignsDetails, getProjectsId, getCampaignDonationsDetails, thousands_separators } from '../../redux/actions/index';
import { userAction } from '../../redux/actions/userAction'
import { LINKIMAGES } from "../../redux/actions/types";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Footer from '../Components/Footer/Footer'
import Steps from '../Components/Steps/Steps'
import user from '../../Images/user.svg'
import Favorite from '../../Images/Favorite.svg'
import Share from '../../Images/Share.svg'
import caution from '../../Images/caution.svg'
import mobilesms from '../../Images/mobilesms.svg'
import close from '../../Images/close.svg'
import addwhiteicon from '../../Images/addwhiteicon.svg'
import smsblack from '../../Images/smsblack.svg'
import moneyblack from '../../Images/moneyblack.svg'
import fundsblack from '../../Images/fundsblack.svg'
import creditcardblack from '../../Images/creditcardblack.svg'
import enterpriseblack from '../../Images/enterpriseblack.svg'
import informationbutton from '../../Images/informationbutton.svg'
import Modalpayment from './Modalpayment'
import ModalSuccess from './ModalSuccess'
import moment from 'moment';
import 'moment/locale/ar';  // without this line it didn't work
import star from '../../Images/star.svg';
import star2 from '../../Images/star2.svg';
import $ from 'jquery'
class CampaignDonation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isfav: false,
      unfav: false,
      do: true
    }
  }
  componentDidMount() {

    const id = this.props.match.params.id
    this.props.getCampaignsDetails(id, this.props.uid)
    this.props.getCampaignDonationsDetails(id)
    $(function () {
      $('[data-toggle="tooltip"]').tooltip()
    })
  }
  //  window =function successCallBack(res) {
  //   // confirm donation_id
  //   var myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/json");
  //   var donation_id=localStorage.getItem("donation_id")
  //   console.log(donation_id)
  //   var raw = JSON.stringify(donation_id);
  //   var requestOptions = {
  //     method: 'POST',
  //     headers: myHeaders,
  //     body: raw,
  //     redirect: 'follow'
  //   };
  //   fetch("http://inmemoryof.com/OnlinePayment/api/hostedcheckout/Save", requestOptions)
  //     .then(response => response.text())
  //     .then(result => console.log(
  //       setTimeout(function(){ 
  //         document.getElementById("closemodal").click(); document.getElementById("ModalSuccess").click();  }, 2000)))
  //     .catch(error => console.log('error', error));
  //     console.log('Payment success');
  // }
  componentWillReceiveProps() {
    // this.setState({isfav:this.props.campaignDetails.isFavorite })
    if (this.state.do && this.props.campaignDetails.isFavorite !== undefined) {
      this.setState({ isfav: this.props.campaignDetails.isFavorite, do: false })
    }
  }

  clickfav = (x) => {
    this.props.postUserfavourite(x.campaign_id)
    this.setState({
      isfav: true
    })
  }
  clickunfav = (x) => {
    this.props.deleteUserfavourite(x.campaign_id)
    this.setState({
      isfav: false

    })
  }
  getProjectsId = (id) => {
    this.props.getProjectsId(id)
  }
  handleclosemodle = () => {
    document.getElementById("closemodal2").click();
  }
  render() {


    document.title = "InMemoryOf | " + this.props.campaignDetails.campaign_title;
    document.getElementsByTagName("META")[2].content = window.location.href
    document.getElementsByTagName("META")[4].content = this.props.campaignDetails.campaign_title;
    document.getElementsByTagName("META")[5].content = this.props.campaignDetails.campaign_description
    document.getElementsByTagName("META")[6].content = LINKIMAGES + this.props.campaignDetails.campaign_image


    document.documentElement.lang = 'en';
    return (
      <div className="CampaignDonation" >
        <div className="navhe"></div>
        <div className="container">
          <div className="py-2">
            <Link to="/" className="fz12 fzcm px-2 fw500">الرئيسية</Link>
            <span className="fz12 fw500 mt-2">&gt;	</span>
            <Link to="/Campaigns" className="fz12 fzcm px-2 fw500">اكتشف</Link>
            <span className="fz12 fw500 mt-2">&gt;	</span>
          </div>
          <p className="borderdashed m-0"></p>
          <h3 className="fzcblack black fz24 fw500 py-3 ">{!this.props.campaignCreated ? "حملات قائمة" : "ابدأ حملة تبرعات"}</h3>
        </div>
        {this.props.campaignCreated ?
          <div className="container" >
            <Steps step={3} />
          </div> : ' '
        }
        <section className="Campaigndetails py-3">
          <div className="container">

            <div className="row m-0 pt-3">
              <div className="col-md-8  px-md-2 px-0">
                <p className="fz18 fw500 px-2 mx-3  m-0 fzcblack labelstyle">حملة تبرع جماعي لإحياء ذكري</p>

                <div className=" b_r border row m-0 p-2 detailsdonation">
                  <div className="col-12 p-0 pb-2 text-left ">
                    {this.props.IsLoggedIn ?
                      this.state.isfav ?
                        <img src={star} alt="Favorite" style={{ width: 25 }} onClick={() => this.clickunfav(this.props.campaignDetails)} />
                        :
                        <img src={star2} alt="Favorite" style={{ width: 25 }} onClick={() => this.clickfav(this.props.campaignDetails)} />
                      :
                      <img src={star2} style={{ width: 25 }} className="p-0" alt="image" data-toggle="modal" data-target="#exampleModalScrollable1" />
                    }
                  </div>
                  <div className="col-lg-4 text-center">
                    <figure className=" figure m-0 p-0 relative">
                      {/* <div className="dead"></div> */}
                      <img src={LINKIMAGES + this.props.campaignDetails.campaign_image} className=" m-0 bgcwhite " alt={this.props.campaignDetails.campaign_title} />
                      <figcaption className="fz12 fzcmuted text-center"><p className="m-0"> تاريخ انشاء الحملة :</p> <p> {moment(this.props.campaignDetails.campaign_tstamp).format('LL')}</p></figcaption>
                    </figure>
                  </div>
                  <div className="col-lg-8">
                    <p className="fz22 fw500 m-0">{this.props.campaignDetails.campaign_title}</p>
                    <p className="fz14 fzcmuted fw500 mb-3 m-0">{this.props.campaignDetails.campaign_description}</p>
                    <p className="borderdashed m-1 my-2 "></p>
                    <div className="row justify-content-center ">
                      {!this.props.campaignDetails.EPayment && !this.props.campaignDetails.CashCollection ? '' :
                        <div className="col-lg-6 mb-2">
                          <a data-toggle="modal" data-target=".bd-example-modal-xl" className=" btn bgcm d-block fzcwhite fz20 px-1 fw500" style={{ color: "#fff" }}>
                            <img src={Favorite} className=" m-1  " alt="Favorite" style={{ width: 20 }} />
                            تـــبــــرع
                        </a>
                        </div>
                      }
                      <div className="col-lg-6">


                        <div className=" btn fz20 d-block bordercm fzcm px-1 fw500 fb-share-button" data-href="http://inmemoryof.com/CampaignDonation/%D8%B9%D9%84%D8%A7%D8%A1_%D8%A7%D9%84%D8%B6%D9%88%D9%8A%D9%86%D9%8A/35211" data-layout="button_count" data-size="small">
                          <a className="stylelink fb-xfbml-parse-ignore" target="_blank" href={"https://www.facebook.com/sharer/sharer.php?u=" + window.location.href + "&amp;src=sdkpreparse"}>
                            <img src={Share} className=" m-1  " alt="Share" style={{ width: 20 }} />
                            انشر الحملة
                          </a>
                          {/* <a className="stylelink fb-xfbml-parse-ignore" target="_blank" href={`https://www.facebook.com/sharer/sharer.php?u=http://inmemoryof.com/FBSHARE?campid=${this.props.campaignDetails.campaign_id}&camptitle=${this.props.campaignDetails.campaign_title}&campdescription=${this.props.campaignDetails.campaign_description}&campnimage=${LINKIMAGES + this.props.campaignDetails.campaign_image}`}>
                            <img src={Share} className=" m-1  " alt="Share" style={{ width: 20 }} />
                            انشر الحملة
                          </a> */}
                        </div>
                      </div>

                    </div>
                    <p className="fz16 fw500 p-2 m-0 fzcblack ">
                      <span className="fzcmuted px-1"> لصالح </span>
                      {this.props.campaignDetails.project_ar_name}
                    </p>

                    <div className="bgcwhite bordercm borderdashed">
                      <p className="m-0 p-1 fz14">
                        <img src={caution} alt="caution" className="mx-1" style={{ width: 20 }} />
                        التبرعات تذهب بشكل مباشر للجمعيات الخيرية
                                      </p>
                    </div>

                  </div>
                </div>
                <div className="bgcm my-4 b_r d-flex justify-content-center smssection" >

                  <p className="m-0 p-2  fz20 fzcwhite fw500 text-center ">
                    للتبرع عن طريق ال
                    sms
                    إرسل كود
                        <span className="fzcblack px-1 nowrap">T{" "}{this.props.campaignDetails.smsCode}</span>
                    إلى الرقم
                            <span className="fzcblack px-1">{this.props.campaignDetails.SMSshortNumber}</span>
                    قيمه التبرع 5 ج.م

                            </p>
                  <img src={mobilesms} alt="caution" className="mx-1 " className="smsimg" />

                </div>

                <div className="b_r border row m-0 p-2 ngo">
                  <div className="col-md-3 text-center">
                    <figure className=" figure m-0 p-0 ">
                      <img src={LINKIMAGES + this.props.campaignDetails.project_image} className=" m-0 mt-2 bgcwhite border" alt="project_image" />
                    </figure>
                  </div>
                  <div className="col-md-7" onClick={() => this.getProjectsId(this.props.campaignDetails.project_id)}>
                    <p className="fz22 fw500 m-0">{this.props.campaignDetails.project_ar_name}</p>
                    <p className="fz14 fzcmuted fw500 m-0 fh63">{this.props.campaignDetails.project_description}</p>
                    <p className="fzcm fz14 m-0" data-toggle="modal" data-target=".bd-example-modal-lg">المزيد </p>
                  </div>
                  <div className="col-md-2 d-none d-md-block charity text-center">
                    <figure className=" figure m-0 p-0 ">
                      <figcaption className="fz12 fzcmuted text-center">احد مشروعات</figcaption>
                      <img src={LINKIMAGES + this.props.campaignDetails.ngo_image} className=" m-0 mt-2 bgcwhite border" alt="ngo_image" />
                    </figure>
                  </div>
                </div>


                <p className="fz18 fw500 px-2 mx-3  m-0 fzcblack labelstyle">بيانات منشئ الحملة</p>
                <div className="b_r  border m-0 p-0 ">
                  <p className="fz16 fw500 p-3 m-0"> <span className="fzcmuted px-1"> الاسم  </span>
                    {this.props.campaignDetails.user_name}
                  </p>
                </div>

              </div>


              <div className="col-md-4 px-md-2 px-0 pt-4">
                <div className="bordercm2 bgcwhite b_r ">
                  <div className="col-12 text-left  px-2" >
                    <img src={informationbutton} alt="iconmoreinfo" className="text-left" style={{ width: 15 }} data-toggle="tooltip" data-placement="top" title="وحدة اكتمل تمويلها الى الآن" />
                  </div>
                  <div className="row m-0 px-3 pb-3">

                    <div className="col-10 px-0 py-0">
                      <p className="fzcmuted m-0 fz16">تم تحصيل</p>
                      <p className="fzcm fz24 mb-1  fw600 m-0 py-1">{this.props.thousands_separators(this.props.campaignDetails.campaign_collected)} <span className="fz14 fw300 fzcblack"> من <span className="fw600 fz14"> {this.props.thousands_separators(this.props.campaignDetails.project_amount)} </span> ج.م </span></p>
                      <div className="progress " style={{ height: 10 }}>
                        <div className="progress-bar bgcm" role="progressbar" style={{ width: this.props.campaignDetails.campaign_collected / this.props.campaignDetails.project_amount * 100 + "%" }} aria-valuenow={75} aria-valuemin={0} aria-valuemax={100}></div>
                      </div>

                    </div>
                    <div className="col-2 p-1 d-flex">
                      <div className="hrv"></div>
                      <div className="">
                        <p className="fzcm fz18 text-center fw600 mt-2 m-0">{this.props.thousands_separators(this.props.campaignDetails.units_completed)} </p>
                        <p className="fzcm fz10 text-center m-0">وحدة اكتمل تمويلها الى الآن</p>
                      </div>
                    </div>
                  </div>
                </div>
                {this.props.campaignDonationsDetails.length > 0 &&
                  <div className="border my-3 bgcwhite b_r p-3 ">
                    <div className="d-flex justify-content-between">
                      <span className="fz16  fw500"> المتبرعون</span>
                      <span className=" fz12 fzcmutedc  fw500">  تبرعات من {this.props.total_of_donations} شخص</span>
                    </div>
                    <p className="borderdashed m-1 my-2 "></p>
                    <div className="comments">

                      {this.props.campaignDonationsDetails.length != 0 ?
                        this.props.campaignDonationsDetails.map((x, i) => {
                          return (
                            <>
                              <div className="commentdonor row m-0 my-2 py-2" key={i}>
                                <div className="col-2 p-0 text-center">
                                  <img src={user} alt="iconmoreinfo" className="" style={{ width: 40, height: 40, borderRadius: "50%" }} />
                                </div>
                                <div className="col-10 row">
                                  <div className="col-12  p-0 d-flex justify-content-between ">
                                    <div>
                                      <p className="fz14 fw500 m-0">{x.user_name}</p>
                                      <p className="fz10 fzcmuted  m-0">{moment(x.donation_date).format('LL')}</p></div>
                                    <div><span className="fw500 fz18">{this.props.thousands_separators(x.donation_amount)} </span><span className="fz10">ج.م</span></div>
                                  </div>
                                  <div>
                                    <p className="fz12 fw500 m-0">{x.donation_comment}</p>

                                  </div>
                                </div>
                              </div>
                              <p className="borderdashed m-1  mx-0 my-2 "></p>
                            </>
                          )
                        }) : <p className="fz20 py-4 text-center"> لا يوجد تبرعات حتى الآن</p>
                      }

                    </div>

                  </div>
                }
              </div>
              {/* modal */}
              <a id="ModalSuccess" data-toggle="modal" data-target="#exampleModalScrollable" style={{ display: "none" }}></a>
              <Modalpayment campaignDetails={this.props.campaignDetails} thousands_separators={this.props.thousands_separators} />
              <ModalSuccess resCreateDonation={this.props.resCreateDonation} code={this.props.campaignDetails.smsCode} num={this.props.campaignDetails.SMSshortNumber} thousands_separators={this.props.thousands_separators} />
            </div>
          </div>
        </section>

        {/* modal */}
        <div className="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="text-left px-2">
                <img src={close} id="closemodal2" alt="close" className="mt-1 ml-0" data-dismiss="modal" style={{ width: 30 }} />
              </div>

              <div className="row m-0">
                <div className="col-md-10">
                  <div className="row ">
                    <div className="col-md-4 txet-center my-2 ">
                      <figure className="figure">
                        <img src={LINKIMAGES + this.props.ProjectsDetails.project_image} className="" alt="project_image" style={{ width: "100%", height: 110, objectFit: "contain" }} />
                      </figure>
                    </div>
                    <div className="col-md-8 ">
                      <p className="fz16 fw500 my-2">{this.props.ProjectsDetails.project_ar_name}</p>
                      <p className="borderdashed m-0"></p>
                      <div className="row my-1">
                        <p className="col-3 fz14 fw500 my-1 ">هدف الحملة</p>
                        <div className="col-8"><span className="fw500 fz22  my-1">{this.props.thousands_separators(this.props.ProjectsDetails.project_amount)} </span> <span className="fw500 my-1">ج.م</span></div>
                      </div>
                      <p className="borderdashed m-0"></p>
                      <div className="row mx-0  my-2">
                        <p className="col-3 fz14 fw500 my-1 px-0">وسائل الدفع</p>
                        <div className="col-8 fzcmuted">
                          <img src={fundsblack} style={{ height: 27, width: 27, objectFit: "contain" }} className="mx-2" />|
                                <img src={creditcardblack} style={{ height: 27, width: 27, objectFit: "contain" }} className="mx-2" />|
                                <img src={smsblack} style={{ height: 27, width: 27, objectFit: "contain" }} className="mx-2" />

                        </div>
                      </div>

                    </div>
                    <p className=" fz12 fzcmuted px-4">{this.props.ProjectsDetails.project_description}</p>
                  </div>
                </div>
                <div className="col-md-2 text-center">
                  <p className="fz10 my-1 fw500">احد مشروعات</p>
                  <img src={LINKIMAGES + this.props.ProjectsDetails.ngo_image} style={{ width: 75, height: 75, objectFit: "contain", border: " 1px solid #F1F1F1", boxShadow: " 0px 3px 6px #00000029" }} alt="logotype" className="m-auto" />
                  <p className="fz10 fzcmuted  my-1 mt-2 ">تاريخ انشاء المشروع</p>
                  <p className="fz10 fw500 my-1"> {moment(this.props.ProjectsDetails.created_at).format('LL')}</p>
                </div>
              </div>
              <div className="px-4">
                <p className="borderdashed m-0"></p>
                <p className="fz16 fw500 my-1">ارقام تخص المشروع</p>
                <div className="row m-0 py-3">
                  <div className="col-md-2 col-6 text-center">
                    <img src={moneyblack} style={{ width: 50, height: 50, objectFit: "contain" }} alt="logotype" className="m-auto" />
                    <p className="fz22 fw500 m-0">{this.props.thousands_separators(this.props.ProjectsDetails.donations_collected)}</p>
                    <p className="fz14  fw500 m-0">تبرعات</p>
                  </div>
                  <div className="col-md-2 col-6 text-center">
                    <img src={enterpriseblack} style={{ width: 50, height: 50, objectFit: "contain", }} alt="logotype" className="m-auto" />
                    <p className="fz22 fw500 m-0">{this.props.thousands_separators(this.props.ProjectsDetails.units_completed)}</p>
                    <p className="fz14  fw500 m-0">وحدات مكتمله</p>
                  </div>
                </div>
                <p className="borderdashed m-0"></p>
                <div className="text-center">
                  {this.props.ProjectsDetails.project_ar_name &&
                    <Link to={"/CreateCampaign/" + this.props.ProjectsDetails.project_ar_name.replace(/ /g, "_") + "/" + this.props.ProjectsDetails.project_id} onClick={this.handleclosemodle} className="btn px-5 mx-2 bgcm fzcwhite fw500 fz16 mt-4 mb-4" >
                      <img src={addwhiteicon} alt="arrow" style={{ width: 18 }} className="mx-1" />
                      انشئ حملة</Link>}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade ModalSuccess" id="exampleModalScrollable1" tabIndex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
          <div className="modal-dialog  modal-dialog-scrollable2" role="document">
            <div className="modal-content">

              <div className="modal-body">
                <div className="text-left">
                  <img src={close} alt="close" id="closemodal" className="mt-1 ml-0" data-dismiss="modal" style={{ width: 30 }} />
                </div>
                <div className="modal-body text-center  p-0">
                  <p className=" fzcm fz18 fw500">
                    يجب اولا {" "}
                    <Link to="/Register" onClick={this.handleclosemodle} >تسجيل حساب </Link> {" "}
                    {" "}   او {" "}
                    <Link to="/Login" onClick={this.handleclosemodle}>تسجيل دخول </Link>  {" "}
                  </p>
                </div>
              </div>

            </div>
          </div>
        </div>
        <Footer />
      </div >
    );
  }
}

const mapStateToProps = state => {
  return {
    campaignDetails: state.Campaigns.campaignDetails ? state.Campaigns.campaignDetails[0] : '',
    campaignDonationsDetails: state.Campaigns.campaignDonationsDetails ? state.Campaigns.campaignDonationsDetails : '',
    total_of_donations: state.Campaigns.campaignDonationsDetails ? state.Campaigns.campaignDonationsDetails[0] ? state.Campaigns.campaignDonationsDetails[0].total_of_donations : '' : '',
    resCreateDonation: state.Campaigns.resCreateDonation ? state.Campaigns.resCreateDonation : '',
    campaignCreated: state.User.campaignCreated ? state.User.campaignCreated : '',
    uid: state.User.user ? state.User.user.user_id : " ",
    ProjectsDetails: state.Campaigns.ProjectsDetails ? state.Campaigns.ProjectsDetails[0] : '',
    uid: state.User.user ? state.User.user.user_id : " ",
    IsLoggedIn: state.User ? state.User.IsLoggedIn : '',
    IsLoggedIn: state.User ? state.User.IsLoggedIn : '',

  };
}
const mapDispatchToProps = ({
  getCampaignsDetails: getCampaignsDetails,
  getCampaignDonationsDetails: getCampaignDonationsDetails,
  postUserfavourite: userAction.postUserfavourite,
  deleteUserfavourite: userAction.deleteUserfavourite,
  postUserfavourite: userAction.postUserfavourite,
  deleteUserfavourite: userAction.deleteUserfavourite,
  getProjectsId: getProjectsId,
  thousands_separators

})
export default connect(mapStateToProps, mapDispatchToProps)(CampaignDonation);