// export const LINKAPIS = "http://172.16.1.146:4000";
export const LINKAPIS = "https://www.inf.tatelecom.com:4000";
export const LINKIMAGES = "https://www.inf.tatelecom.com:4000/images/";
export const LINKAPISReDirections = "https://www.inf.tatelecom.com:4000";
// export const LINKPAYMENT = "https://inmemoryof.com/OnlinePayment_test2";
export const LINKPAYMENT = "https://inmemoryof.com/OnlinePayment";

export const FETCH_FILTERS = "FETCH_FILTERS";
export const FETCH_5CAMPAIGNS = "FETCH_5CAMPAIGNS";
export const FETCH_CATEGORIES = "FETCH_CATEGORIES";
export const RESET_ALL_CAMPAIGNS = "RESET_ALL_CAMPAIGNS";

export const FETCH_NGOS = "FETCH_NGOS";
export const FETCH_NGO_DETAILS = "FETCH_NGO_DETAILS";
export const FETCH_CATEGORY_PROJECTS = "FETCH_CATEGORY_PROJECTS";
export const RESET_CATEGORY_PROJECTS = "RESET_CATEGORY_PROJECTS";
export const FETCH_NGO_PROJECTS = "FETCH_NGO_PROJECTS";
export const FETCH_ALL_PROJECTS = "FETCH_ALL_PROJECTS";
export const FETCH_ALL_PROJECTS2 = "FETCH_ALL_PROJECTS2";
export const RESET_ALL_PROJECTS = "RESET_ALL_PROJECTS";
export const FETCH_CITIES = "FETCH_CITIES";
export const FETCH_AREAS = "FETCH_AREAS";
export const RESET_NGOS = "RESET_NGOS";
export const POST_COUNTACTUS = "POST_COUNTACTUS";
export const FETCH_SEARCH = "FETCH_SEARCH";

export const FETCH_PROJECTS_ID = "FETCH_PROJECTS_ID";
export const FETCH_ALL_CAMPAIGNS = "FETCH_ALL_CAMPAIGNS";
export const FETCH_CAMPAIGN_DETAILS = "FETCH_CAMPAIGN_DETAILS";
export const FETCH_CAMPAIGN_DONATIONS_DETAILS =
  "FETCH_CAMPAIGN_DONATIONS_DETAILS";
export const FETCH_FAQs = "FETCH_FAQs";
export const FETCH_PRESS = "FETCH_PRESS";

export const CREATE_DONATION = "CREATE_DONATION";

export const USERS_LOGOUT = "USERS_LOGOUT";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAILURE = "USER_REGISTER_FAILURE";
export const USERS_LOGIN_SUCCESS = "USERS_LOGIN_SUCCESS";
export const USERS_LOGIN_FAILURE = "USERS_LOGIN_FAILURE";
export const USER_INFO = "USER_INFO";
export const USER_CAMPAIGNS = "USER_CAMPAIGNS";
export const USER_DONATIONS = "USER_DONATIONS";
export const USER_4CAMPAIGNS = "USER_4CAMPAIGNS";
export const USER_4DONATIONS = "USER_4DONATIONS";
export const USER_4FAVORITE = "USER_4FAVORITE";
export const USER_FAVORITE = "USER_FAVORITE";
export const USER_FAVORITE2 = "USER_FAVORITE2";
export const USER_DELETE_FAVORITE = "USER_DELETE_FAVORITE";
export const USER_UPDATE = "USER_UPDATE";
export const USER_VERIFY = "USER_VERIFY";
export const RESET_USER_ERROR = "RESET_USER_ERROR";
export const DELETE_USER = "DELETE_USER";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const GET_ACHIEVEMENTS = "GET_ACHIEVEMENTS";
export const LOADING = "LOADING";
export const GETMESSAGE = "GETMESSAGE";
export const USER_VERIFYMESSAGE = "USER_VERIFYMESSAGE";



export const USER_UPDATE_RESET_M = "USER_UPDATE_RESET_M";
export const USER_POST_FAVORITE = "USER_POST_FAVORITE";
export const HANDLE_ERROR_GLOBAL = "HANDLE_ERROR_GLOBAL";
export const ERROR_MESSAGE_BACK_END = "ERROR_MESSAGE_BACK_END";
export const RESET_ERRORS = "RESET_ERRORS";

export const CREATE_CAMPAIGN = "CREATE_CAMPAIGN";
