import React from "react";
import "./ForgotPassword.scss";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Footer from "../Components/Footer/Footer";
import { Forgotpassword } from "../../redux/actions";

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      erroruser_email: ""
    };
  }
  componentDidMount() { }
  handleChange = e => {
    this.setState({
      // ...this.state,
      [e.target.name]: e.target.value
    });
  };
  validate = () => {
    let erroruser_email = "";

    if (!this.state.email.includes("@")) {
      erroruser_email = "بريد الكتروني غير صحبح";
    }

    if (erroruser_email) {
      this.setState({
        erroruser_email
      });
      return false;
    }
    return true;
  };
  handleSubmit = e => {
    e.preventDefault();
    const isValid = this.validate();
    if (isValid) {
      this.props.Forgotpassword(this.state.email);
      this.setState({
        erroruser_email: "",
        errorpassword: ""
      });
    }
  };
  render() {
    return (
      <div className="Login">
        <div className="navhe"></div>
        <section className="formlogin py-4 my-5 col-lg-4 col-md-5 col-11 mx-auto px-lg-4">
          <form className="px-lg-5 m-auto" onSubmit={this.handleSubmit}>
            <h3 className="fzcblack  fz26 fw500 text-center py-3 ">
              مساعدة كلمة السر
            </h3>
            <p className="fz14 fzcmuted text-center">
              أدخل عنوان البريد الإلكتروني المرتبط بحسابك
            </p>

            <p className="borderdashed mb-4"></p>
            {this.props.forgotpassword === 1 ? <p className="fz16">تم إرسال رساله الي البريد الالكتروني لتعديل كلمة المرور</p> :
              this.props.forgotpassword === 2 ? <p className="fz16 text-center">loading...</p> :
                <>
                  <div className="form-group  m-0 py-2 row">
                    <input
                      type="email"
                      className="form-control"
                      id="statemailicEmail"
                      placeholder="البريد الإلكتروني"
                      name="email"
                      value={this.state.email}
                      onChange={this.handleChange}
                    />
                    <div style={{ fontSize: 12, color: "red" }} className="py-1 m-0">
                      {this.state.erroruser_email}
                    </div>
                  </div>

                  {this.props.errorMessage ? (
                    <div className="alert alert-danger m-0  my-2 py-2" role="alert">
                      {this.props.errorMessage}
                    </div>
                  ) : (
                      ""
                    )}
                  <button
                    type="submit"
                    className="btn  w-100 d-block py-2 stylelink fzcwhite  fw500 fz18 bgcm d-block my-3"
                    role="button"
                    aria-pressed="true"
                  >
                    إرسال
              </button>
                </>


            }
            <p className="borderdashed my-3"></p>
            <p className=" fz14 text-center fw500 fzcblack">
              {" "}
              ليس لديك حساب ؟
              <Link to="/Register" className="fzcm">
                {" "}
                سجل الآن{" "}
              </Link>
            </p>
          </form>
        </section>

        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    errorMessage: state.User.errorMessage,
    forgotpassword: state.User.forgotpassword
  };
};
const mapDispatchToProps = {
  Forgotpassword: Forgotpassword
};
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
