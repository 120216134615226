import {
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAILURE,
  USER_CAMPAIGNS,
  USER_DONATIONS,
  USER_4CAMPAIGNS,
  USER_4DONATIONS,
  USER_FAVORITE,
  USER_4FAVORITE,
  USER_DELETE_FAVORITE,
  USERS_LOGIN_SUCCESS,
  USERS_LOGIN_FAILURE,
  USER_POST_FAVORITE,
  USERS_LOGOUT,
  USER_INFO,
  CREATE_CAMPAIGN,
  USER_UPDATE,
  USER_FAVORITE2,
  USER_UPDATE_RESET_M,
  USER_VERIFY,
  USER_VERIFYMESSAGE,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  DELETE_USER,
  RESET_USER_ERROR
} from "../actions/types";
import cookies from "js-cookie";

let user = cookies.getJSON('user');
const initialState = user
  ? {
    IsLoggedIn: true,
    user,
    userCampaigns: [],
    userDonations: [],
    userCampaigns4: [],
    userDonations4: [],
    userFavorites: [],
    userFavorites4: [],
    forgotpassword: 0
  }
  : {
    userCampaigns: [],
    userDonations: [],
    userCampaigns4: [],
    userDonations4: [],
    userFavorites: [],
    userFavorites4: [],
    forgotpassword: 0
  };

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_REGISTER_SUCCESS:
      return {
        ...state,
        IsLoggedIn: false,
        user: action.payload
      };
    case USER_REGISTER_FAILURE:
      return {
        ...state,
        errorMessageRegister: action.payload
      };
    case USERS_LOGIN_SUCCESS:
      return {
        ...state,
        IsLoggedIn: true,
        user: action.payload
      };
    case USERS_LOGIN_FAILURE:
      return {
        ...state,
        IsLoggedIn: false,
        errorMessage: action.payload
      };

    case USERS_LOGOUT:
      return {
        IsLoggedIn: false,
        user: false
      };
    case USER_VERIFY:
      return {
        ...state,
        IsLoggedIn: false,
        verify: action.payload
      };

    case USER_VERIFYMESSAGE:
      return {
        ...state,
        verifyMessage: action.payload
      };
    case DELETE_USER:
      return {
        ...state,
        IsLoggedIn: false,
        user: {}

      };
    case USER_CAMPAIGNS:
      return {
        ...state,
        userCampaigns: state.userCampaigns.concat(action.payload)
      };

    case USER_DONATIONS:
      return {
        ...state,
        userDonations: state.userDonations.concat(action.payload)
      };
    case USER_4CAMPAIGNS:
      return {
        ...state,
        userCampaigns4: action.payload
      };

    case USER_4DONATIONS:
      return {
        ...state,
        userDonations4: action.payload
      };
    case USER_FAVORITE:
      return {
        ...state,
        userFavorites: state.userFavorites.concat(action.payload)
      };
    case USER_FAVORITE2:
      return {
        ...state,
        userFavorites: action.payload
      };
    case USER_4FAVORITE:
      return {
        ...state,
        userFavorites4: action.payload
      };
    case USER_INFO:
      return {
        ...state,
        user: action.payload
      };
    case USER_UPDATE:
      return {
        ...state,
        successMessageAR: "تم تعديل بياناتك"
      };
    case USER_UPDATE_RESET_M:
      return {
        ...state,
        successMessageAR: false
      };

    case USER_POST_FAVORITE:
      return {
        ...state,
        successMessageAR: "تم اضافاتها الي المفضلات"
      };
    case USER_DELETE_FAVORITE:
      return {
        ...state,
        successMessageAR: "تم مسحها من المفضلات"
      };
    case CREATE_CAMPAIGN:
      return {
        ...state,
        campaignCreated: true,
        campaignid: action.payload,
        campaign_title: action.campaign_title
      };
    case RESET_USER_ERROR:
      return {
        ...state,
        errorMessageRegister: false,
        errorMessage: false,
        errorMessageRegister: false
      };
    case FORGOT_PASSWORD:
      return {
        ...state,
        forgotpassword: action.payload
      };
    case RESET_PASSWORD:
      return {
        ...state,
        resetpassword: action.payload
      };
    default:
      return state;
  }
};
