import React from 'react';
import './ChooseCampaign.scss';
import mdarrowleftblack from '../../Images/mdarrowrleftblack.svg'
import mdarrowrightblack from '../../Images/mdarrowrightblack.svg'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { LINKIMAGES } from "../../redux/actions/types";
import { Link } from "react-router-dom";
import { Animated } from "react-animated-css";


class Showall extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      project_id: this.props.project_id,
      startPosition: 0
    };
    this.handle = this.handle.bind(this)
  }

  componentDidMount() {

  }
  handle(event) {
    this.setState({ project_id: event.currentTarget.id })
    this.props.setProjectId2(event.currentTarget.id)
  }
  render() {
    const settings = {
      className: "center",
      centerMode: false,
      infinite: false,
      slidesToShow: 5,
      speed: 500,
      rows: 2,
      dots: true,
      variableWidth: true,
      centerPadding: "60px",

      // afterChange: () => this.props.setProjectId(document.getElementById("slider2").getElementsByClassName("slick-current")[0].firstChild.firstChild.id),
      nextArrow: <img src={mdarrowrightblack} alt="logotype" className="" />,
      prevArrow: <img src={mdarrowleftblack} alt="logotype" className="" />,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 5,
            rows: 1,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 3,
            rows: 1,

          }
        },
        {
          breakpoint: 300,
          settings: {
            slidesToShow: 1,
            rows: 1,
            centerMode: true,
            infinite: true,
          }
        }
      ]

    };

    return (
      <div className="container Showall pb-5 " id="items2">
        <Slider {...settings} >
          {this.props.AllProjects.length > 0 ?
            this.props.AllProjects.map((x, i) => {
              return (
                <Link to={`/CreateCampaign/${x.project_ar_name.replace(/ /g, "_")}/${x.project_id}`} className="item  px-3 stylelink" key={i}>
                  <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true} animationInDelay={this.props.AllProjects.length < 20 ? i + "00" : ((i + 20) - this.props.AllProjects.length) + "00"}>

                    {/*for old verison slider*/}
                    {/* + (this.state.project_id == this.props.AllProjects[i].project_id ? "  active" : '') */}
                    <div className={"p-3 my-3 bgcwhite text-center projectitem Campaign"} id={this.props.AllProjects[i].project_id}  >
                      <div className="text-center">
                        <img src={LINKIMAGES + this.props.AllProjects[i].ngo_image} style={{ width: 70, height: 70, objectFit: "cover", border: " 1px solid #F1F1F1" }} alt="logotype" className="m-auto" />
                      </div>
                      <p className="py-1 fw500 fz16 fzcblock text-center m-0 fh56 d-flex justify-content-center ">{this.props.AllProjects[i].project_ar_name}</p>
                      <p className="py-1 fw500 fz22 fzcblock text-center m-0">{this.props.thousands_separators(this.props.AllProjects[i].project_amount)} <span className="fz12">ج.م</span></p>
                    </div>
                  </Animated>
                </Link>
              )
            })
            : ''}
        </Slider>
      </div>
    );
  }
}

export default Showall;