import {
  FETCH_NGOS,
  FETCH_NGO_DETAILS,
  FETCH_NGO_PROJECTS,
  FETCH_FAQs,
  FETCH_FILTERS,
  FETCH_PRESS,
  RESET_NGOS,
  FETCH_SEARCH,
  POST_COUNTACTUS,
  GET_ACHIEVEMENTS
} from "../actions/types";
const initialState = {
  allNGOS: []
};
export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_NGOS:
      return {
        ...state,
        allNGOS: state.allNGOS.concat(action.payload)
      };
    case RESET_NGOS:
      return {
        ...state,
        allNGOS: []
      };
    case FETCH_NGO_DETAILS:
      return {
        ...state,
        NGODetails: action.payload
      };
    case FETCH_NGO_PROJECTS:
      return {
        ...state,
        projects: action.payload
      };
    case FETCH_FAQs:
      return {
        ...state,
        allFAQs: action.payload
      };

    case FETCH_PRESS:
      return {
        ...state,
        AllPressCoverage: action.payload
      };
    case FETCH_FILTERS:
      return {
        ...state,
        filters: action.payload
      };
    case POST_COUNTACTUS:
      return {
        ...state,
        messageCountactUs: action.payload
      };
    case FETCH_SEARCH:
      return {
        ...state,
        allsearch: action.payload
      };
    case GET_ACHIEVEMENTS:
      return {
        ...state,
        Achievements: action.payload
      };
    default:
      return state;
  }
};
