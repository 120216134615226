import React from "react";
import "./NGOsListing.scss";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getngos, resetngos, thousands_separators } from "../../redux/actions";
import Footer from "../Components/Footer/Footer";
import addicon from "../../Images/addicon.svg";
import arrowcm from "../../Images/arrowcm.svg";
import searchcmuted from "../../Images/searchcmuted.svg";
import { LINKIMAGES } from "../../redux/actions/types";
import { Animated } from "react-animated-css";

class NGOsListing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      rowscount: 20
    };
    this.handleDataAferSearch = this.handleDataAferSearch.bind(this);
  }
  componentDidMount() {
    if (this.props.allNGOS.length == 0) {
      this.props.resetngos();
      this.props.getngos(this.state.offset, this.state.rowscount);
    }
  }

  // handlemore = () => {
  //   console.log(this.props.allNGOS[this.props.allNGOS.length - 1].id);
  // };
  handelsearchbar = () => {
    this.setState({ searchbar: !this.state.searchbar });
  };
  handleDataAferSearch(e) {
    this.props.resetngos();
    this.setState(
      {
        search: e.target.value,
        offset: 0,
        rowscount: 20
      },
      this.props.getngos(0, 20, e.target.value)
    );
  }
  render() {
    document.title = "InMemoryOf | All NGOs";
    document.getElementsByTagName("META")[1].content =
      "IN MEMORY OF was created to help family and friends commemorate their loved ones through memorial donations";
    document.documentElement.lang = "en";

    return (
      <div className="NGOsListing">
        <div className="navhe"></div>
        <div className="container">
          <div className="py-2">
            <Link to="" className="fz12 fzcm px-2 fw500">
              الرئيسية
            </Link>
            <span className="fz12 fw500 mt-2">&gt; </span>
          </div>
          <p className="borderdashed m-0"></p>
          <div className="d-md-flex justify-content-between py-3">
            <h3 className="fzcblack black fz24 fw500  m-0 center-v col-lg-8 col-md-7">
              الجمعيات الخيرية
            </h3>
            {window.screen.width > 768 ?

              <div className="col-md-1 text-left center-v p-0 my-2 ">
                <img src={searchcmuted} alt="searchcmuted" onClick={this.handelsearchbar} style={{ width: 25 }} />
                {this.state.searchbar ?

                  <div className={" d-flex " + (this.state.searchbar ? " searchbarshow2" : " searchbar2")}>
                    <Animated animationIn="fadeInDown" animationOut="fadeOut" isVisible={true} animationInDelay="0" className="col">
                      <input type="search" className="form-control" value={this.state.search} onChange={this.handleDataAferSearch} />
                    </Animated>
                  </div>
                  : ''}
              </div>
              :
              <div className="col-md-1 text-left d-flex m-0 center-v p-0 my-2 ">
                <img src={searchcmuted} alt="searchcmuted" style={{ width: 25 }} />
                <div className=" col p-1">
                  <input type="search" className="form-control" value={this.state.search} onChange={this.handleDataAferSearch} />
                </div>
              </div>
            }
            <div className="col-lg-3 col-md-4  d-md-flex justify-content-between">
              <Link
                to="/ChooseCampaign"
                className="btn col mx-2 bordercm fzcm fzcwhite fw500  fz16"
              >
                <img
                  src={addicon}
                  alt="addicon"
                  style={{ width: 17 }}
                  className="mx-1"
                />
                انشئ حملة جديدة
              </Link>
              {/* <select className=" col mx-2 fzcmuted fw500 fz16">
                <option className=" ">الحملات الأكثر نشاطا</option>
              </select> */}
            </div>
          </div>
        </div>

        <section className="allNGOs py-3">
          <div className="container">
            <div className="row mx-auto ">
              {this.props.allNGOS.map((x, i) => {
                return (
                  <Link
                    to={"/NGODetails/" + x.ar_name.replace(/ /g, "_") + "/" + x.id}
                    className="col-1-5 stylelink"
                    key={i}
                  >
                    <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true} animationInDelay={this.props.allNGOS.length < 20 ? i + "00" : ((i + 20) - this.props.allNGOS.length) + "00"}>

                      <div className="ngoitem  p-0 my-3 bgcwhite text-center mx-auto ">
                        <figure class="figure m-0 text-center">
                          <img
                            src={LINKIMAGES + x.ngo_image}
                            class="figure-img img-fluid rounded"
                            alt="ngo"
                          />
                        </figure>

                        <div className="d-flex justify-content-between px-3 fzcblack">
                          <p className="py-1 fw500 fz16 fzcblock  m-0">
                            عدد المشاريع
                        </p>
                          <p className="py-1 fw500 fz16 fzcblock  m-0">
                            {" "}
                            {this.props.thousands_separators(x.total_projects)}{" "}
                          </p>
                        </div>
                      </div>
                    </Animated>
                  </Link>
                );
              })}
              {/* <div className="col-1-5">
                  <div className="ngoitem  p-0 my-3 bgcwhite text-center mx-auto " >
                        <figure class="figure m-0 text-center" >
                        <img src={charity1} class="figure-img img-fluid rounded" alt="ngo"/>
                      </figure>

                    <div className="d-flex justify-content-between px-3">
                     <p className="py-1 fw500 fz16 fzcblock  m-0">عدد المشاريع</p>
                     <p className="py-1 fw500 fz16 fzcblock  m-0">  23 </p>
                    </div>
                  </div>   
                </div>
                <div className="col-1-5">
                  <div className="ngoitem  p-0 my-3 bgcwhite text-center mx-auto " >
                        <figure class="figure m-0 text-center" >
                        <img src={charity2} class="figure-img img-fluid rounded" alt="ngo"/>
                      </figure>

                    <div className="d-flex justify-content-between px-3">
                     <p className="py-1 fw500 fz16 fzcblock  m-0">عدد المشاريع</p>
                     <p className="py-1 fw500 fz16 fzcblock  m-0">  23 </p>
                    </div>
                  </div>   
                </div>
                <div className="col-1-5">
                  <div className="ngoitem  p-0 my-3 bgcwhite text-center mx-auto " >
                        <figure class="figure m-0 text-center" >
                        <img src={charity1} class="figure-img img-fluid rounded" alt="ngo"/>
                      </figure>

                    <div className="d-flex justify-content-between px-3">
                     <p className="py-1 fw500 fz16 fzcblock  m-0">عدد المشاريع</p>
                     <p className="py-1 fw500 fz16 fzcblock  m-0">  23 </p>
                    </div>
                  </div>   
                </div>
                <div className="col-1-5">
                  <div className="ngoitem  p-0 my-3 bgcwhite text-center mx-auto " >
                        <figure class="figure m-0 text-center" >
                        <img src={charity3} class="figure-img img-fluid rounded" alt="ngo"/>
                      </figure>

                    <div className="d-flex justify-content-between px-3">
                     <p className="py-1 fw500 fz16 fzcblock  m-0">عدد المشاريع</p>
                     <p className="py-1 fw500 fz16 fzcblock  m-0">  23 </p>
                    </div>
                  </div>   
                </div>
                <div className="col-1-5">
                  <div className="ngoitem  p-0 my-3 bgcwhite text-center mx-auto " >
                        <figure class="figure m-0 text-center" >
                        <img src={charity1} class="figure-img img-fluid rounded" alt="ngo"/>
                      </figure>

                    <div className="d-flex justify-content-between px-3">
                     <p className="py-1 fw500 fz16 fzcblock  m-0">عدد المشاريع</p>
                     <p className="py-1 fw500 fz16 fzcblock  m-0">  23 </p>
                    </div>
                  </div>   
                </div>
                <div className="col-1-5">
                  <div className="ngoitem  p-0 my-3 bgcwhite text-center mx-auto " >
                        <figure class="figure m-0 text-center" >
                        <img src={charity2} class="figure-img img-fluid rounded" alt="ngo"/>
                      </figure>

                    <div className="d-flex justify-content-between px-3">
                     <p className="py-1 fw500 fz16 fzcblock  m-0">عدد المشاريع</p>
                     <p className="py-1 fw500 fz16 fzcblock  m-0">  23 </p>
                    </div>
                  </div>   
                </div>
                <div className="col-1-5">
                  <div className="ngoitem  p-0 my-3 bgcwhite text-center mx-auto " >
                        <figure class="figure m-0 text-center" >
                        <img src={charity3} class="figure-img img-fluid rounded" alt="ngo"/>
                      </figure>

                    <div className="d-flex justify-content-between px-3">
                     <p className="py-1 fw500 fz16 fzcblock  m-0">عدد المشاريع</p>
                     <p className="py-1 fw500 fz16 fzcblock  m-0">  23 </p>
                    </div>
                  </div>   
                </div> */}
            </div>

            {parseInt(this.props.total_ngo) > this.props.allNGOS.length &&
              this.props.allNGOS.length > 0 ? (
                <p
                  onClick={() =>
                    this.props.getngos(
                      this.props.allNGOS.length,
                      this.state.rowscount
                    )
                  }
                  className="btn bgcwhite pointer col mx-0 bordercm fzcm  fw500 px-4 my-3 fz18"
                >
                  حمل المزيد
                <img src={arrowcm} alt="arrow" />
                </p>
              ) : (
                ""
              )}
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    allNGOS: state.NGOS ? state.NGOS.allNGOS : [],
    total_ngo: state.NGOS.allNGOS
      ? state.NGOS.allNGOS[0]
        ? state.NGOS.allNGOS[0].total_ngos
        : ""
      : 0
  };
};
const mapDispatchToProps = {
  getngos: getngos,
  resetngos: resetngos,
  thousands_separators
};
export default connect(mapStateToProps, mapDispatchToProps)(NGOsListing);
