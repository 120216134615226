import React from 'react';
import './DeleteUser.scss';
import { Link } from "react-router-dom";
import { userAction } from '../../redux/actions'
import { connect } from 'react-redux';


import Footer from '../Components/Footer/Footer'




class DeleteUser extends React.Component {

  constructor(props) {
    super(props);
    this.state = {

    };
  }
  componentDidMount() {
  }



  render() {
    return (
      <div className="Login">
        <div className="navhe"></div>
        <section className="formlogin py-4 my-5 col-lg-4 col-md-5 col-11 mx-auto px-lg-4 text-center">


          <h3 className="fzcblack  fz24 fw500 text-center py-3 ">تم مسح حسابك</h3>
          <Link to="/Register" className="fz14 fzcm">تسجيل حساب من هنا</Link>



        </section>

        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {

  };
}
const mapDispatchToProps = ({
})
export default connect(mapStateToProps, mapDispatchToProps)(DeleteUser);