import React from "react";
import "./Navbar.scss";
import search from "../../../Images/search.svg";
import logoWhite from "../../../Images/logoWhite.png";
import { Link } from "react-router-dom";
import arrowwhite from "../../../Images/arrowwhite.svg";
import { connect } from "react-redux";
import { userAction, searchdata } from "../../../redux/actions";
import { LINKIMAGES } from "../../../redux/actions/types";
import sidemenu from "../../../Images/sidemenu.png";
import { Animated } from "react-animated-css";


class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchbar: false,
      search: ""
    };
    this.handelsearch = this.handelsearch.bind(this);
  }
  componentWillReceiveProps() { }
  handlesearchbar = () => {
    this.setState({ searchbar: !this.state.searchbar });
  };

  handelsearch(e) {
    this.setState({ search: e.target.value });
    if (e.target.value.length > 2) {
      this.setState({ dropdownsearch: true });
      this.props.searchdata(this.state.search);
    }
    if (e.target.value.length < 2) {
      this.setState({ dropdownsearch: false });
    }
  }
  handelsidemenu = () => {
    if (document.getElementById("contant").className == "contantwithSM") {
      document.getElementById("contant").classList.add("contant")
      document.getElementById("contant").classList.remove("contantwithSM")
      document.getElementById("containerNav").classList.add("contant")
      document.getElementById("containerNav").classList.remove("contantwithSM")
      document.getElementById("side").classList.remove("sidemenushow")
      document.getElementById("side").classList.add("sidemenunone")

    } else {
      document.getElementById("contant").classList.add("contantwithSM")
      document.getElementById("contant").classList.remove("contant")
      document.getElementById("containerNav").classList.add("contantwithSM")
      document.getElementById("containerNav").classList.remove("contant")
      document.getElementById("side").classList.remove("sidemenunone")
      document.getElementById("side").classList.add("sidemenushow")
    }
  }
  render() {
    if (this.props.location.pathname.length === 1) {
      window.addEventListener("scroll", function (e) {
        if (document.getElementById("nav")) {
          var nav = document.getElementById("nav");
          if (
            document.documentElement.scrollTop ||
            document.body.scrollTop > window.innerHeight ||
            window.location.pathname.length !== 1
          ) {
            nav.classList.add("colornav");
            nav.classList.remove("colornav2");
          } else {
            nav.classList.add("colornav2");
            nav.classList.remove("colornav");
          }
        }
      });
    }
    const searchtype = {
      ngo: "NGODetails",
      project: "CreateCampaign",
      campaign: "CampaignDonation"
    };

    return (
      <div
        id="nav"
        className={"Navbar colornav text-center d-flex align-items-center fixed-top "
          + (this.props.location.pathname.length === 1 ? "colornav2" : "")}>
        <div id="containerNav" className=" contant container row m-0 mx-auto px-0 align-items-center">
          <img className="arrowwhite mx-2   d-block d-md-none" onClick={this.handelsidemenu} src={sidemenu} alt="sidemenu" style={{ width: 35 }} />
          <div className="col-md-4 col-3 relative px-0 d-none d-md-block">
            <div className="d-flex justify-content-between px-0 mx-0 px-md-4 searchdiv">
              <div className="fz14  fzcwhite d-flex align-items-center pointer" onClick={this.handlesearchbar}>
                <img src={search} alt="search" className="searchicon mx-2 d-flex  " />
                {!this.state.searchbar ? 'بحث' : ''}
              </div>
              {!this.state.searchbar ?
                <>
                  <span className="fz16 fzcwhite">|</span>
                  <Link to="/Campaigns" className="fz14 fzcwhite">
                    اكتشف
                  </Link>
                  <span className="fz16 fzcwhite">|</span>
                  <Link to="/ChooseCampaign" className="fz14 fzcwhite">
                    ابدأ حملة تبرعات
                  </Link>
                </>
                :
                <>
                  <Animated animationIn="fadeInRight" isVisible={true} animationInDelay="0" className="col">

                    <input type="text" value={this.state.search} className="form-control" onChange={this.handelsearch}
                      autoComplete="off" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                      aria-expanded="false" />
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton" style={this.state.dropdownsearch ? {} : { display: "none" }}>
                      {this.props.allsearch.map((x, i) => {
                        return (
                          <Link to={"/" + searchtype[x.type] + "/" + x.en_name.replace(/ /g, "_") + "/" + x.id} className="faildsearch" >
                            <div className="row m-0">
                              <div className="col-5">
                                <img src={LINKIMAGES + x.image} alt={x.en_name} style={{ width: "100%", height: 60, objectFit: "contain" }} />
                              </div>
                              <div className="col-7 text-right center-v ">
                                <p className="m-0">{x.en_name}</p>
                              </div>
                            </div>
                            <p className="borderdashed m-0 my-1 d-block"></p>
                          </Link>
                        );
                      })}
                    </div>
                  </Animated>

                </>
              }
            </div>
          </div>
          <div className="col-md-4 col-6  mx-auto d-flex align-items-center ">
            <Link to="/" className="m-auto">
              <img src={logoWhite} alt="inmemoryof" className="logoWhite mx-auto " />
            </Link>
          </div>
          <div className="col-md-4 col-3">
            <div style={this.props.IsLoggedIn ? { display: "none" } : {}}>
              <div className="d-flex justify-content-between align-items-center px-md-4 " >
                <div className="col-2 "></div>
                <Link to="/Register" className="fz14  fzcwhite  d-none d-md-block">
                  اشترك
                </Link>
                <span className="fz16 fzcwhite  d-none d-md-block">|</span>
                <Link to="/Login" className="fz14 fzcwhite">
                  تسجيل الدخول
                </Link>
                {/* <span className="fz16 fzcwhite">|</span>
                <a href="" className="fz14 fzcwhite">
                E
                </a> */}
              </div>
            </div>

            <div className="px-md-4 " style={{ textAlign: "left" }} style={!this.props.IsLoggedIn ? { display: "none" } : {}}>
              <div className="dropdown m-0 d-flex justify-content-end align-items-center"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                {this.props.IsLoggedIn ?
                  <img className="" src={LINKIMAGES + this.props.user.profile_pic} alt="MyProfile"
                    style={{ height: 35, width: 35, objectFit: "contain", borderRadius: "50%" }} />
                  : ''}
                <span className="fz14  mx-1  d-none d-md-block" style={{ color: "#B8B8B8" }}>
                  مرحبا
                </span>
                <span className="fz14 fzcwhite mx-1  d-none d-md-block">
                  {this.props.user_name}
                </span>
                <img className="arrowwhite m-1 mt-2  d-none d-md-block" src={arrowwhite} alt="MyProfile" />
              </div>
              <div className="dropdown-menu px-1 text-right" aria-labelledby="dropdownMenuButton"  >
                <div className="triangle"></div>
                <div className="p-1 absolute">
                  <Link className="dropdown-item fw500 fz16" to="/MyProfile">
                    حسابى
                   </Link>
                  <p className="borderdashed m-0"></p>
                  <Link className="dropdown-item fw500 fz16" to="/MyFavourite" >
                    المفضلات
                    </Link>
                  <p className="borderdashed m-0"></p>
                  <Link className="dropdown-item fw500 fz16" to="/MyDonations"  >
                    تبرعاتى
                  </Link>
                  <p className="borderdashed m-0"></p>
                  <Link className="dropdown-item fw500 fz16" to="/MyCampaigns" >
                    حملات قمت بإنشائها
                  </Link>
                  <p className="borderdashed m-0"></p>
                  <Link className="dropdown-item fw500 fz16" to="/MyProfile/Edit" >
                    اعدادات الحساب
                    </Link>
                  <p className="borderdashed m-0"></p>
                  {/* <Link className="dropdown-item fw500 fz16" to="">
                      مساعدة
                    </Link>
                    <p className="borderdashed m-0"></p> */}
                  <a className="dropdown-item fw500 fz16" onClick={() => this.props.Logout("/")} >
                    الخروج
                    </a>
                </div>
              </div>
              {/* <span className="fz16 fzcwhite">|</span>
                <a href="" className="fz16 fzcwhite">
                E
                </a> */}
            </div>
            <div id="side" className="side sidemenunone text-right pt-4">
              {this.props.IsLoggedIn ?
                <Link to="/MyProfile" onClick={this.handelsidemenu} className="text-center">
                  <div >
                    <img src={LINKIMAGES + this.props.user.profile_pic} alt="profile_pic" style={{ height: 70, width: 70, objectFit: "contain", borderRadius: "50%" }} />
                  </div>
                  <span className="fz16 fzcwhite px-2 py-3" style={{ color: "#fff" }}>
                    مرحبا
                 </span>
                  <span className="fz14 fzcwhite mx-1 ">
                    {this.props.user_name}
                  </span>
                </Link>
                : ""}

              <div className="p-2">
                <input type="text" className="form-control " value={this.state.search} className="form-control" onChange={this.handelsearch}
                  autoComplete="off" placeholder="بحث" />
              </div>

              {this.props.allsearch > 0 || this.state.search.length > 2 ?
                <div className="sidemenusearch m-1">
                  {this.props.allsearch.map((x, i) => {
                    return (
                      <Link to={"/" + searchtype[x.type] + "/" + x.en_name.replace(/ /g, "_") + "/" + x.id} onClick={this.handelsidemenu} className="faildsearch " >
                        <div className={x.type === "ngo" && this.props.history.location.pathname.split("/")[2] == x.id || x.type === "campaign" && this.props.history.location.pathname.split("/")[2] == x.id ? "row m-0  bgcb2" : "row m-0  "} >
                          <div className="col-5">
                            <img src={LINKIMAGES + x.image} alt={x.en_name} style={{ width: "100%", height: 60, objectFit: "contain" }} />
                          </div>
                          <div className="col-7 text-right center-v ">
                            <p className="m-0 fzcwhite">{x.en_name}</p>
                          </div>
                        </div>
                        <p className="borderdashed my-1 mx-3 bcmuted "></p>
                      </Link>
                    );
                  })}
                </div>
                : ''}
              <Link to="/Campaigns" onClick={this.handelsidemenu} className={window.location.pathname.split("/")[1] == "Campaigns" ? "fz16 fzcwhite d-block px-2 py-3 ch" : "fz16 fzcwhite d-block px-2 py-3"}>اكتشف</Link>
              <Link to="/ChooseCampaign" onClick={this.handelsidemenu} className={window.location.pathname.split("/")[1] == "ChooseCampaign" ? "fz16 fzcwhite d-block px-2 py-3 ch" : "fz16 fzcwhite d-block px-2 py-3"}>ابدأ حملة تبرعات</Link>
              {this.props.IsLoggedIn ? ''
                : <Link to="/Register" onClick={this.handelsidemenu} className={window.location.pathname.split("/")[1] == "Register" ? "fz16 fzcwhite d-block px-2 py-3 ch" : "fz16 fzcwhite d-block px-2 py-3"}>اشترك</Link>
              }

            </div>
          </div>
        </div>
      </div >
    );
  }
}
const mapStateToProps = state => {
  return {
    IsLoggedIn: state.User ? state.User.IsLoggedIn : "",
    user_name: state.User.user ? state.User.user.user_name : "",
    user: state.User.user,
    allsearch: state.NGOS.allsearch ? state.NGOS.allsearch : []
  };
};
const mapDispatchToProps = {
  Logout: userAction.Logout,
  searchdata: searchdata
};
export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
