import React from 'react';
import './Login.scss';
import { Link } from "react-router-dom";
import { userAction } from '../../redux/actions'
import { connect } from 'react-redux';


import Footer from '../Components/Footer/Footer'


import facebookwhite from '../../Images/facebookwhite.svg'


class Login extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      user_email: "",
      password: "",

      erroruser_email: "",
    };
  }
  componentDidMount() {
  }
  handleChange = e => {
    this.setState({
      // ...this.state,
      [e.target.name]: e.target.value
    })
  };
  validate = () => {
    let erroruser_email = "";


    if (!this.state.user_email.includes("@")) {
      erroruser_email = "بريد الكتروني غير صحبح";
    }

    if (erroruser_email) {
      this.setState({
        erroruser_email,
      });
      return false;
    }
    return true;
  };
  handleSubmit = e => {
    e.preventDefault();
    // perform all neccassary validations
    const isValid = this.validate();

    if (isValid) {
      this.props.Login(this.state.user_email, this.state.password)
      this.setState({
        erroruser_email: "",
      })
    }
  };
  render() {
    return (
      <div className="Login">
        <div className="navhe"></div>
        <section className="formlogin py-4 my-5 col-lg-4 col-md-5 col-11 mx-auto px-lg-4">
          <form className="px-lg-5 m-auto" onSubmit={this.handleSubmit}>
            <h3 className="fzcblack  fz26 fw500 text-center py-3 ">الدخول الى الموقع</h3><p></p>
            {/* <Link to="/" className="btn d-block py-1 stylelink fzcwhite  fw500 fz18 bgcf d-block mt-4 mb-2" role="button" aria-pressed="true" >
                      استمر بالفيسبوك   
                        <img src={facebookwhite} className="figure-img img-fluid   m-1" alt="twitterwhite" style={{width:25,height:25,objectFit:"contain"}}/>
              </Link>

              <span className="fzcblack labelstyle fw500 fz18 px-1">أو</span>
                           */}
            <p className="borderdashed mb-4"></p>
            <div className="form-group  m-0 py-2 row">
              <input type="email" className="form-control" id="statemailicEmail" placeholder="البريد الإلكتروني" name="user_email" value={this.state.user_email} onChange={this.handleChange} />
              <div style={{ fontSize: 12, color: "red" }} className="py-1 m-0">
                {this.state.erroruser_email}
              </div>
            </div>
            <div className="form-group  m-0 py-1 row">
              <input type="password" className="form-control" id="statemailicEmail" placeholder="كلمة السر" name="password" value={this.state.password} onChange={this.handleChange} />

            </div>
            {this.props.errorMessage ?
              <div className="alert alert-danger m-0  my-2 py-2" role="alert">
                {this.props.errorMessage}
              </div>
              : ''}
            <button type="submit" className="btn  w-100 d-block py-2 stylelink fzcwhite  fw500 fz18 bgcm d-block my-3" role="button" aria-pressed="true" >
              الدخول
                          </button>
            <div className="text-center pt-3"> <Link to="/ForgotPassword" className="fzcm fz14 text-center fw500">نسيت كلمة المرور</Link>
            </div>
            <p className="borderdashed my-3"></p>
            <p className=" fz14 text-center fw500 fzcblack"> ليس لديك حساب ؟
                          <Link to="/Register" className="fzcm"> سجل الآن </Link>
            </p>
          </form>
        </section>





        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    errorMessage: state.User.errorMessage
  };
}
const mapDispatchToProps = ({
  Login: userAction.Login
})
export default connect(mapStateToProps, mapDispatchToProps)(Login);