import React from 'react';
import './Steps.scss';
import mdarrowleft from '../../../Images/mdarrowleft.svg'

class Steps extends React.Component {
  render() {
    return (
      <div className="steps  ">
        <div className="row m-0 p-0">
          <div className={this.props.step === 1 ? "step col-md " : " step col-md dismedia"}>
            <div className="row mb-2 active d-md-flex align-items-center " >
              <div className=" col-3 "><div className="stepnumber fz30">1</div></div>
              <div className="col-9 steptitle p-0  fz18  fw500 ">اختر مشروع لتوجه تبرعات إليه </div>
            </div>
            {(this.props.step == 1 ? <hr /> : "")}
          </div>
          <div className="col-1 text-center dismedia"><img src={mdarrowleft} className="" alt="next" style={{ width: 40 }} /></div>
          <div className={this.props.step === 2 ? "step col-md " : " step col-md dismedia"}>
            <div className={"row mb-2 d-flex align-items-center " + (this.props.step >= 2 ? "active" : "")}>
              <div className=" col-3 "><div className="stepnumber fz30">2</div></div>
              <div className="col-9 steptitle px-3  fz18  fw500 ">أنشئ حملة تبرعات</div>
            </div>
            {(this.props.step === 2 ? <hr /> : "")}
          </div>
          <div className="col-1 text-center dismedia"><img src={mdarrowleft} className="" alt="next" style={{ width: 40 }} /></div>
          <div className={this.props.step === 3 ? "step col-md " : " step col-md dismedia"}>
            <div className={"row mb-2 d-flex align-items-center " + (this.props.step >= 3 ? "active" : "")}>
              <div className=" col-3 "><div className="stepnumber fz30">3</div></div>
              <div className="col-9 steptitle px-3  fz18  fw500 ">انشرها على نطاق واسع</div>
            </div>
            {(this.props.step === 3 ? <hr /> : "")}

          </div>
        </div>
      </div>
    );
  }
}

export default Steps;