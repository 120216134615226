import React from 'react';
import './HowItWork.scss';
import { Link } from "react-router-dom";
import Footer from '../Components/Footer/Footer'
import { connect } from 'react-redux';
import addicon from '../../Images/addicon.svg'

class HowItWork extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  componentDidMount() {

  }


  render() {
    document.title = "InMemoryOf | How It Work";
    document.getElementsByTagName("META")[1].content = "IN MEMORY OF was created to help family and friends commemorate their loved ones through memorial donations";
    document.documentElement.lang = 'en';
    return (
      <div className="HowItWork">
        <div className="navhe"></div>
        <div className="container">
          <div className="py-2">
            <Link to="" className="fz12 fzcm px-2 fw500">الرئيسية</Link>
            <span className="fz12 fw500 mt-2">&gt;	</span>
          </div>
          <p className="borderdashed m-0"></p>
          <h3 className="fzcblack black fz24 fw500 py-3 ">كيف نعمل</h3>
        </div>
        <section className="container">
          {/* <header></header> */}
          <div className="row m-0 py-md-4">
            <div className="col-md-4 px-md-5">
              <div className="row mb-2 d-flex align-items-center ">
                <div className=" col-3 "><div className="stepnumber fzcm fz30">1</div></div>
                <p className="col-9 steptitle px-1 m-0 fz20 fzcm  fw500 ">ابدأ حملة التبرع</p>
              </div>

              <ul className="fz16 fw500">
                <li>	حدد مشروع التبرع من المشروعات المعروضة على الموقع من قبل المؤسسات الشريكة لتوجه تبرعات الحملة إليه </li>
                <li>	ادخل بيانات المتوفى وصورة شخصية</li>
                <li>	أضف بياناتك لتتمكن من متابعة الحملة</li>
              </ul>
            </div>
            <div className="col-md-4 px-md-5">
              <div className="row mb-2 d-flex align-items-center ">
                <div className=" col-3 "><div className="stepnumber fzcm fz30">2</div></div>
                <p className="col-9 steptitle px-1 m-0 fz20 fzcm  fw500 ">انشر الحملة</p>
              </div>
              <ul className="fz16 fw500">
                <li>	ارسل رابط الحملة شخصياً لمعارف وأصدقاء المتوفى عن طريق وسائل التواصل الاجتماعي </li>
                <li>	انشر رابط الحملة على وسائل التواصل الاجتماعي</li>
              </ul>
            </div>
            <div className="col-md-4 px-md-5">
              <div className="row mb-2 d-flex align-items-center ">
                <div className=" col-3 "><div className="stepnumber fzcm fz30">3</div></div>
                <p className="col-9 steptitle px-1 m-0 fz20 fzcm  fw500 ">تابع التبرعات</p>
              </div>
              <ul className="fz16 fw500">
                <li>	تابع تبرعات الرسائل النصية لحظياً</li>
                <li>	تابع تبرعات الكروت الائتمانية لحظياً</li>
                <li>	تابع تبرعات التحصيل المنزلي والتي يتم تأكيدها في خلال ٧٢ ساعة من موعد تحصيل مبلغ التبرع</li>
              </ul>
            </div>

          </div>
        </section>


        <div className="mb-5 mx-auto text-center">
          <Link to="/ChooseCampaign" className="btn px-5 py-2 stylelink  bordercm fzcm fzcwhite  fw500 fz18   my-3" role="button" aria-pressed="true" >
            <img src={addicon} alt="arrow" className="mx-1" style={{ width: 20, height: 20 }} />

            انشئ حملة تبرعات
           </Link>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {

  };
}
const mapDispatchToProps = ({
})
export default connect(mapStateToProps, mapDispatchToProps)(HowItWork);