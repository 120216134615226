import axios from "axios";
import Cookies from "js-cookie";
import {
  LINKAPIS,
  LINKPAYMENT,
  FETCH_5CAMPAIGNS,
  FETCH_ALL_CAMPAIGNS,
  FETCH_CATEGORIES,
  FETCH_NGOS,
  FETCH_CATEGORY_PROJECTS,
  FETCH_ALL_PROJECTS,
  FETCH_PROJECTS_ID,
  FETCH_CAMPAIGN_DETAILS,
  FETCH_CAMPAIGN_DONATIONS_DETAILS,
  FETCH_NGO_PROJECTS,
  FETCH_NGO_DETAILS,
  RESET_ALL_PROJECTS,
  FETCH_ALL_PROJECTS2,
  CREATE_DONATION,
  HANDLE_ERROR_GLOBAL,
  ERROR_MESSAGE_BACK_END,
  USERS_LOGOUT,
  RESET_ERRORS,
  FETCH_CITIES,
  FETCH_AREAS,
  FETCH_FAQs,
  FETCH_PRESS,
  RESET_NGOS,
  FETCH_FILTERS,
  RESET_ALL_CAMPAIGNS,
  POST_COUNTACTUS,
  FETCH_SEARCH,
  FORGOT_PASSWORD,
  USERS_LOGIN_FAILURE,
  GET_ACHIEVEMENTS,
  RESET_USER_ERROR,
  LOADING,
  GETMESSAGE,
  RESET_CATEGORY_PROJECTS
} from "./types";
import { history } from "../_helpers/history";
import { authHeader, authHeaderAPI } from "../_helpers/auth-header";
//////
export * from "./userAction";
/////// campaigns
export const getfilter = x => {
  return dispatch => {
    axios
      .get(`${LINKAPIS}/api/${x}/filter`, { headers: authHeaderAPI() })
      .then(res => dispatch({ type: FETCH_FILTERS, payload: res.data.data }))
      .catch();
  };
};
export const getachievements = () => {
  return dispatch => {
    axios
      .get(`${LINKAPIS}/api/about/achievements`, { headers: authHeaderAPI() })
      .then(res => dispatch({ type: GET_ACHIEVEMENTS, payload: res.data.data[0] }))
      .catch();
  };
};
export const searchdata = x => {
  return dispatch => {
    axios
      .get(`${LINKAPIS}/api/search?text=${x}&rowscount=20`, { headers: authHeaderAPI() })
      .then(res => dispatch({ type: FETCH_SEARCH, payload: res.data.data }))
      .catch();
  };
};
export const getAllCampaigns = (o, r, uid, filter = "default", search = "", Reset = false) => {
  return dispatch => {
    dispatch({ type: LOADING, payload: "ADDMORE_L" })

    axios
      .get(
        `${LINKAPIS}/api/campaigns/details?offset=${o}&rowscount=${r}&uid=${uid}&filter=${filter}&search=${search}`, { headers: authHeaderAPI() },

      )
      .then(res => {
        if (res.data.status == 1) {
          if (Reset) {
            dispatch({ type: RESET_ALL_CAMPAIGNS });

          }
          dispatch({ type: FETCH_ALL_CAMPAIGNS, payload: res.data.data })
          dispatch({ type: LOADING, payload: "ADDMORE_D" })
          dispatch({ type: GETMESSAGE, payload: { campaignsCard: false } })

        } else {
          if (Reset) {
            dispatch({ type: RESET_ALL_CAMPAIGNS });

          }
          dispatch({ type: GETMESSAGE, payload: { campaignsCard: res.data.message } })
          dispatch({ type: LOADING, payload: "ADDMORE_D" })
        }
      }

      )
      .catch(error => {
        if (error.response.status === 500) {
          dispatch({ type: LOADING, payload: "ADDMORE_D" })
        }
      }
      );
  };
};
export const resetAllCampaigns = () => {
  return dispatch => {
    dispatch({ type: RESET_ALL_CAMPAIGNS });
  };
};
// home
export const get5Campaigns = (uid, filter = "default") => {
  return dispatch => {
    axios
      .get(
        `${LINKAPIS}/api/campaigns/details?rowscount=5&uid=${uid}&filter=${filter}`, { headers: authHeaderAPI() }
      )
      .then(res => dispatch({ type: FETCH_5CAMPAIGNS, payload: res.data.data }))
      .catch();
  };
};

export const getcategories = () => async dispatch => {
  await axios
    .get(`${LINKAPIS}/api/campaigns/categories`, { headers: authHeaderAPI() })
    .then(res => {
      dispatch({ type: FETCH_CATEGORIES, payload: res.data.data })
    })
    .catch();
};
export const getcities = () => {
  return dispatch => {
    axios
      .get(`${LINKAPIS}/api/cities`, { headers: authHeaderAPI() })
      .then(res => dispatch({ type: FETCH_CITIES, payload: res.data.data }))
      .catch();
  };
};
export const getAreas = id => {
  return dispatch => {
    axios
      .get(`${LINKAPIS}/api/cities/${id}`, { headers: authHeaderAPI() })
      .then(res => {
        if (res.data.status == 1) {
          dispatch({ type: FETCH_AREAS, payload: res.data.data });
        } else {
          dispatch({ type: FETCH_AREAS, payload: [] });
        }
      })
      .catch(error => {
        dispatch(HandleCachError(error));
      });
  };
};
export const getCampaignsDetails = (id, uid=" ") => {
  return dispatch => {
    axios
      .get(`${LINKAPIS}/api/campaigns/details?campid=${id}&uid=${uid}`, { headers: authHeaderAPI() })
      .then(res => {
        if (res.data.status == 1) {
          dispatch({ type: FETCH_CAMPAIGN_DETAILS, payload: res.data.data });
        } else {
          history.push("/PathNotFound");
        }
      })
      .catch();
  };
};

export const getCampaignDonationsDetails = id => {
  return dispatch => {
    axios
      .get(`${LINKAPIS}/api/campaigns/donations?campid=${id}`, { headers: authHeaderAPI() })
      .then(res =>
        dispatch({
          type: FETCH_CAMPAIGN_DONATIONS_DETAILS,
          payload: res.data.data
        })
      )
      .catch();
  };
};
//NGOsListing
export const getngos = (o, r, search = "") => {
  return dispatch => {
    axios
      .get(
        `${LINKAPIS}/api/ngos/details?offset=${o}&rowscount=${r}&search=${search}`, { headers: authHeaderAPI() }
      )
      .then(res => {
        if (res.data.status == 1) {
          dispatch({ type: FETCH_NGOS, payload: res.data.data });
        } else {
          //   history.push("/");
        }
      })
      .catch();
  };
};
export const resetngos = (o, r, search = "") => {
  return dispatch => {
    dispatch({ type: RESET_NGOS });
  };
};
//NGOsListing
export const getngoDetails = id => {
  return dispatch => {
    axios
      .get(`${LINKAPIS}/api/ngos/details?ngoid=${id}`, { headers: authHeaderAPI() })
      .then(res => {
        if (res.data.status == 1) {
          dispatch({ type: FETCH_NGO_DETAILS, payload: res.data.data });
        } else {
          history.push("/PathNotFound");
        }
      })
      .catch(err => console.log(err.response));
  };
};
export const getProjectsBasedonNGO = id => {
  return dispatch => {
    axios
      .get(`${LINKAPIS}/api/ngos/projects?ngoid=${id}`, { headers: authHeaderAPI() })
      .then(res =>
        dispatch({ type: FETCH_NGO_PROJECTS, payload: res.data.data })
      )
      .catch();
  };
};
//ChooseCampaign ProjectsListing
export const getProjectsBasedonCategory = (
  id,
  o,
  r,
  filter = "",
  search = "",
  Reset = false
) => {
  return dispatch => {


    axios
      .get(
        `${LINKAPIS}/api/ngos/projects?categoryid=${id}&offset=${o}&rowscount=${r}&filter=${filter}&search=${search}`, { headers: authHeaderAPI() }
      )
      .then(res => {
        if (Reset) {
          dispatch({ type: RESET_CATEGORY_PROJECTS });
        }
        dispatch({ type: FETCH_CATEGORY_PROJECTS, payload: res.data.data })
      }
      )
      .catch();
  };
};
export const resetProjectsBasedonCategory = () => {
  return dispatch => {
    dispatch({ type: RESET_CATEGORY_PROJECTS });

  };
};

//projectlisting
export const getAllProjects = (o, r) => {
  return dispatch => {
    axios
      .get(`${LINKAPIS}/api/ngos/projects?offset=${o}&rowscount=${r}`, { headers: authHeaderAPI() })
      .then(res =>
        dispatch({ type: FETCH_ALL_PROJECTS, payload: res.data.data })
      )
      .catch();
  };
};
//chooseCampaign
export const getAllProjects2 = (o, r, filter = "default") => {
  return dispatch => {
    axios
      .get(`${LINKAPIS}/api/ngos/projects?offset=${o}&rowscount=${r}&filter=${filter}`, { headers: authHeaderAPI() })
      .then(res =>
        dispatch({ type: FETCH_ALL_PROJECTS2, payload: res.data.data }))
      .catch();
  };
};
// CreateCampaign,projectsListing
export const getProjectsId = id => {
  return dispatch => {
    axios
      .get(`${LINKAPIS}/api/ngos/projects?projectid=${id}`, { headers: authHeaderAPI() })
      .then(res => {
        if (res.data.status == 1) {
          dispatch({ type: FETCH_PROJECTS_ID, payload: res.data.data });
        } else {
          history.push("/PathNotFound");
        }
      })
      .catch();
  };
};

// export const createDonation = donation => {
//   return dispatch => {
//     axios
//       .post(`${LINKAPIS}/api/donations`, { donation }, { headers: authHeaderAPI() }, {
//         onUploadProgress: progressEvent => dispatch({ type: LOADING, payload: "PAY_L" })
//       })
//       .then(res => {
//         if (res.data) {
//           dispatch({ type: CREATE_DONATION, payload: res.data.data[0] });
//           if (donation.type === 2) {
//             dispatch({ type: LOADING, payload: "PAY_L" });
//             dispatch(BankIntegration(donation, res.data.data[0]));

//           } else {
//             dispatch({ type: LOADING, payload: "PAY_D" })
//             document.getElementById("closemodal").click(); // Click on the checkbox
//             document.getElementById("ModalSuccess").click(); // Click on the checkbox
//             dispatch(getCampaignDonationsDetails(donation.campaign_id));

//           }

//         } else {
//           dispatch({ type: HANDLE_ERROR_GLOBAL });
//         }
//       })
//       .catch(error => {
//         dispatch(HandleCachError(error));
//       });
//   };
// };
export const HandleSuccessPayment=()=>{
  return dispatch=>{
  let campaign_id=JSON.stringify(localStorage.getItem("campaign_id"));
  let myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Cookie", "__cfduid=d158bd9d0d1d14b88d5ded5aae210716e1585742295");
  let raw = JSON.stringify(localStorage.getItem("donation_id"));
  let requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
  };
fetch(`${LINKPAYMENT}/api/hostedcheckout/Save`, requestOptions)
  .then(response =>{
    console.log(campaign_id,JSON.stringify(localStorage.getItem("campaign_id")))
      dispatch(getCampaignsDetails(localStorage.getItem("campaign_id")));
      dispatch(getCampaignDonationsDetails(localStorage.getItem("campaign_id")));
      dispatch({ type: LOADING, payload: "PAY_D" });
      document.getElementById("closemodal").click();
      document.getElementById("ModalSuccess").click();
      setTimeout(function(){  localStorage.removeItem("donation_id");localStorage.removeItem("campaign_id")  }, 3000);
  })
  .catch(error =>dispatch(HandleCachError(error))  );
  }
}
export const HandlecancelledPayment=()=>{
  return dispatch=>{
    dispatch({ type: LOADING, payload: "PAY_D" });
    document.getElementById("closemodal").click();

  }
}
export const HandleErrorPayment=()=>{
  return dispatch=>{
    dispatch({ type: LOADING, payload: "PAY_D" });
    history.push("/TransactionFailed");
    document.getElementById("closemodal").click();

  }
}

export const createDonation = donation => {
  console.log("create ", donation);
  return dispatch => {
    axios
      .post(
        `${LINKAPIS}/api/donations`,
        { donation },
        { headers: authHeaderAPI() },
        {
          onUploadProgress: progressEvent =>
            dispatch({ type: LOADING, payload: "PAY_L" })
        }
      )
      .then(res => {
        if (res.data) {
          dispatch({ type: CREATE_DONATION, payload: res.data.data[0] });
          if (donation.type === 2) {
            dispatch({ type: LOADING, payload: "PAY_L" });
            if (donation.ngoId == 2) {
              localStorage.setItem("donation_id",res.data.data[0].donation_id)
              localStorage.setItem("campaign_id",donation.campaign_id)

              axios.post(`${LINKPAYMENT}/api/hostedcheckout/GetSessionId`,{
                "name": donation.user_name,
                "amount":  donation.amount,
                "msisdn": donation.msisdn,
                "donationId":  res.data.data[0].donation_id,
                "campaignID":  donation.campaign_id,
                "TransactionNumber": null
            })
              .then(res => {
                console.log(res.data.Message,"res")
                window.Checkout.configure({
                  session: {
                      id: res.data.Message
                  },
                  merchant: 'AHLMASR_MEGA',
                  order: {
                      amount: function () {
                          //Dynamic calculation of amount
                          return donation.amount;
                      },
                      currency: 'EGP',
                      description: 'Ordered goods',
                      id: Date.now()+""+Math.floor(Math.random() * 62) 
                  },
                  interaction: {
                      operation: 'PURCHASE', // set this field to 'PURCHASE' for Hosted Checkout to perform a Pay Operation.
                      merchant: {
                          name: 'ِAhl Masr',
                          address: {
                              line1: '200 Sample St',
                              line2: '1234 Example Town'
                          }
                      }
                  }
              });
              console.log()
                document.getElementById("showLightbox").click()
                // document.getElementById("showPaymentPage").click()
                // setTimeout(function(){  dispatch({ type: LOADING, payload: "PAY_D" });  }, 10000);
              })
              .catch(err=>{
                console.log(err,"err")
                history.push("/TransactionFailed"); 
                setTimeout(function(){  dispatch({ type: LOADING, payload: "PAY_D" });
                document.getElementById("closemodal").click();  }, 1000);
                setTimeout(function(){  history.goBack() }, 3000);
              })
            } else if (donation.ngoId == 4) {
              //resala NGO
              localStorage.setItem("donation_id",res.data.data[0].donation_id)
              localStorage.setItem("campaign_id",donation.campaign_id)

              axios.post(`${LINKPAYMENT}/api/hostedcheckout/GetSessionId`,{
                "name": donation.user_name,
                "amount":  donation.amount,
                "msisdn": donation.msisdn,
                "donationId":  res.data.data[0].donation_id,
                "campaignID":  donation.campaign_id,
                "TransactionNumber": null
            })
              .then(res => {
                console.log(res.data.Message,"res")
                window.Checkout.configure({
                  session: {
                      id: res.data.Message
                  },
                  merchant: "RESALA_MEMO",
                  order: {
                      amount: function () {
                          //Dynamic calculation of amount
                          return donation.amount;
                      },
                      currency: 'EGP',
                      description: 'Ordered goods',
                      id: Date.now()+""+Math.floor(Math.random() * 62) 
                  },
                  interaction: {
                      operation: 'PURCHASE', // set this field to 'PURCHASE' for Hosted Checkout to perform a Pay Operation.
                      merchant: {
                        name: "Resala In Memory Of",
                        address: {
                              line1: '200 Sample St',
                              line2: '1234 Example Town'
                          }
                      }
                  }
              });
              console.log()
                document.getElementById("showLightbox").click()
                // document.getElementById("showPaymentPage").click()
                // setTimeout(function(){  dispatch({ type: LOADING, payload: "PAY_D" });  }, 10000);
              })
              .catch(err=>{
                console.log(err,"err")
                document.getElementById("closemodal").click();
                history.push("/TransactionFailed"); 
                setTimeout(function(){  dispatch({ type: LOADING, payload: "PAY_D" });
                document.getElementById("closemodal").click();  }, 1000);
                setTimeout(function(){  history.goBack() }, 3000);
              })
             } else {
              dispatch(BankIntegration(donation, res.data.data[0]));
            }
          } else {
            dispatch({ type: LOADING, payload: "PAY_D" });
            document.getElementById("closemodal").click(); // Click on the checkbox
            document.getElementById("ModalSuccess").click(); // Click on the checkbox
            dispatch(getCampaignDonationsDetails(donation.campaign_id));
          }
        } else {
          dispatch({ type: HANDLE_ERROR_GLOBAL });
        }
      })
      .catch(error => {
        dispatch(HandleCachError(error));
      });
  };
};
export const BankIntegration = (donation, resdonation) => {
  return dispatch => {
    axios
      .post(`${LINKPAYMENT}/api/Transaction/Submit`, {
        name: donation.user_name,
        amount: donation.amount,
        msisdn: donation.msisdn,
        campaignID: donation.campaign_id,
        donationId: resdonation.donation_id,
        TransactionNumber: ""
      }, { headers: authHeaderAPI() })
      .then(res => {
        if (res.data.Success == 1) {

          window.location.assign(res.data.URL)
          dispatch({ type: LOADING, payload: "PAY_D" })

          // window.open(res.data.URL)
          // document.getElementById('mylink').href = res.data.URL
          // console.log(window.document.getElementById('mylink'), res.data.URL)
          // document.getElementById('mylink').click();
        } else {
          document.getElementById("closemodal").click(); // Click on the checkbox
          dispatch({ type: LOADING, payload: "PAY_D" })
          history.push("/transactionfailed")
        }
      })
      .catch(err => err.response);
  };
};

export const handleTransaction = (link) => {
  return dispatch => {
        // BankAhlyIntegration
    axios
      .get(`${LINKPAYMENT}/Response.aspx${link}`, { headers: authHeaderAPI() })
      .then(res => {
        if (res.data.Status == 1) {
          dispatch({ type: CREATE_DONATION, payload: { donation_id: res.data.donationID } });
          // dispatch(getCampaignDonationsDetails(res.data.campaignID));
          history.push(`/CampaignDonation/${res.data.campaignName.replace(/ /g, "_")}/${res.data.campaignID}`)
          document.getElementById("ModalSuccess").click(); // Click on the checkbox
        } else {
          history.push("/transactionfailed")
        }
      })
      .catch(err => err.response);
  };
};
export const getFAQs = () => {
  return dispatch => {
    axios
      .get(`${LINKAPIS}/api/about/faq`, { headers: authHeaderAPI() })
      .then(res => dispatch({ type: FETCH_FAQs, payload: res.data.data }))
      .catch();
  };
};
export const getpress = () => {
  return dispatch => {
    axios
      .get(`${LINKAPIS}/api/about/press`, { headers: authHeaderAPI() })
      .then(res => dispatch({ type: FETCH_PRESS, payload: res.data.data }))
      .catch();
  };
};

export const postContactUs = state => {
  return dispatch => {
    axios
      .post(`${LINKAPIS}/api/about/contactus`, {
        name: state.name,
        msisdn: state.phone,
        from: state.email,
        message: state.details
      }, { headers: authHeaderAPI() }, {
        onUploadProgress: progressEvent => dispatch({ type: POST_COUNTACTUS, payload: 2 })
      })
      .then(res => {
        dispatch({ type: POST_COUNTACTUS, payload: 1 });
      })

      .catch(error => {
        if (error.response.data.message) {
          dispatch({ type: POST_COUNTACTUS, payload: 3 });
          setTimeout(() => {
            dispatch({ type: POST_COUNTACTUS, payload: 0 });
          }, 5000);
        } else {
          dispatch(HandleCachError(error));

        }
      });
  };
};



export const resetProject = id => {
  return dispatch => {
    dispatch({ type: RESET_ALL_PROJECTS });
  };
};
export const thousands_separators = (num) => {
  return dispatch => {
    if (num) {

      var num_parts = num.toString().split(".");
      num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return num_parts.join(".");

    } else {
      num = 0
      return num;

    }
  };

}

export const Forgotpassword = (email) => {
  return dispatch => {
    axios
      .post(`${LINKAPIS}/api/auth/forgotpass`, { email: email }, { headers: authHeaderAPI() }, {
        onUploadProgress: progressEvent => dispatch({ type: FORGOT_PASSWORD, payload: 2 })
      })
      .then(res => {
        if (res.data.status == 1) {
          dispatch({ type: FORGOT_PASSWORD, payload: 1 })
          dispatch({ type: RESET_USER_ERROR })

        } else {
          dispatch({ type: USERS_LOGIN_FAILURE, payload: res.data.message })
          dispatch({ type: FORGOT_PASSWORD, payload: 0 })
        }
      }
      )
      .catch(dispatch({ type: FORGOT_PASSWORD, payload: 0 })

      );
  };
};
function HandleCachError(error) {
  return dispatch => {
    if (!error.response) {
      dispatch({ type: HANDLE_ERROR_GLOBAL });
      setTimeout(() => {
        dispatch({ type: RESET_ERRORS });
      }, 5000);
    } else if (error.response.status === 401) {
      Cookies.remove("user");
      dispatch({ type: USERS_LOGOUT });
      history.push("/Login");
    } else if (error.response.data.message) {
      dispatch({
        type: ERROR_MESSAGE_BACK_END,
        payload: error.response.data.message
      });
      setTimeout(() => {
        dispatch({ type: RESET_ERRORS });
      }, 5000);
    } else {
      dispatch({ type: HANDLE_ERROR_GLOBAL });
      setTimeout(() => {
        dispatch({ type: RESET_ERRORS });
      }, 5000);
    }
  };
}
export const resetErorrs = () => {
  return dispatch => {
    dispatch({ type: RESET_ERRORS });
  };
};
