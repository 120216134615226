import React from 'react';
import './MyDonations.scss';
import { Link } from "react-router-dom";
import { userAction } from '../../redux/actions/userAction'
import Footer from '../Components/Footer/Footer'
import arrowcm from '../../Images/arrowcm.svg'
import { connect } from 'react-redux';
import heart from '../../Images/heart.svg'
import CardMyDonation from '../Components/CardMyDonation/CardMyDonation';
import { thousands_separators } from '../../redux/actions'


class MyDonations extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      rowscounts: 20,
    };
  }
  componentDidMount() {
    if (this.props.user_Donations && this.props.user_Donations.length == 0) {
      this.props.UserDonations(this.state.offset, this.state.rowscounts)
    }
  }

  render() {
    document.title = "InMemoryOf | My Donations";
    document.getElementsByTagName("META")[1].content = "IN MEMORY OF was created to help family and friends commemorate their loved ones through memorial donations";
    document.documentElement.lang = 'en';
    // const text = (this.props.user_Donations.length > 0 ? '' : <p className="py-3 animationtext fw500 fz20 m-auto text-center">لا توجد اي تبرعات قمت بها   </p>)

    return (
      <div className="MyDonations">
        <div className="navhe"></div>
        <div className="container">
          <div className="py-2">
            <Link to="" className="fz12 fzcm px-2 fw500">الرئيسية</Link>
            <span className="fz12 fw500 mt-2">&gt;	</span>
            <Link to="/MyProfile" className="fz12 fzcm px-2 fw500">حسابى</Link>
            <span className="fz12 fw500 mt-2">&gt;	</span>
          </div>
          <p className="borderdashed m-0"></p>
          <div className="d-md-flex justify-content-between py-3">
            <h3 className="fzcblack black fz24 fw500  m-0 center-v c">تبرعاتى
            ({this.props.thousands_separators(this.props.total_donations)} ج.م)
            </h3>


            <div className=" px-md-4   d-flex justify-content-between">
              <Link to="/Campaigns" className="btn px-4 col mx-0 my-3 bordercm fzcm fzcwhite fw500  fz16" >
                <img src={heart} alt="arrow" className="mx-2" style={{ width: 25, height: 25 }} />
                تبرع لحملة قائمة</Link>

            </div>
          </div>
        </div>


        <section className="allNGOs py-3">
          <div className="container">

            <div className="row mx-auto ">
              {this.props.user_Donations &&

                <CardMyDonation thousands_separators={this.props.thousands_separators} campaigns={this.props.user_Donations} />
              }
              {/* {text} */}
            </div>









            {/* <p to="/" className="btn bgcwhite pointer col mx-2 bordercm fzcm  fw500 px-4 my-3 fz18" >
          حمل المزيد
          <img src={arrowcm} alt="arrow"/>
          </p> */}
            {parseInt(this.props.total_of_donations) > this.props.user_Donations.length && this.props.user_Donations.length > 0 ?
              <p onClick={() => this.props.UserDonations(this.props.user_Donations.length, this.state.rowscounts)} className="btn bgcwhite pointer col mx-0 bordercm fzcm  fw500 px-4 my-3 fz18" >
                حمل المزيد
            <img src={arrowcm} alt="arrow" />
              </p> : ''
            }

          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user_Donations: state.User ? state.User.userDonations : false,
    total_of_donations: state.User.userDonations ? state.User.userDonations[0] ? state.User.userDonations[0].total_of_donations : '' : 0,
    total_donations: state.User.userDonations ? state.User.userDonations[0] ? state.User.userDonations[0].total_donations : '' : 0,
  };
}
const mapDispatchToProps = ({
  UserDonations: userAction.UserDonations,
  thousands_separators
})
export default connect(mapStateToProps, mapDispatchToProps)(MyDonations);