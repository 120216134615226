import React from 'react';
import './TrustandSafety.scss';
import { Link } from "react-router-dom";

import Footer from '../Components/Footer/Footer'
import { connect } from 'react-redux';

class TrustandSafety extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  componentDidMount() {

  }


  render() {
    document.title = "InMemoryOf | Trust and Safety";
    document.getElementsByTagName("META")[1].content = "IN MEMORY OF was created to help family and friends commemorate their loved ones through memorial donations";
    document.documentElement.lang = 'en';
    return (
      <div className="TrustandSafety">
        <div className="navhe"></div>
        <div className="container">
          <div className="py-2">
            <Link to="" className="fz12 fzcm px-2 fw500">الرئيسية</Link>
            <span className="fz12 fw500 mt-2">&gt;	</span>
          </div>
          <p className="borderdashed m-0"></p>
          <h3 className="fzcblack black fz24 fw500 py-3 ">الثقة والسلامة</h3>
          <p className=" fz16 fw500 ">
            موقع In Memory of هو أحد منتجات شركة كورام للبرمجيات والتجارة الإلكترونية ومقرها ١١١مكرر ش فريد سميكة - مصر الجديدة - القاهرة - جمهورية مصر العربية. نحن نأخذ على عاتقنا ضمان سلامة وشفافية تبرعاتكم من خلال موقعنا ونضمن أن جميع تبرعاتكم تذهب مباشرةً إلى المؤسسات الخيرية الغير هادفة للربح من خلال السبل التالية.
<br />
            In Memory of هي فقط أداة للمؤسسات الخيرية الغير هادفة للربح للتمويل الجماعي للتبرعات. ومع ذلك ، فنحن لا نجمع أموال التبرع مباشرةً تحت أي ظرف من الظروف.
</p>
          <p className="fz16 fzcm fw500">ما تريد أن تعرفه بشأن التبرعات و المشاركات</p>
          <ul className="fz16 fw500">
            <li>	تتم عمليات التحصيل النقدي من خلال ممثل عن المؤسسة الخيرية غير الهادفة للربح وتقع على عاتق المؤسسة مسؤولية تزويد الجهة المانحة بإيصال استلام النقدية</li>
            <li>	تتم عملية الدفع الإلكتروني عن طريق صفحة الدفع البنكية الخاصة بالمؤسسات الخيرية ولا يتحمل موقع In Memory of مسؤولية جمع أموال التبرع من المتبرعين</li>
            <li>	تتم عملية التبرع عن طريق الرسائل النصية القصيرة عن طريق الرقم الهاتفي المختصر الخاص بالمؤسسة الخيرية لتصل مبالغ التبرع للمؤسسات مباشرةً</li>
            <li>	لا يقوم موقع In Memory of بخصم أي مبالغ من أموال التبرع ولكن نقوم بتحصيل رسوم خدمة من المؤسسات الخيرية غيرالهادفة للربح شهرياً بنسبة ٥٪ من إجمالي مبالغ التبرعات المحصلة شهرياً ولا يتم خصم هذه الرسوم من مبلغ التبرع نهائياً.</li>
          </ul>

        </div>

        <section className="container">

        </section>

        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {

  };
}
const mapDispatchToProps = ({
})
export default connect(mapStateToProps, mapDispatchToProps)(TrustandSafety);