import {combineReducers} from 'redux'
import Campaigns from './Campaigns';
import NGOS from './Ngos';
import User from './User';
import Errors from './Errors';

export default combineReducers({
    Campaigns :Campaigns,
    NGOS:NGOS,
    User:User,
    Errors:Errors,

})
   