import React from 'react';
import './NGODetails.scss';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { getngoDetails, getProjectsBasedonNGO, thousands_separators } from '../../redux/actions'
import Footer from '../Components/Footer/Footer'
import smsblack from '../../Images/smsblack.svg'
import moneyblack from '../../Images/moneyblack.svg'
import fundsblack from '../../Images/fundsblack.svg'
import creditcardblack from '../../Images/creditcardblack.svg'
import enterpriseblack from '../../Images/enterpriseblack.svg'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Loadable from '@loadable/component';
import mdarrowleft from '../../Images/mdarrowleft.svg'
import mdarrowright from '../../Images/mdarrowright.svg'
import { LINKIMAGES } from "../../redux/actions/types";
const OwlCarousel = Loadable(() => import('react-owl-carousel'));

class NGODetails extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      responsive: {
        200: {
          items: 1
        },
        500: {
          items: 2
        },
        800: {
          items: 3
        },
        1200: {
          items: 5

        }
      }
    };
  }
  componentDidMount() {
    const id = this.props.match.params.id
    this.props.getngoDetails(id)
    this.props.getProjectsBasedonNGO(id)
  }

  render() {
    document.title = "InMemoryOf | " + this.props.NGODetails.ar_name;
    document.getElementsByTagName("META")[1].content = this.props.NGODetails.ar_name;
    document.documentElement.lang = 'en';

    return (
      <div className="NGODetails">
        <div className="navhe"></div>
        <div className="container">
          <div className="py-2">
            <Link to="/" className="fz12 fzcm px-2 fw500">الرئيسية</Link>
            <span className="fz12 fw500 mt-2">&gt;	</span>
            <Link to="/NGOsListing" className="fz12 fzcm px-2 fw500">الجمعيات الخيرية</Link>
            <span className="fz12 fw500 mt-2">&gt;	</span>
          </div>
          <p className="borderdashed m-0"></p>
        </div>
        <section className="NGOinfo">
          <div className="container">

            <div className="row m-0">
              <div className=" my-2 mx-auto">
                <figure className="figure">
                  <img src={LINKIMAGES + this.props.NGODetails.ngo_image} className="" alt="NGO" style={{ width: 190, height: 190, objectFit: "contain", border: " 1px solid #F1F1F1", boxShadow: " 0px 3px 6px #00000029" }} />
                </figure>
              </div>
              <div className="col-md">
                <p className="fz24 fw500 my-3 ">{this.props.NGODetails.ar_name}</p>
                <p className="borderdashed m-0"></p>
                <div className="row mx-0  my-1">
                  <p className=" fz16 fw500 my-1 ">عدد المشاريع</p>
                  <div className="col"><span className="fw500 fz24  my-1">{this.props.thousands_separators(this.props.NGODetails.total_projects)}</span> </div>
                </div>
                <p className="borderdashed m-0"></p>
                <div className="row mx-0  my-3">
                  <p className=" fz16 fw500 px-0">وسائل الدفع</p>
                  <div className="col  fzcmuted">
                    <img src={fundsblack} style={{ height: 27, width: 27, objectFit: "contain" }} className="mx-2" />|
                                <img src={creditcardblack} style={{ height: 27, width: 27, objectFit: "contain" }} className="mx-2" />|
                                <img src={smsblack} style={{ height: 27, width: 27, objectFit: "contain" }} className="mx-2" />

                  </div>
                </div>

              </div>
              <p className="col-12 fz14 fzcmuted py-3 px-4">{this.props.NGODetails.ngo_description}</p>
            </div>

          </div>
        </section>
        <section className="ngoProjects">
          {this.props.NGOSProjects && this.props.NGOSProjects.length > 0 &&
            <div className="container">
              <h3 className="fz22 fw500  py-3 m-0  px-3">المشروعات الخاصة بالجمعية</h3>
              {this.props.NGOSProjects ?
                <OwlCarousel
                  className="owl-theme mb-4 "
                  loop={false}
                  items={5}
                  center
                  dots={true}
                  margin={0}
                  nav={true}
                  startPosition={this.props.NGOSProjects.length > 4 ? 2 : 0}
                  responsive={this.state.responsive}
                  // responsiveclassName={true}
                  // responsive={}
                  navText={[` <img src=${mdarrowleft} alt="logotype" className="arrowslider" />`,
                  `<img src=${mdarrowright}  alt="logotype" className="arrowslider" />`
                  ]}

                >
                  {
                    this.props.NGOSProjects.map((x, i) => {
                      return (
                        <Link to={"/CreateCampaign/" + x.project_ar_name.replace(/ /g, "_") + "/" + x.project_id} className="item stylelink " key={i}>
                          <div className="projectitem  p-3 my-3 bgcwhite text-center mx-auto pointer" >
                            <p className="py-1 fw500 fz16 fzcblock text-center m-0 fh56">{x.project_ar_name}</p>
                            <p className="py-1 fw500 fz22 fzcblock text-center m-0 fh35">{this.props.thousands_separators(x.project_amount)} <span className="fz12">ج.م</span></p>
                          </div>
                        </Link>
                      )
                    })
                  }

                  {/* <div  className="item ">
                  <div className="projectitem  p-3 my-3 bgcwhite text-center mx-auto " >
                    
                    <p className="py-1 fw500 fz16 fzcblock text-center m-0">مستلزمات طبية لمستشفي أبو الريش</p>
                    <p className="py-1 fw500 fz22 fzcblock text-center m-0">  2,000 <span className="fz12">ج.م</span></p>
                  </div>   
                </div>
                <div  className="item ">
                  <div className="projectitem  p-3 my-3 bgcwhite text-center mx-auto " >
                    
                    <p className="py-1 fw500 fz16 fzcblock text-center m-0">مستلزمات طبية لمستشفي أبو الريش</p>
                    <p className="py-1 fw500 fz22 fzcblock text-center m-0">  2,000 <span className="fz12">ج.م</span></p>
                  </div>   
                </div>
                <div  className="item ">
                  <div className="projectitem  p-3 my-3 bgcwhite text-center mx-auto " >
                    
                    <p className="py-1 fw500 fz16 fzcblock text-center m-0">مستلزمات طبية لمستشفي أبو الريش</p>
                    <p className="py-1 fw500 fz22 fzcblock text-center m-0">  2,000 <span className="fz12">ج.م</span></p>
                  </div>   
                </div>
                <div  className="item ">
                  <div className="projectitem  p-3 my-3 bgcwhite text-center mx-auto " >
                    
                    <p className="py-1 fw500 fz16 fzcblock text-center m-0">مستلزمات طبية لمستشفي أبو الريش</p>
                    <p className="py-1 fw500 fz22 fzcblock text-center m-0">  2,000 <span className="fz12">ج.م</span></p>
                  </div>   
                </div>
                 */}

                </OwlCarousel> : ''}
            </div>
          }
        </section>
        <section>
          <div className="container">
            <h3 className="fz22 fw500  pb-3 m-0  px-3 ">ارقام تخص الجمعية</h3>
            <div className="row m-0 py-3">
              <div className=" text-center mx-4">
                <img src={moneyblack} style={{ width: 55, height: 55, objectFit: "contain" }} alt="logotype" className="m-auto" />
                <p className="fz24 fw500 m-0">{this.props.thousands_separators(this.props.NGODetails.donations_collected)}</p>
                <p className="fz14  fw500 m-0">تبرعات</p>
              </div>
              <div className=" text-center  mx-4">
                <img src={enterpriseblack} style={{ width: 55, height: 55, objectFit: "contain", }} alt="logotype" className="m-auto" />
                <p className="fz24 fw500 m-0">{this.props.thousands_separators(this.props.NGODetails.units_completed)}</p>
                <p className="fz14  fw500 m-0">وحدات مكتمله</p>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    NGODetails: state.NGOS.NGODetails ? state.NGOS.NGODetails[0] : '',
    NGOSProjects: state.NGOS.projects

  };
}
const mapDispatchToProps = ({
  getngoDetails: getngoDetails,

  getProjectsBasedonNGO: getProjectsBasedonNGO,
  thousands_separators
})
export default connect(mapStateToProps, mapDispatchToProps)(NGODetails);