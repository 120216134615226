import React from "react";
import "./CreateCampaign.scss";
import { Link } from "react-router-dom";

import prearrow from "../../Images/prearrow.svg";
import nextarrow from "../../Images/nextarrow.svg";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Footer from "../Components/Footer/Footer";
import Steps from "../Components/Steps/Steps";
import user from "../../Images/user.svg";
import Done from "../../Images/Done.svg";
import { connect } from "react-redux";

import { getProjectsId, thousands_separators } from "../../redux/actions";
import { userAction } from '../../redux/actions'
class CreateCampaign extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      campaign: {
        project_id: this.props.match.params.id,
        campaign_title: "",
        campaign_description: "",
        collected: 0,
        phone: '',
        type: 1,
        user_id: null,
        campaign_image: false
      },
      name: "",
      email: "",
      password: "",
      passwordconfirm: "",

    };
  }



  componentDidMount() {
    const id = this.props.match.params.id;
    this.props.getProjectsId(id);
  }
  handelclosemodal = () => {
    document.getElementById("closemodal").click(); // Click on the checkbox
  };

  handleFormcreatecampiagn = e => {
    this.setState({
      campaign: {
        ...this.state.campaign,
        [e.target.name]: e.target.value
      }
    })
  };

  handleChange = e => {
    this.setState({
      // ...this.state,
      [e.target.name]: e.target.value
    })
  };
  onChangeimage = e => {
    this.setState({
      // ...this.state,
      campaign: {
        ...this.state.campaign,
        campaign_image: e.target.files[0]
      },
      sizeimage: e.target.files[0].size
    }, () => this.state.info)
  };
  validate = () => {
    let errorcampaign_title = "";
    let errorname = "";
    let errorphone = "";
    let errorpassword = "";
    let errorconfirmpassword = "";
    let erroremail = "";
    let errorsizeimage = "";

    if (this.state.campaign.campaign_title.length < 2) {
      errorcampaign_title = "ادخل الاسم  صحيح";
    } else {
      if (!this.state.campaign.campaign_title.match(/^[ء-ي0-9 a-zA-Z]+$/)) {
        errorcampaign_title = "يجب ان لا تحتوي علي اي رموز  :!@#$%&";
      }
    }


    if (isNaN(this.state.campaign.phone) || this.state.campaign.phone.length !== 11) {
      errorphone = "رقم الهاتف غير صحيح يجب ان يبداء ب *********01";
    }
    if (this.state.sizeimage > 3e+6) {
      errorsizeimage = "3MP يجب ان تكون مساحة الصوره اقل من  ";
    }
    // if (!this.state.campaign.campaign_image) {
    //   errorsizeimage = "يجب وضع صوره ";
    // }
    if (!this.props.IsLoggedIn) {
      if (this.state.name.length < 2) {
        errorname = "ادخل الاسم  صحيح";
      } else {
        if (!this.state.name.match(/^[ء-ي0-9 a-zA-Z]+$/)) {
          errorname = "يجب ان لا تحتوي علي اي رموز  :!@#$%&";
        }
      }
      if (this.state.password.length < 8) {
        errorpassword = "يجب ان يكون كلمة السر اكبر من 8 حروف او ارقام";
      }
      if (!this.state.email.includes("@")) {
        erroremail = "بريد الكتروني غير صحبح";
      }
      if (this.state.password !== this.state.confirmpassword) {
        errorconfirmpassword = "كلمة السر غير مطابقه";
      }
    }

    if (errorcampaign_title || errorname || erroremail || errorphone || errorpassword || errorconfirmpassword || errorsizeimage) {
      this.setState({ errorcampaign_title, errorname, erroremail, errorphone, errorpassword, errorconfirmpassword, errorsizeimage });
      return false;
    }
    return true;
  };
  handleSubmitCampaign = e => {
    e.preventDefault();
    const isValid = this.validate();

    if (isValid) {
      if (!this.props.IsLoggedIn) {
        if (this.props.user.verified === false) {
          // this.props.reseterroruser()
          this.setState({
            campaign: {
              ...this.state.campaign,
              user_id: this.props.user_id
            }
          }, () => this.props.Loginandcreate(this.state.email, this.state.password, this.state.campaign))
        } else {
          this.props.Register(this.state.name, this.state.email, this.state.password)
        }

      } else if (this.props.IsLoggedIn) {
        this.setState({
          campaign: {
            ...this.state.campaign,
            user_id: this.props.user_id
          }
        }, () => this.props.createCampaignItem(this.state.campaign))
      }

      this.setState({
        errorcampaign_title: "",
        errorname: "",
        erroremail: "",
        errorphone: "",
        errorpassword: "",
        errorsizeimage: "",
        errorconfirmpassword: ""
      })

    };
    // this.props.createCampaignItem(this.formModel);
  };

  render() {
    document.title = "InMemoryOf | Create Campaign";
    document.getElementsByTagName("META")[1].content = "IN MEMORY OF was created to help family and friends commemorate their loved ones through memorial donations";
    document.documentElement.lang = 'en';
    return (
      <div className="CreateCampaign">
        <form onSubmit={this.handleSubmitCampaign}>

          <div className="navhe"></div>
          <div className="container">
            <div className="py-2">
              <Link to="" className="fz12 fzcm px-2 fw500">
                الرئيسية
            </Link>
              <span className="fz12 fw500 mt-2">&gt; </span>
              <Link to="Campaigns" className="fz12 fzcm px-2 fw500">
                اكتشف
            </Link>
              <span className="fz12 fw500 mt-2">&gt; </span>
            </div>
            <p className="borderdashed m-0"></p>
            <h3 className="fzcblack black fz24 fw500 py-4 ">ابدأ حملة تبرعات</h3>
          </div>
          <div className="container">
            <Steps step={2} />
          </div>
          <section className="createDonations py-3 px-2">
            <div className="container">
              <div className="row m-0 pt-3">
                <div className="col-md-8 p-0">
                  <p className=" pt-3  fz18 fw500 ">
                    انشاء حملة تبرعات لصالح
                  <span className="fzcm px-2 fw500 ">
                      {this.props.ProjectsDetails.ngo_ar_name}
                    </span>
                  </p>
                </div>
                <div className="col-md-4 ">
                  <div className="row d-flex align-items-center">
                    <span className="fw500 fz18 ">هدف الحملة</span>
                    <div className="b_r d-inline border px-4 py-1 mx-auto">
                      <p className="fz37 fw600 m-0 text-center" style={{ width: 170 }} >
                        {this.props.thousands_separators(this.props.ProjectsDetails.project_amount)}
                        <span className="fz10 fw300"> ج.م </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>


              <p className="fz18 fw500 px-2 m-0 fzcblack labelstyle">
                بيانات المتوفى
              </p>

              <div className=" b_r border row p-3">
                <div className="col-md-7 py-3">
                  <div className="form-group  row">
                    <label htmlFor="campaign_title" className="col-sm-2 px-0 col-form-label d-flex justify-content-between"  >
                      الاسم<span className="require">*</span>
                    </label>
                    <div className="col-sm-7 col-md-6">
                      <input type="text" className="form-control" name="campaign_title" value={this.state.campaign.campaign_title}
                        onChange={this.handleFormcreatecampiagn} placeholder="" />
                      <div style={{ fontSize: 12, color: "red" }} className="py-1 m-0">
                        {this.state.errorcampaign_title}
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="campaign_description"
                      className="col-sm-2 px-0 col-form-label"
                    >
                      نبذه عنه
                    </label>
                    <div className="col-sm-10">
                      <textarea className="form-control" id="campaign_description" rows="2" name="campaign_description"
                        value={this.state.campaign.campaign_description} onChange={this.handleFormcreatecampiagn}
                      ></textarea>
                    </div>
                  </div>
                </div>

                <div className="col-md-5">
                  <figure className=" col-md-4 figure m-0 p-0 mq-sm-center" onClick={() => document.getElementById("campaign_image").click()}>
                    <img src={this.state.campaign.campaign_image ? URL.createObjectURL(this.state.campaign.campaign_image) : user} className="figure-img img-fluid m-0 bgcwhite border" style={{ width: 140, height: 140, objectFit: "contain" }} alt="profilepicture" />
                    <figcaption className="fzcm text-center ">
                      <a className=""  >اختر صورة</a>
                      <input type="file" name="campaign_image" id="campaign_image" onChange={this.onChangeimage} style={{ display: "none" }} />
                    </figcaption>
                  </figure>
                  <div style={{ fontSize: 12, color: "red" }} className="py-1 m-0">
                    {this.state.errorsizeimage}
                  </div>
                </div>
              </div>

              <p className="fz18 fw500 px-2 m-0 fzcblack labelstyle">
                بيانات منشئ الحملة
              </p>

              <div className=" b_r border row p-3 mb-4">
                {this.props.IsLoggedIn ?
                  <div className="form-group col-md-6 py-1 m-0  row">
                    <label htmlFor="name" className="col-sm-2 px-0 col-form-label d-flex justify-content-between"  >
                      الاسم <span className="require">*</span>
                    </label>
                    <div className="col-sm-9 col-md-8">
                      <input type="text" className="form-control" id="name" placeholder="" name="name"
                        value={this.props.user_name} disabled />

                    </div>
                  </div>
                  : ""}
                <div className="form-group col-md-6 py-1 m-0  row">
                  <label htmlFor="phone" className="col-sm-2 px-0 col-form-label  d-flex justify-content-between"  >

                    موبايل<span className="require">*</span>
                  </label>
                  <div className="col-sm-9 col-md-8">
                    <input
                      type="text"
                      className="form-control"
                      id="phone"
                      placeholder=""
                      name="phone"
                      value={this.state.phone}
                      onChange={this.handleFormcreatecampiagn}
                    />
                    <div style={{ fontSize: 12, color: "red" }} className="py-1 m-0">
                      {this.state.errorphone}
                    </div>
                  </div>
                </div>
                {this.props.IsLoggedIn ? "" : <>
                  <div className="text-center fzcbs col-11 mb-4">
                    <span className="fzcblack labelstyle px-1">
                      بيانات انشاء حساب جديد
                  </span>

                    <p className="borderdashed mx-5"></p>
                    <Link to="/Login" className="fz10 stylelink fzcmuted">
                      لديك حساب بالفعل؟
                    <span className="fzcm underline">اضغط هنا</span>
                    </Link>
                  </div>
                  {this.props.user.verified === false ?
                    <div className="text-center mx-auto">
                      <h3 className="fzcblack  fz22 fw500 mx-auto py-3  ">تم إرسال رساله الي بريدك الالكتروني قم بفتحها لتفعيل الحساب </h3>
                      <p className="fzcblack  fz22 fw500 mx-auto py-3  ">و بعد تفعيلك للحساب اضغط علي التالي</p>
                    </div>
                    :
                    <>
                      <div className="form-group col-md-6 py-1 m-0  row">
                        <label htmlFor="name" className="col-sm-2 px-0 col-form-label d-flex justify-content-between"  >
                          الاسم <span className="require">*</span>
                        </label>
                        <div className="col-sm-9 col-md-8">
                          <input type="text" className="form-control" id="name" placeholder="" name="name"
                            value={this.state.name} onChange={this.handleChange} />
                          <div style={{ fontSize: 12, color: "red" }} className="py-1 m-0">
                            {this.state.errorname}
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-md-6 m-0 py-1 row">

                        <label
                          htmlFor="email"
                          className="col-sm-2 px-0 col-form-label d-flex justify-content-between"
                        >
                          البريد الالكترونى <span className="require">*</span>
                        </label>
                        <div className="col-sm-9 col-md-8">
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            placeholder=""
                            name="email"
                            value={this.state.email}
                            onChange={this.handleChange}
                          />
                          <div style={{ fontSize: 12, color: "red" }} className="py-1 m-0">
                            {this.state.erroremail}
                          </div>
                        </div>
                      </div>
                      {/* <div className="form-group col-md-6  m-0 py-1 row"></div> */}
                      <div className="form-group col-md-6  m-0 py-1 row">
                        <label
                          htmlFor="password"
                          className="col-sm-2 px-0 col-form-label d-flex justify-content-between"
                        >
                          كلمة السر <span className="require">*</span>
                        </label>
                        <div className="col-sm-9 col-md-8 ">
                          <input
                            type="password"
                            className="form-control"
                            id="password"
                            placeholder=""
                            name="password"
                            value={this.state.password}
                            onChange={this.handleChange}
                          />
                          <div style={{ fontSize: 12, color: "red" }} className="py-1 m-0">
                            {this.state.errorpassword}
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-md-6  m-0 py-1 row">
                        <label
                          htmlFor="confirmpassword"
                          className="col-sm-2 px-0 col-form-label d-flex justify-content-between"
                        >
                          تأكيد كلمة السر <span className="require">*</span>
                        </label>
                        <div className="col-sm-9 col-md-8 ">
                          <input
                            type="password"
                            className="form-control"
                            id="confirmpassword"
                            placeholder=""
                            name="confirmpassword"
                            value={this.state.confirmpassword}
                            onChange={this.handleChange}
                          />
                          <div style={{ fontSize: 12, color: "red" }} className="py-1 m-0">
                            {this.state.errorconfirmpassword}
                          </div>
                        </div>

                      </div> </>}
                </>}
              </div>
              {this.props.errorMessageRegister ?
                <div class="alert alert-danger m-0 text-center col-md-5 mx-auto my-2 py-2" role="alert">
                  {this.props.errorMessageRegister}
                </div>
                : ''}

              {this.props.errorMessage ?
                <div class="alert alert-danger m-0 text-center col-md-5 mx-auto my-2 py-2" role="alert">
                  {this.props.errorMessage}
                </div>
                : ''}
            </div>
          </section>
          <div className="text-center py-3">
            <Link
              to="/ChooseCampaign"
              className="btn btnw bgcmuted fzcwhite  px-4 m-2 fz20 text-center"
            >
              <img
                src={nextarrow}
                className=""
                style={{ width: 25 }}
                alt="arrow"
              />
              السابق
          </Link>

            {this.props.Loading == "CREATECAMPAIGN_L" ?
              <button type="button" className="btn btnw bgcm fzcwhite  px-4  m-2  fz20 text-center" style={{ color: "#fff" }}>
                التالي
                <div class="spinner-grow text-light mx-2" role="status" style={{ height: 20, width: 20 }}>
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
              :
              <button type="submit" className="btn btnw bgcm fzcwhite  px-4  m-2  fz20 text-center" style={{ color: "#fff" }}>
                التالي
                <img src={prearrow} className="" style={{ width: 25 }} alt="arrow" />
              </button>
            }

          </div>
          <a data-toggle="modal" data-target="#exampleModal" id="modalaftercreatecampaign" style={{ display: "none" }}> </a>
          <div
            className="modal fade "
            id="exampleModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-body text-center px-4 py-3">
                  <p className=" fzcm fz24 fw500">
                    لقد تم إنشاء الحملة بنجاح
                  <img
                      src={Done}
                      alt="right"
                      className="px-2"
                      style={{ width: 35 }}
                    />
                  </p>
                  <p className="fz16 fzcmuted fw500">
                    ييرجى العلم بأن الحملة لن تعرض على الموقع حتى يصل إجمالي التبرعات الي 100ج
                </p>
                  <p className="fz14 fzcmuted">
                    اضغط هنا لقراءة
                  <Link to="/Terms" onClick={this.handelclosemodal} className="fzcm px-2 underline m-0">
                      الشروط والأحكام
                  </Link>
                  </p>
                </div>
                <div className="text-center pb-4">
                  {this.props.campaign_title &&
                    <Link
                      to={"/CampaignDonation/" + this.props.campaign_title.replace(/ /g, "_") + "/" + this.props.campaignid}
                      onClick={this.handelclosemodal}
                      className="btn btnw bgcm fzcwhite fw500 px-4 fz20 text-center"
                    >
                      التالي
                  <button id="closemodal" type="button" style={{ display: "none" }} data-dismiss="modal">
                        Close
                  </button>
                      <img src={prearrow} className="" style={{ width: 25 }} alt="arrow" />
                    </Link>}
                </div>
              </div>
            </div>
          </div>
        </form>

        <Footer />
      </div >
    );
  }
}

const mapStateToProps = state => {
  return {
    ProjectsDetails: state.Campaigns.ProjectsDetails ? state.Campaigns.ProjectsDetails[0] : "",
    errorMessageRegister: state.User ? state.User.errorMessageRegister : '',
    IsLoggedIn: state.User ? state.User.IsLoggedIn : '',
    user_name: state.User.user ? state.User.user.user_name : '',
    user_id: state.User.user ? state.User.user.user_id : '',
    campaignid: state.User ? state.User.campaignid : '',
    campaign_title: state.User ? state.User.campaign_title : false,

    user: state.User.user ? state.User.user : '',
    errorMessage: state.User ? state.User.errorMessage : '',
    Loading: state.Campaigns ? state.Campaigns.Loading : '',

  };
};
const mapDispatchToProps = {
  getProjectsId: getProjectsId,
  createCampaignItem: userAction.createCampaignItem,
  Register: userAction.Register,
  Login: userAction.Login,
  reseterroruser: userAction.reseterroruser,
  Loginandcreate: userAction.Loginandcreate,
  thousands_separators

};
export default connect(mapStateToProps, mapDispatchToProps)(CreateCampaign);
