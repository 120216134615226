import React from 'react';
import './CampaignDonation.scss';
import { Link } from "react-router-dom";
import close from '../../Images/close.svg'
import Done from '../../Images/Done.svg'
import Share from '../../Images/Share.svg'
import mobilesms from '../../Images/mobilesms.svg'
import { connect } from 'react-redux';

class ModalSuccess extends React.Component {
  render() {
    return (
      <div className="modal fade ModalSuccess" id="exampleModalScrollable" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
        <div className="modal-dialog  modal-dialog-scrollable" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="text-left">
                <img src={close} alt="close" className="mt-1 ml-0" data-dismiss="modal" style={{ width: 30 }} />
              </div>
              <div className="modal-body text-center  p-0">
                {this.props.resCreateDonation.donation_type == "Cash" ?
                  <p className=" fzcm fz20 fw500">

                    لقد وصلنا طلبكم
                                <img src={Done} alt="right" className="px-2" style={{ width: 55 }} />
                  </p> :
                  <p className=" fzcm fz20 fw500">

                    تمت عملية التبرع بنجاح
                              <img src={Done} alt="right" className="px-2" style={{ width: 55 }} />
                  </p>
                }
                <p className="fz14 fzcmuted fw500"> رقم عملية التبرع الخاص بك هو  {this.props.resCreateDonation.donation_id}</p>
                <p className="borderdashed mx-5"></p>
                {this.props.resCreateDonation.donation_type == "Cash" ?

                  <p className="fz14 fzcmuted fw500">
                    سوف يقوم احد ممثلي الجمعية بالاتصال بك خلال 72 ساعة لتحديد ميعاد لتحصيل قيمة التبرع
                  و يرجى العلم بأن عملية التبرع سوف تظهر في قائمة المتبرعين خلال 72 ساعه من تاريخ التحصيل
                               </p>
                  : ""}
                <a href={"https://www.facebook.com/sharer/sharer.php?u=" + window.location.href} target="_blank" className="btn stylelink fzcm bordercm fw500 px-4 fz16 m-2" role="button" aria-pressed="true">
                  <img src={Share} className=" m-1  " alt="Share" style={{ width: 20 }} />
                  شارك الحملة مع اصدقائك للتبرع للمتوفى
                </a>
                <p className="fz14 fzcmuted">
                  اضغط هنا لقراءة
                              <Link to="/Terms" className="fzcm px-2 underline m-0">
                    الشروط والأحكام
                              </Link>
                </p>
                <div className="bgcm my-4 b_r d-flex justify-content-center smssection pt-1" >
                  <p className="m-0 p-2  fz16 fzcwhite fw500 text-center ">
                    للتبرع عن طريق ال
                    sms
                    إرسل كود
                            <span className="fzcblack px-1">T{" "}{this.props.code}</span>
                    إلى الرقم
                            <span className="fzcblack px-1">{this.props.num}</span>
                    قيمه التبرع 5 ج.م

                            </p>
                  <img src={mobilesms} alt="caution" className="mx-1 smsimg" />

                </div>
              </div>
            </div>

          </div>
        </div>
      </div>


    );
  }
}
const mapStateToProps = state => {
  return {
    campaignDetails: state.Campaigns.campaignDetails ? state.Campaigns.campaignDetails[0] : '',

  };
}
const mapDispatchToProps = ({


})
export default connect(mapStateToProps, mapDispatchToProps)(ModalSuccess);
