import React from 'react';
import './MyCampaigns.scss';
import { Link } from "react-router-dom";
import { userAction } from '../../redux/actions/userAction'
import { connect } from 'react-redux';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Footer from '../Components/Footer/Footer'
import addicon from '../../Images/addicon.svg'
import arrowcm from '../../Images/arrowcm.svg'
import CardDonation from '../Components/CardDonation/CardDonation';



class MyCampaigns extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      rowscounts: 20,
      isfav: {},
      unfav: {}
    };

  }
  componentDidMount() {
    if (this.props.userCampaigns && this.props.userCampaigns.length == 0) {
      this.props.getUserCampaigns(this.state.offset, this.state.rowscounts)
    }
    this.setState({ isfav: {}, unfav: {} })

  }
  clickfav = (x) => {
    this.props.postUserfavourite(x.campaign_id)
    //  this.props.getAllCampaigns(this.state.offset,this.props.AllCampaigns.length,this.props.uid)
    this.setState({
      isfav: {
        ...this.state.isfav,
        [x.campaign_id]: true
      },
      unfav: {
        ...this.state.unfav,
        [x.campaign_id]: false
      }
    })
  }
  clickunfav = (x) => {
    this.props.deleteUserfavourite(x.campaign_id)
    this.setState({
      isfav: {
        ...this.state.isfav,
        [x.campaign_id]: false
      },
      unfav: {
        ...this.state.unfav,
        [x.campaign_id]: true
      }

    })
  }
  render() {
    document.title = "InMemoryOf | My Campaigns";
    document.getElementsByTagName("META")[1].content = "IN MEMORY OF was created to help family and friends commemorate their loved ones through memorial donations";
    document.documentElement.lang = 'en';
    const text = (this.props.userCampaigns ? this.props.userCampaigns.length > 0 ? '' : <p className="py-3  animationtext fw500 fz20 m-auto text-center">لا توجد اي حملات  </p> : '')
    return (
      <div className="Campaigns">
        <div className="navhe"></div>
        <div className="container">
          <div className="py-2">
            <Link to="" className="fz12 fzcm px-2 fw500">الرئيسية</Link>
            <span className="fz12 fw500 mt-2">&gt;	</span>
          </div>
          <p className="borderdashed m-0"></p>
          <div className="d-md-flex justify-content-between py-3">
            <h3 className="fzcblack black fz24 fw500  m-0 center-v">حملات قمت بإنشائها</h3>
            <div className=" d-flex justify-content-between">
              <Link to="/ChooseCampaign" className="btn px-4 col mx-0 my-3 bordercm fzcm fzcwhite fw500  fz16" >
                <img src={addicon} alt="arrow" className="mx-1" style={{ width: 20, height: 20 }} />
                انشئ حملة جديدة</Link>
            </div>
          </div>
        </div>
        <section className="allCampaigns py-3">
          <div className="container">
            {this.props.userCampaigns &&
              <CardDonation campaigns={this.props.userCampaigns} clickfav={this.clickfav} clickunfav={this.clickunfav} isfav={this.state.isfav} unfav={this.state.unfav} />
            }
            {text}
            {this.props.userCampaigns.length ? parseInt(this.props.total_campaigns) > this.props.userCampaigns.length && this.props.userCampaigns.length > 0 ?
              <p onClick={() => this.props.getUserCampaigns(this.props.userCampaigns.length, this.state.rowscounts)} className="btn bgcwhite pointer col mx-0 bordercm fzcm  fw500 px-4 my-3 fz18" >
                حمل المزيد
            <img src={arrowcm} alt="arrow" />
              </p> : '' : ''
            }
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    userCampaigns: state.User ? state.User.userCampaigns : false,
    total_campaigns: state.User.userCampaigns ? state.User.userCampaigns[0] ? state.User.userCampaigns[0].total_campaigns : '' : 0,
    uid: state.User.user ? state.User.user.id : " "

  };
}
const mapDispatchToProps = ({
  getUserCampaigns: userAction.getUserCampaigns,
  postUserfavourite: userAction.postUserfavourite,
  deleteUserfavourite: userAction.deleteUserfavourite,

})
export default connect(mapStateToProps, mapDispatchToProps)(MyCampaigns);