import React from "react";
import "./ContactUs.scss";
import { Link } from "react-router-dom";
import Footer from "../Components/Footer/Footer";
import { connect } from "react-redux";
import { postContactUs } from "../../redux/actions";
import Loading from '../../Images/loading.svg'
import email from '../../Images/email.svg'
import location from '../../Images/location.svg'
import phone from '../../Images/phone.svg'

class ContactUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phone: "",
      details: ""
    };
  }
  componentDidMount() { }
  handleChange = e => {
    this.setState({
      // ...this.state,
      [e.target.name]: e.target.value
    });
  };
  validate = () => {
    let errorname = "";
    let errorphone = "";
    let erroremail = "";
    let errordetails = "";
    if (this.state.name.length < 2) {
      errorname = "ادخل الاسم  صحيح";
    }
    // if (isNaN(this.state.phone) || this.state.phone.length !== 11) {
    //   errorphone = "رقم الهاتف غير صحيح يجب ان يبداء ب *********01";
    // }
    if (this.state.details.length < 8) {
      errordetails = "اكتب مزيد من التفاصيل ";
    }
    if (!this.state.email.includes("@")) {
      erroremail = "بريد الكتروني غير صحبح";
    }
    if (errorname || errorphone || errordetails || erroremail) {
      this.setState({ errorname, errorphone, errordetails, erroremail });
      return false;
    }
    return true;
  };
  handleSubmit = e => {
    e.preventDefault();

    // perform all neccassary validations
    const isValid = this.validate();
    if (isValid) {
      this.props.postContactUs(this.state);
      this.setState({
        errorname: "",
        errorphone: "",
        errordetails: "",
        erroremail: ""
      });
    }
  };
  render() {
    document.title = "InMemoryOf | Conatact US";
    document.getElementsByTagName("META")[1].content =
      "IN MEMORY OF was created to help family and friends commemorate their loved ones through memorial donations";
    document.documentElement.lang = "en";
    return (
      <div className="ContactUs">
        <div className="navhe"></div>
        <div className="container">
          <div className="py-2">
            <Link to="" className="fz12 fzcm px-2 fw500">
              الرئيسية
            </Link>
            <span className="fz12 fw500 mt-2">&gt; </span>
          </div>
          <p className="borderdashed m-0"></p>
          <h3 className="fzcblack black fz24 fw500 py-3 ">اتصل بنا</h3>
          <p className="fzcm fz16 fw500 m-0">
            ووكلاء خدمة العملاء دائماً هنا من أجلك. جميع أسئلتكم سوف يتم الرد عليها سواء كانت كبيرة أو صغيرة. نرجو منك البحث عن إجابة لسؤالك ضمن الأسئلة الشائعة أو


          </p>
          <p className="fz16 fw500">
            التواصل معنا عبر نموذج الاستفسارات أومن خلال الإيميل الخاص بنا.
          </p>
        </div>
        <section>
          {this.props.messageCountactUs === 1 ? <p className="fz22 text-center py-5"> تم الإرسال وسوف يتم التواصل معاكم او الرد علي استفساركم </p> :
            this.props.messageCountactUs === 2 ? <div className=" text-center py-5" >
              <img src={Loading} alt="" />
              <p className="fz16 text-center"> لحظات من فضلك</p>
            </div> :
              this.props.messageCountactUs === 3 ? <p className="fz16 text-center py-5" >لم يتم إرسال الرساله حاول مره اخره</p> :
                <form className="container" onSubmit={this.handleSubmit}>
                  <div className="b_r border row m-0 py-4 my-3">
                    <div className="form-group col-md-6 py-1 m-0  row">
                      <label
                        htmlFor="name"
                        className="col-sm-2 px-0 col-form-label d-flex justify-content-between"
                      >
                        الاسم <span className="require">*</span>
                      </label>
                      <div className="col-sm-9 col-md-8">
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          placeholder=""
                          name="name"
                          value={this.state.name}
                          onChange={this.handleChange}
                        />
                        <div
                          style={{ fontSize: 12, color: "red" }}
                          className="py-1 m-0"
                        >
                          {this.state.errorname}
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-md-6 py-1 m-0  row">
                      <label
                        htmlFor="phone"
                        className="col-sm-2 px-0 col-form-label d-flex justify-content-between"
                      >
                        الهاتف
                      </label>
                      <div className="col-sm-9 col-md-8">
                        <input
                          type="text"
                          className="form-control"
                          id="phone"
                          placeholder=""
                          name="phone"
                          value={this.state.phone}
                          onChange={this.handleChange}
                        />
                        <div
                          style={{ fontSize: 12, color: "red" }}
                          className="py-1 m-0"
                        >
                          {this.state.errorphone}
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-md-6 py-1 m-0  row">
                      <label
                        htmlFor="email"
                        className="col-sm-2 px-0 col-form-label d-flex justify-content-between"
                      >
                        البريد الإلكتروني <span className="require">*</span>
                      </label>
                      <div className="col-sm-9 col-md-8">
                        <input
                          type="text"
                          className="form-control"
                          id="email"
                          placeholder=""
                          name="email"
                          value={this.state.email}
                          onChange={this.handleChange}
                        />
                        <div
                          style={{ fontSize: 12, color: "red" }}
                          className="py-1 m-0"
                        >
                          {this.state.erroremail}
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 py-1 m-0  row">
                      <label
                        htmlFor="details"
                        className="col-sm-1 px-0 col-form-label d-flex justify-content-between"
                      >
                        الاستفسار <span className="require">*</span>
                      </label>
                      <div className="col-sm-10">
                        <textarea
                          className="form-control"
                          id="campaign_description"
                          rows="2"
                          name="details"
                          value={this.state.details}
                          onChange={this.handleChange}
                        ></textarea>
                        <div
                          style={{ fontSize: 12, color: "red" }}
                          className="py-1 m-0"
                        >
                          {this.state.errordetails}
                        </div>
                      </div>
                    </div>
                    <div className="row m-0 col-12">
                      <div className="col-1"></div>
                      <button
                        type="submit"
                        className="btn btnw bgcm fzcwhite  px-4  mt-2 mx-3 fz20 text-center"
                        style={{ color: "#fff" }}
                      >
                        إرسال
                  </button>
                      <p>{this.props.messageCountactUs}</p>
                    </div>
                  </div>
                </form>
          }
        </section>
        {/* {this.props.messageCountactUs ? (
          <HandleRes
            title={this.props.messageCountactUs}
            stylecss="alert-success"
          />
        ) : (
          ""
        )} */}

        <div className="map text-center">
          <iframe className="relative " src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3451.615818411539!2d31.347813615116483!3d30.105187281860232!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x145815c54bf13459%3A0x22b972aaf6cc969d!2sTA%20telecom!5e0!3m2!1sen!2seg!4v1581942146793!5m2!1sen!2seg" width="100%" height="400px" frameborder="0" allowfullscreen="">
          </iframe>
          <div className="popup bgcwhite btn shadow border  px-4  text-right">
            <p className="fz16 m-0">مصر</p>
            <p className="fz24 mb-1">القاهرة</p>
            <div className="fz16 mb-1 row m-0">
              <div className="w35 text-center">
                <img src={email} alt='email InMemoryOf' className=" mx-auto" />
              </div>
              <a href="https://www.google.com/maps/place/TA+telecom/@30.1051601,31.3479048,17z/data=!3m1!4b1!4m5!3m4!1s0x145815c54bf13459:0x22b972aaf6cc969d!8m2!3d30.1051555!4d31.3500935" className="col p-0">
                111ب فريد سميكة أمام بوابة 5 للكلية الحربية - هليوبوليس</a></div>
            <a target="_blank" href="mailto:info@inmemoryof.com" className="fz16 mb-1 row m-0">
              <div className="w35 text-center">
                <img src={location} alt='email InMemoryOf' className=" mx-1" />
              </div>
              info@inmemoryof.com</a>
            <a href="tel:201023448874" className="fz16 mb-1  row m-0">
              <div className="w35 text-center">
                <img src={phone} alt='email InMemoryOf' className=" mx-1" />
              </div>

              ‭+201023448874
            </a>

          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    messageCountactUs: state.NGOS.messageCountactUs
      ? state.NGOS.messageCountactUs
      : ''
  };
};
const mapDispatchToProps = {
  postContactUs: postContactUs
};
export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
