import React from 'react';
import './MyFavourite.scss';
import { Link } from "react-router-dom";
import { userAction } from '../../redux/actions/userAction'
import { connect } from 'react-redux';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Footer from '../Components/Footer/Footer'
import addicon from '../../Images/addicon.svg'
import arrowcm from '../../Images/arrowcm.svg'
import CardDonation from '../Components/CardDonation/CardDonation';



class MyFavourite extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      rowscounts: 20,
      isfav: {},
      unfav: {}
    };

  }
  componentDidMount() {
    // if(this.props.user_Favourite.length<1){
    //   this.props.getUserfavourite(this.state.offset,this.state.rowscounts)

    // }
    this.setState({ isfav: {}, unfav: {} })
    this.props.getUserfavourite2(this.state.offset, this.state.rowscounts)

  }
  clickfav = (x) => {
    this.props.postUserfavourite(x.campaign_id)
    this.props.getUserfavourite2(this.state.offset, this.props.user_Favourite.length)
    this.setState({
      isfav: {
        ...this.state.isfav,
        [x.campaign_id]: true
      },
      unfav: {
        ...this.state.unfav,
        [x.campaign_id]: false
      }
    })
  }
  clickunfav = (x) => {
    this.props.deleteUserfavourite(x.campaign_id)
    this.props.getUserfavourite2(this.state.offset, this.props.user_Favourite.length)

    this.setState({
      isfav: {
        ...this.state.isfav,
        [x.campaign_id]: false
      },
      unfav: {
        ...this.state.unfav,
        [x.campaign_id]: true
      }

    })
  }
  render() {
    document.title = "InMemoryOf | My Favourite";
    document.getElementsByTagName("META")[1].content = "IN MEMORY OF was created to help family and friends commemorate their loved ones through memorial donations";
    document.documentElement.lang = 'en';
    const text = (this.props.user_Favourite && this.props.user_Favourite.length > 0 ? '' : <p className="py-3  animationtext fw500 fz20 m-auto text-center">لا توجد اي حملات  </p>)
    return (
      <div className="MyFavourite">
        <div className="navhe"></div>
        <div className="container">
          <div className="py-2">
            <Link to="" className="fz12 fzcm px-2 fw500">الرئيسية</Link>
            <span className="fz12 fw500 mt-2">&gt;	</span>
          </div>
          <p className="borderdashed m-0"></p>
          <div className="d-md-flex justify-content-between py-3">
            <h3 className="fzcblack black fz24 fw500  m-0 center-v">حملات قمت بتفضيلها</h3>
            <div className=" d-flex justify-content-between">
              <Link to="/ChooseCampaign" className="btn px-4 col mx-0 my-3 bordercm fzcm fzcwhite fw500  fz16" >
                <img src={addicon} alt="arrow" className="mx-1" style={{ width: 20, height: 20 }} />
                أضف حملة جديدة</Link>
            </div>
          </div>
        </div>
        <section className="allCampaigns py-3">
          <div className="container">

            <CardDonation campaigns={this.props.user_Favourite} clickfav={this.clickfav} clickunfav={this.clickunfav} isfav={this.state.isfav} unfav={this.state.unfav} />
            {text}
            {this.props.user_Favourite && parseInt(this.props.total_favorites) > this.props.user_Favourite.length && this.props.user_Favourite.length > 0 ?
              <p onClick={() => this.props.getUserfavourite(this.props.user_Favourite.length, this.state.rowscounts)} className="btn bgcwhite pointer col mx-0 bordercm fzcm  fw500 px-4 my-3 fz18" >
                حمل المزيد
            <img src={arrowcm} alt="arrow" />
              </p> : ''
            }

          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user_Favourite: state.User ? state.User.userFavorites : [],
    total_favorites: state.User.userFavorites ? state.User.userFavorites[0] ? state.User.userFavorites[0].total_favorites : '' : 0,
  };
}
const mapDispatchToProps = ({
  getUserfavourite: userAction.getUserfavourite,
  getUserfavourite2: userAction.getUserfavourite2,
  postUserfavourite: userAction.postUserfavourite,
  deleteUserfavourite: userAction.deleteUserfavourite
})
export default connect(mapStateToProps, mapDispatchToProps)(MyFavourite);