import React from 'react';
import './Register.scss';
import { Link } from "react-router-dom";
// import {userAction} from '../../redux/actions'
import { connect } from 'react-redux';


import Footer from '../Components/Footer/Footer'

import facebookwhite from '../../Images/facebookwhite.svg'
import { userAction } from '../../redux/actions'


class Register extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      user_name: "",
      user_email: "",
      password: "",
      confirmpassword: "",

      erroruser_name: "",
      erroruser_email: "",
      errorpassword: "",
      errorconfirmpassword: "",
    };
  }
  componentDidMount() {
    this.props.reseterroruser()

  }
  handleChange = e => {
    this.setState({
      // ...this.state,
      [e.target.name]: e.target.value
    })
  };
  validate = () => {
    let erroruser_name = "";
    let errorpassword = "";
    let errorconfirmpassword = "";
    let erroruser_email = "";
    if (this.state.user_name.length < 2) {
      erroruser_name = "ادخل الاسم  صحيح";
    } else {
      if (!this.state.user_name.match(/^[ء-ي0-9 a-zA-Z]+$/)) {
        erroruser_name = "يجب ان لا تحتوي علي اي رموز  :!@#$%&";
      }
    }
    if (this.state.password.length < 8) {
      errorpassword = "يجب ان يكون كلمة السر اكبر من 8 حروف او ارقام";
    }
    if (!this.state.user_email.includes("@")) {
      erroruser_email = "بريد الكتروني غير صحبح";
    }
    if (this.state.password !== this.state.confirmpassword) {
      errorconfirmpassword = "كلمة السر غير مطابقه";
    }
    if (erroruser_name || erroruser_email || errorpassword || errorconfirmpassword) {
      this.setState({

        erroruser_name,
        erroruser_email,
        errorpassword,
        errorconfirmpassword

      });
      return false;
    }
    return true;
  };

  handleSubmit = e => {
    e.preventDefault();
    // perform all neccassary validations
    const isValid = this.validate();

    if (isValid) {
      // this.props.registerUser(this.state);
      this.props.Register(this.state.user_name, this.state.user_email, this.state.password)
      this.setState({
        erroruser_name: "",
        erroruser_email: "",
        errorpassword: "",
        errorconfirmpassword: ""
      })
    }
  };

  render() {
    return (
      <div className="Register">
        <div className="navhe"></div>
        <section className="formlogin py-4 my-5 col-lg-4 col-md-5 col-11 mx-auto px-lg-4">
          {this.props.user.verified === false ?
            <h3 className="fzcblack  fz22 fw500 text-center py-3 my-5 ">تم إرسال رساله الي بريدك الالكتروني قم بفتحها لتفعيل الحساب </h3>
            :
            <form className="px-lg-5 m-auto" onSubmit={this.handleSubmit}>
              <h3 className="fzcblack  fz26 fw500 text-center py-3 ">تسجيل حساب جديد</h3><p></p>
              {/* <Link to="/" className="btn d-block py-1 stylelink fzcwhite  fw500 fz18 bgcf d-block mt-4 mb-2" role="button" aria-pressed="true" >
                      استمر بالفيسبوك   
                        <img src={facebookwhite} className="figure-img img-fluid   m-1" alt="twitterwhite" style={{width:25,height:25,objectFit:"contain"}}/>
              </Link>

              <span className="fzcblack labelstyle fw500 fz18 px-1">أو</span>
                           */}
              <p className="borderdashed mb-4"></p>
              <div className="form-group  m-0 py-1 row">
                <input type="text" className="form-control" placeholder="الاسم" name="user_name" value={this.state.user_name} onChange={this.handleChange} />
                <div style={{ fontSize: 12, color: "red" }} className="py-1 m-0">
                  {this.state.erroruser_name}
                </div>
              </div>
              <div className="form-group  m-0 py-1 row">
                <input type="text" className="form-control" placeholder="البريد الإلكتروني" name="user_email" value={this.state.user_email} onChange={this.handleChange} />
                <div style={{ fontSize: 12, color: "red" }} className="py-1 m-0">
                  {this.state.erroruser_email}
                </div>
              </div>
              <div className="form-group  m-0 py-1 row">
                <input type="password" className="form-control" placeholder="كلمة السر" name="password" value={this.state.password} onChange={this.handleChange} />
                <div style={{ fontSize: 12, color: "red" }} className="py-1 m-0">
                  {this.state.errorpassword}
                </div>
              </div>

              <div className="form-group  m-0 py-1 row">
                <input type="password" className="form-control" placeholder="تأكيد كلمة السر" name="confirmpassword" value={this.state.confirmpassword} onChange={this.handleChange} />
                {!this.state.errorpassword && this.state.errorconfirmpassword ? <div style={{ fontSize: 12, color: "red" }} className="py-1 m-0">
                  {this.state.errorconfirmpassword}
                </div> : ''}
              </div>
              {this.props.errorMessageRegister ?
                <div class="alert alert-danger m-0  my-2 py-2" role="alert">
                  {this.props.errorMessageRegister}
                </div>
                : ''}
              <button type="submit" to="/" className="btn w-100 d-block py-2 stylelink fzcwhite  fw500 fz18 bgcm d-block my-3" role="button" aria-pressed="true" >
                تسجيل
                          </button>
            </form>
          }
        </section>





        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    errorMessageRegister: state.User.errorMessageRegister,
    user: state.User.user ? state.User.user : ''
  };
}
const mapDispatchToProps = ({
  Register: userAction.Register,
  reseterroruser: userAction.reseterroruser

})
export default connect(mapStateToProps, mapDispatchToProps)(Register);