import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import { Router, Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import Navbar from "./Pages/Components/Navbar/Navbar";
import Home from "./Pages/Home/Home";
import ChooseCampaign from "./Pages/ChooseCampaign/ChooseCampaign";
import CreateCampaign from "./Pages/CreateCampaign/CreateCampaign";
import CampaignDonation from "./Pages/CampaignDonation/CampaignDonation";
import ProjectsListing from "./Pages/ProjectsListing/ProjectsListing";
import NGOsListing from "./Pages/NGOsListing/NGOsListing";
import NGODetails from "./Pages/NGODetails/NGODetails";
import MyDonations from "./Pages/MyDonations/MyDonations";
import ContactUs from "./Pages/ContactUs/ContactUs";
import PressCoverage from "./Pages/PressCoverage/PressCoverage";
import Terms from "./Pages/Terms/Terms";
import FAQs from "./Pages/FAQs/FAQs";
import Campaigns from "./Pages/Campaigns/Campaigns";
import MyCampaigns from "./Pages/MyCampaigns/MyCampaigns";
import MyProfile from "./Pages/MyProfile/MyProfile";
import MyFavourite from "./Pages/MyFavourite/MyFavourite";
import PathNotFound from "./Pages/PathNotFound/PathNotFound";
import SuccessTips from "./Pages/SuccessTips/SuccessTips";
import HowItWork from "./Pages/HowItWork/HowItWork";
import TrustandSafety from "./Pages/TrustandSafety/TrustandSafety";
import Impact from "./Pages/Impact/Impact";
import EditProfile from "./Pages/EditProfile/EditProfile";
import Login from "./Pages/Login/Login";
import Register from "./Pages/Register/Register";
import HandleRes from "./Pages/Components/HandleRes/HandleRes";
import Verifymail from "./Pages/Verifymail/Verifymail";
import { userAction } from './redux/actions';
import { history } from "./redux/_helpers/history";
import { resetErorrs } from './redux/actions/index';
import ForgotPassword from "./Pages/ForgotPassword/ForgotPassword";
import ResetPassword from "./Pages/ResetPassword/ResetPassword";
import DeleteUser from "./Pages/DeleteUser/DeleteUser";
import TransactionDonation from "./Pages/TransactionDonation/TransactionDonation";
import TransactionFailed from "./Pages/TransactionFailed/TransactionFailed";

class App extends React.Component {
  constructor(props) {
    super(props);
    history.listen((location, action) => {
      // clear alert on location change
      window.scrollTo(0, 0)
      this.props.resetErorrs();
      this.props.reseterroruser()
    });
  }
  render() {
    return (
      <div className="App">
        <Router history={history}>
          <Route component={Navbar}></Route>
          <div id="contant" className="contant">
            <Switch>
              {this.props.IsLoggedIn ?
                <Switch>
                  <Route exact path="/" component={Home}></Route>
                  <Route exact path="/ChooseCampaign" component={ChooseCampaign} />
                  <Route exact path="/CreateCampaign/:name/:id" component={CreateCampaign} />
                  <Route exact path="/CampaignDonation/:name/:id" component={CampaignDonation} />
                  {/* <Route exact path="/:name" component={ReRoute} /> */}
                  <Route exact path="/Campaigns" component={Campaigns} />
                  <Route exact path="/ProjectsListing" component={ProjectsListing} />
                  <Route exact path="/NGOsListing" component={NGOsListing} />
                  <Route exact path="/NGODetails/:name/:id" component={NGODetails} />
                  <Route exact path="/MyDonations" component={MyDonations} />
                  <Route exact path="/MyCampaigns" component={MyCampaigns} />
                  <Route exact path="/MyFavourite" component={MyFavourite} />
                  <Route exact path="/MyProfile" component={MyProfile} />
                  <Route exact path="/MyProfile/Edit" component={EditProfile} />
                  <Route exact path="/ContactUs" component={ContactUs} />
                  <Route exact path="/TransactionDonation/:Link" component={TransactionDonation} />
                  <Route exact path="/TransactionFailed" component={TransactionFailed} />
                  <Route exact path="/PressCoverage" component={PressCoverage} />
                  <Route exact path="/Terms" component={Terms} />
                  <Route exact path="/FAQs" component={FAQs} />
                  <Route exact path="/SuccessTips" component={SuccessTips} />
                  <Route exact path="/HowItWork" component={HowItWork} />
                  <Route exact path="/TrustandSafety" component={TrustandSafety} />
                  <Route exact path="/Impact" component={Impact} />
                  <Route exact path="/Verifymail/:token" component={Verifymail} />
                  <Route exact component={PathNotFound} />
                </Switch>
                :
                <Switch>
                  <Route exact path="/" component={Home}></Route>
                  <Route exact path="/ChooseCampaign" component={ChooseCampaign} />
                  <Route exact path="/CreateCampaign/:name/:id" component={CreateCampaign} />
                  {/* <Route exact path="/:name" component={ReRoute} /> */}
                  <Route exact path="/CampaignDonation/:name/:id" component={CampaignDonation} />
                  <Route exact path="/Campaigns" component={Campaigns} />
                  <Route exact path="/ProjectsListing" component={ProjectsListing} />
                  <Route exact path="/NGOsListing" component={NGOsListing} />
                  <Route exact path="/NGODetails/:name/:id" component={NGODetails} />
                  <Route exact path="/TransactionDonation/:Link" component={TransactionDonation} />
                  <Route exact path="/TransactionFailed" component={TransactionFailed} />
                  <Route exact path="/ContactUs" component={ContactUs} />
                  <Route exact path="/PressCoverage" component={PressCoverage} />
                  <Route exact path="/Terms" component={Terms} />
                  <Route exact path="/FAQs" component={FAQs} />
                  <Route exact path="/SuccessTips" component={SuccessTips} />
                  <Route exact path="/HowItWork" component={HowItWork} />
                  <Route exact path="/TrustandSafety" component={TrustandSafety} />
                  <Route exact path="/Impact" component={Impact} />
                  <Route exact path="/Verifymail/:token" component={Verifymail} />
                  <Route exact path="/ForgotPassword" component={ForgotPassword} />
                  <Route exact path="/ResetPassword/:token" component={ResetPassword} />
                  <Route exact path="/Login" component={Login} />
                  <Route exact path="/Register" component={Register} />
                  <Route exact path="/DeleteUser" component={DeleteUser} />
                  <Route exact component={PathNotFound} />
                </Switch>
              }
            </Switch>
          </div>
          {(this.props.successMessageAR ? <HandleRes title={this.props.successMessageAR} stylecss="alert-success" /> : '')}
          {(this.props.errorMessageBackEnd ? <HandleRes title={this.props.errorMessageBackEnd} stylecss="alert-danger" /> : '')}
          {(this.props.errorGlobal ? <HandleRes title="sorry there was a problem with your request" stylecss="alert-danger" /> : '')}
        </Router>
      </div >
    );
  }
}

const mapStateToProps = state => {
  return {
    errorMessageBackEnd: state.Errors.errorMessageBackEnd ? state.Errors.errorMessageBackEnd : "",
    errorGlobal: state.Errors.errorGlobal ? state.Errors.errorGlobal : "",
    successMessageAR: state.User.successMessageAR ? state.User.successMessageAR : "",
    IsLoggedIn: state.User ? state.User.IsLoggedIn : '',
  };
};
const mapDispatchToProps = {
  resetErorrs: resetErorrs,
  reseterroruser: userAction.reseterroruser
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
