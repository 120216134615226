import React from 'react';
import './MyProfile.scss';
import { Link } from "react-router-dom";
import { userAction } from '../../redux/actions/userAction'
import { connect } from 'react-redux';
import CardDonation from '../Components/CardDonation/CardDonation';
import CardMyDonation from '../Components/CardMyDonation/CardMyDonation';
import Footer from '../Components/Footer/Footer'
import arrowright from '../../Images/arrowright.svg'
import Visibility from '../../Images/Visibility.svg'
import { LINKIMAGES } from "../../redux/actions/types";
import { thousands_separators } from '../../redux/actions'

class MyProfile extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isfav: {},
      unfav: {}
    };
  }
  componentDidMount() {
    this.props.userInfo()

    this.props.UserFavourite4(0, 4)
    this.props.getUserCampaigns4(0, 4)
    this.props.UserDonations4(0, 4)
    this.setState({ isfav: {}, unfav: {} })

  }
  clickfav = (x) => {
    this.props.postUserfavourite(x.campaign_id)
    let campaign_id = x.campaign_id
    this.setState({
      isfav: {
        ...this.state.isfav,
        [x.campaign_id]: true
      },
      unfav: {
        ...this.state.unfav,
        [x.campaign_id]: false
      }
    })
  }
  clickunfav = (x) => {
    this.props.deleteUserfavourite(x.campaign_id)
    this.setState({
      isfav: {
        ...this.state.isfav,
        [x.campaign_id]: false
      },
      unfav: {
        ...this.state.unfav,
        [x.campaign_id]: true
      }

    })
  }
  render() {
    document.title = "InMemoryOf | My Profile";
    document.getElementsByTagName("META")[1].content = "IN MEMORY OF was created to help family and friends commemorate their loved ones through memorial donations";
    document.documentElement.lang = 'en';
    return (
      <div className="MyProfile">
        <div className="navhe"></div>
        <div className="container">
          <div className="py-2">
            <Link to="" className="fz12 fzcm px-2 fw500">الرئيسية</Link>
            <span className="fz12 fw500 mt-2">&gt;	</span>

          </div>
          <p className="borderdashed m-0"></p>
          <h3 className="fzcblack black fz24 fw500 py-4 ">حسابى</h3>
        </div>
        <div className="userinfo">
          <div className="container py-2">

            <div className="personalInfo row m-0 mq-sm-center">
              <div className="col-lg-2 col-sm-4 text-center">
                <figure className="figure ">
                  <img src={LINKIMAGES + this.props.profile_pic} className="figure-img img-fluid rounded-circle " alt="profile image" style={{
                    width: "120px", height: 120, objectFit: "contain",
                    boxShadow: "0px 0px 25px #E1E1E1"
                  }} />

                </figure>
              </div>
              <div className="col-md-5">
                <h2 className="fz28 fw500 fzcmuted">{this.props.user_name}</h2>
                <span className="fzcm fz20  fw500"> {this.props.thousands_separators(this.props.total_campaigns)} </span>&nbsp;
                      <span className="fzcmuted fz14  fw500"> حملات قمت انشائها </span>&nbsp;	&nbsp;	|&nbsp;	&nbsp;
                      <span className="fzcm fz20 fw500"> {this.props.thousands_separators(this.props.total_donations)} </span>&nbsp;
                      <span className="fzcmuted fz14 fw500"> تبرعات </span>
                <div className="fz24 fzcmuted">
                  <img src={Visibility} alt="Visibility" className={"px-3  " + (!this.props.hide_data ? " " : " Cmuted")} style={{ height: 25 }} />
                  {/*  | <img src={facebooklogo} alt="facebooklogo" className="Cface px-3" alt="facebooklogo" style={{ height: 20 }} /> */}
                  {/* |
                        <img src={twitterlogo} alt="twitterlogo" className=" px-3" alt="twitterlogo" style={{height:20 }}/> */}

                </div>

              </div>
              <div className="col-lg-4">
                <Link to="/MyProfile/Edit" className="btn stylelink fzcm bordercm fw500 px-5 fz20 mb-4 mt-4" role="button" aria-pressed="true">اعدادات الحساب</Link>

              </div>
            </div>

          </div>


          <div className="MyDonation">
            <div className="mycapaigns py-3">
              <div className="container ">
                <h3 className="fzcblack fz22 fw500 py-2 ">حملات قمت بتفضيلها</h3>
                <CardDonation campaigns={this.props.user_4Favourite} fav={true} clickfav={this.clickfav} clickunfav={this.clickunfav} isfav={this.state.isfav} unfav={this.state.unfav} />
                <Link to="/MyFavourite" className="stylelink fzcm fz18 text-center fw500 d-block py-2 ">
                  عرض كل الحملات
                <img src={arrowright} className="charityimg" alt="image" />
                </Link>
              </div>
            </div>
            <div className="container" >
              <h3 className=" fz22 fw500 py-2 ">تبرعاتي </h3>
              <CardMyDonation thousands_separators={this.props.thousands_separators} campaigns={this.props.user_4Donations} create={true} />
              <Link to="/MyDonations" className="stylelink fzcm fz18 text-center fw500 d-block py-2 ">
                عرض كل التبرعات
                  <img src={arrowright} className="charityimg" alt="image" />
              </Link>
            </div>
          </div>
          <div className="mycapaigns py-3">
            <div className="container ">
              <h3 className="fzcblack fz22 fw500 py-2 ">حملات قمت بإنشائها</h3>
              <CardDonation campaigns={this.props.user_4Campaigns} create={true} clickfav={this.clickfav} clickunfav={this.clickunfav} isfav={this.state.isfav} unfav={this.state.unfav} />
              <Link to="/MyCampaigns" className="stylelink fzcm fz18 text-center fw500 d-block py-2 ">
                عرض كل الحملات
                <img src={arrowright} className="charityimg" alt="image" />
              </Link>
            </div>
          </div>

        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    hide_data: state.User.user ? state.User.user.hide_data : '',
    user_name: state.User.user ? state.User.user.user_name : '',
    total_campaigns: state.User.user ? state.User.user.total_campaigns : '',
    total_donations: state.User.user ? state.User.user.total_donations : '',
    profile_pic: state.User.user ? state.User.user.profile_pic : '',
    user_id: state.User.user ? state.User.user.user_id : '',
    user_4Campaigns: state.User.userCampaigns4,
    user_4Donations: state.User.userDonations4,
    user_4Favourite: state.User.userFavorites4,
    uid: state.User.user ? state.User.user.user_id : " "

  };
}
const mapDispatchToProps = ({
  userInfo: userAction.userInfo,
  getUserCampaigns4: userAction.getUserCampaigns4,
  UserDonations4: userAction.UserDonations4,
  UserFavourite4: userAction.UserFavourite4,
  postUserfavourite: userAction.postUserfavourite,
  deleteUserfavourite: userAction.deleteUserfavourite,
  thousands_separators
})
export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);