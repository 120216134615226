import React from 'react';
import './TransactionDonation.scss';
import { Link } from "react-router-dom";
import { handleTransaction } from '../../redux/actions'
import { connect } from 'react-redux';



import loading from "../../Images/loading.svg";
import success from "../../Images/success.svg";



class TransactionDonation extends React.Component {

  constructor(props) {
    super(props);
    this.state = {

    };
  }
  componentDidMount() {

    this.props.handleTransaction(this.props.location.search)
  }
  render() {
    return (
      <div className="TransactionDonation text-center pt-5">
        <img src={loading} alt="loading" className="pt-5" />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {

  };
}
const mapDispatchToProps = ({
  handleTransaction: handleTransaction
})
export default connect(mapStateToProps, mapDispatchToProps)(TransactionDonation);