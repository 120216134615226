import React from 'react';
import './CardDonation.scss';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { userAction } from '../../../redux/actions'
import charity1 from '../../../Images/charity1.png'
import star from '../../../Images/star.svg'
import star2 from '../../../Images/star2.svg'
import heart from '../../../Images/heart.svg'
import addicon from '../../../Images/addicon.svg'
import close from '../../../Images/close.svg'
import Defult from '../../../Images/Defult.png'
import { LINKIMAGES } from "../../../redux/actions/types";
import { Animated } from "react-animated-css";
import { thousands_separators } from '../../../redux/actions'

class CardDonation extends React.Component {
  componentDidMount() {
  }
  handleclosemodle = () => {
    document.getElementById("closemodal").click();

  }
  handlestar(x) {
    //   let  fav = this.props.user_Favourite.filter(
    //     (contact)=>{
    //         return contact.campaign_id.toString().indexOf(x.campaign_id)!==-1;
    //     }
    // )
    // console.log(x.isFavorite,"favfavfavfav")
    if (this.props.IsLoggedIn) {
      if (x.isFavorite && !this.props.unfav[x.campaign_id] || this.props.isfav[x.campaign_id]) {
        return <div className="col-5  m-0 p-0 text-center d-flex align-items-center">
          <img src={star} style={{ width: 25 }} className="p-0" alt="image" onClick={() => this.props.clickunfav(x)} />
        </div>
      } else {
        return <div className="col-5  m-0 p-0 text-center d-flex align-items-center">
          <img src={star2} style={{ width: 25 }} className="p-0" alt="image" onClick={() => this.props.clickfav(x)} />
        </div>
      }
    } else {
      return <div className="col-5  m-0 p-0 text-center d-flex align-items-center" >
        <img src={star2} style={{ width: 25 }} className="p-0" alt="image" data-toggle="modal" data-target="#exampleModalScrollable" />
      </div>
    }

  }
  render() {
    return (
      <div className="CardDonation ">
        <div className="card-deck ">

          {this.props.campaigns ? this.props.campaigns.length > 0 ? this.props.campaigns.map((x, i) => {

            return (

              <div className="col-1-5 my-2 " key={i}>
                <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true} animationInDelay={this.props.campaigns.length < 20 ? i + "00" : ((i + 20) - this.props.campaigns.length) + "00"}>

                  <div className="card m-auto" key={i}>
                    <div className="cardimgLayout relative">
                      <div className="imgLayout m-0 row">
                        <div className="col-7"></div>
                        <div className="col-5 p-0">
                          <div className="favorite m-1">
                            <div className="row  m-1">
                              <div className="col-7  m-0 p-0 ">
                                <p className="fz10 text-center  fzcblack m-0 ">اضف الي المفضلة</p>
                              </div>
                              {this.handlestar(x)}
                              {/* <div className="col-5  m-0 p-0 text-center d-flex align-items-center">
                                    <img src={star2} style={{ width: 25 }} className="p-0" alt="image" onClick={()=>this.props.postUserfavourite(x.campaign_id)} />
                                  </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="dead"></div> */}
                      <img src={x.campaign_image == "campaigns/default.png" ? Defult : LINKIMAGES + x.campaign_image} className="card-img-top" alt={x.campaign_title} />
                    </div>
                    <Link to={"/CampaignDonation/" + x.campaign_title.replace(/ /g, "_") + "/" + x.campaign_id} className="card-body px-2 py-1 stylelink">
                      <div className="row text-center m-0">
                        <div className="col px-0 pt-1 ">
                          <p className="fz12 text-center fw500 fzcm fh40 m-0">{x.project_ar_name}</p>
                        </div>
                        <div className="col-4 px-1 text-center ">
                          <img src={LINKIMAGES + x.ngo_image} className="charityimg" alt="ngo_image " />
                        </div>
                      </div>
                      <p className="fzcblack fz18 text-center fw500 fh35 py-1 m-0 text-truncate ">{x.campaign_title}</p>
                      <div className="row m-0 pt-1">
                        <div className="col-9 px-0 py-2">
                          <p className="fzcm fz16  fw500 m-0 py-1">{this.props.thousands_separators(x.campaign_collected)}<span className="fz12 fw300 fzcblack"> من <span className="fw600"> {this.props.thousands_separators(x.project_amount)} </span> ج.م </span></p>
                          <div className="progress">
                            <div className="progress-bar bgcm" role="progressbar" style={{ width: x.campaign_collected / x.project_amount * 100 + "%" }} aria-valuenow={75} aria-valuemin={0} aria-valuemax={100}></div>
                          </div>
                          <p className="fz10 fw300 fzcmuted m-0">

                            عدد المتبرعين
                          {" "}{this.props.thousands_separators(x.total_of_donations)}{" "}
                          </p>
                        </div>
                        <div className="col-3 p-1 d-flex">
                          <div className="hrv"></div>
                          <div className="">
                            <p className="fzcm fz16 text-center fw500 m-0">{this.props.thousands_separators(x.units_completed)}</p>
                            <p className="fzcmuted fz8 text-center m-0 lineh9">وحدة اكتمل تمويلها الى الآن</p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </Animated>

              </div>
            )
          }) : "" : ''}





          {this.props.create ?

            <Link to="/ChooseCampaign" className="stylelink col-1-5">
              <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true} animationInDelay="1000">

                {
                  window.screen.width > 577 ?
                    <div className="card bordercm mx-auto" >
                      <div className="card-body p-2 text-center d-flex align-items-center">
                        <div>
                          <img src={addicon} alt="addicon" style={{ width: 40 }} className="" />
                          <p className="fzcm fz22 px-3 fw500">انشئ حملة جديدة</p>
                        </div>
                      </div>
                    </div>
                    :
                    <div className="btn px-4 col mx-0 my-3 bordercm fzcm bgcwhite fzcwhite fw500  fz16" >
                      <img src={addicon} alt="arrow" className="mx-1" style={{ width: 20, height: 20 }} />
                      انشئ حملة جديدة</div>
                }
              </Animated>
            </Link>
            : ''
          }
          {this.props.fav ?
            <Link to="/Campaigns" className="stylelink col-1-5">

              <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true} animationInDelay="1000">

                {
                  window.screen.width > 577 ?
                    <div className="card bordercm mx-auto" >
                      <div className="card-body p-2 text-center d-flex align-items-center">
                        <div>
                          <img src={addicon} alt="addicon" style={{ width: 40 }} className="" />
                          <p className="fzcm fz22 px-3 fw500">مزيد من الحملات</p>
                        </div>
                      </div>
                    </div>
                    :
                    <div className="btn px-4 col mx-0 my-3 bordercm bgcwhite fzcm fzcwhite fw500  fz16" >
                      <img src={addicon} alt="arrow" className="mx-1" style={{ width: 20, height: 20 }} />
                      انشئ حملة جديدة</div>
                }

              </Animated>

            </Link>
            : ''
          }
          <div className="modal fade ModalSuccess" id="exampleModalScrollable" tabIndex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
            <div className="modal-dialog  modal-dialog-scrollable" role="document">
              <div className="modal-content">

                <div className="modal-body">
                  <div className="text-left">
                    <img src={close} alt="close" id="closemodal" className="mt-1 ml-0" data-dismiss="modal" style={{ width: 30 }} />
                  </div>

                  <div className="modal-body text-center  p-0">

                    <p className=" fzcm fz18 fw500">
                      يجب اولا {" "}
                      <Link to="/Register" onClick={this.handleclosemodle} >تسجيل حساب </Link> {" "}
                      {" "}   او {" "}
                      <Link to="/Login" onClick={this.handleclosemodle}>تسجيل دخول </Link>  {" "}

                    </p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div >
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.User.user,
    user_Favourite: state.User ? state.User.userFavorites : [],
    IsLoggedIn: state.User ? state.User.IsLoggedIn : '',
  };
}
const mapDispatchToProps = ({

  postUserfavourite: userAction.postUserfavourite,
  deleteUserfavourite: userAction.deleteUserfavourite,
  thousands_separators
})
export default connect(mapStateToProps, mapDispatchToProps)(CardDonation);