import React from 'react';
import './Home.scss';
import CardDonation from '../Components/CardDonation/CardDonation';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { get5Campaigns, getcategories, getngos, getfilter, getachievements, thousands_separators } from '../../redux/actions'
import { userAction } from '../../redux/actions/userAction'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Loadable from '@loadable/component';
import arrowright from '../../Images/arrowright.svg'
import mdarrowleft from '../../Images/mdarrowleft.svg'
import mdarrowright from '../../Images/mdarrowright.svg'
import arrowrightslider from '../../Images/arrowrightslider.svg'
import arrowleftslider from '../../Images/arrowleftslider.svg'
import arrowleftwhite from '../../Images/arrowleftwhite.svg'
import arrowleftblack from '../../Images/arrowleftblack.svg'
import minutes from '../../Images/30minutes.svg'
import Component from '../../Images/Component.svg'
import handcardcoin from '../../Images/hand-card-coin.svg'
import mobile from '../../Images/mobile.svg'
import progressbar from '../../Images/progress-bar.svg'
import enterprise from '../../Images/enterprise.svg'
import heartbox from '../../Images/heart-box.svg'
import money from '../../Images/money.svg'
import $ from 'jquery'
import Footer from '../Components/Footer/Footer'
import { LINKIMAGES } from "../../redux/actions/types";
import { Animated } from "react-animated-css";

const OwlCarousel = Loadable(() => import('react-owl-carousel'));

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isfav: {},
      unfav: {},
      responsive: {
        "0": {
          items: 2

        },
        "480": {
          items: 4

        },
        "768": {
          items: 5

        },
        "900": {
          items: 6

        },
        "1200": {
          items: 8

        }
      },
      responsive2: {
        "0": {
          items: 1

        },
        "480": {
          items: 2

        },
        "768": {
          items: 3

        },
        "900": {
          items: 4

        },
        "1200": {
          items: 5

        }
      }
    };
    this.handleChangeselect = this.handleChangeselect.bind(this)
  }

  componentDidMount() {
    this.props.getcategories()
    this.setState({ isfav: {}, unfav: {} })
    this.props.get5Campaigns(this.props.uid)
    this.props.getfilter("campaigns")
    this.props.getachievements()
    if (this.props.allNGOS.length == 0) {
      this.props.getngos(0, "")
    }
    //countUP
    let a = 0;
    $(window).scroll(function () {
      let counteroffset = $('#counter').offset();
      if (counteroffset) {
        let oTop = $('#counter').offset().top - window.innerHeight;
        if (a == 0 && $(window).scrollTop() > oTop) {
          $('.counter-value').each(function () {
            let $this = $(this),
              countTo = $this.attr('data-count');
            $({
              countNum: $this.text()
            }).animate({
              countNum: countTo
            },
              {
                duration: 1500,
                easing: 'swing',
                step: function () {

                  $this.text(Math.floor(this.countNum));
                },
                complete: function () {
                  let num = this.countNum.toString().split(".")
                  num[0] = num[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                  num.join(".");
                  $this.text(num);
                  //alert('finished');
                }

              });
          });
          a = 1;
        }
      }


    });
  }




  handleChangeselect(e) {
    this.props.get5Campaigns(this.props.uid, e.target.value)
  }
  clickfav = (x) => {
    this.props.postUserfavourite(x.campaign_id)
    this.setState({
      isfav: {
        ...this.state.isfav,
        [x.campaign_id]: true
      },
      unfav: {
        ...this.state.unfav,
        [x.campaign_id]: false
      }
    })
  }
  clickunfav = (x) => {
    this.props.deleteUserfavourite(x.campaign_id)
    this.setState({
      isfav: {
        ...this.state.isfav,
        [x.campaign_id]: false
      },
      unfav: {
        ...this.state.unfav,
        [x.campaign_id]: true
      }
    })
  }

  render() {
    document.title = "In Memory Of";
    document.getElementsByTagName("META")[1].content = "IN MEMORY OF was created to help family and friends commemorate their loved ones through memorial donations";
    document.documentElement.lang = 'en';
    const types = []

    if (this.props.Categories.length > 0) {

      for (var i = 0; i < this.props.Categories.length; i += 1) {
        types.push(
          <div className="item " kay={this.props.Categories[i].category_id} >
            <Link to="/ChooseCampaign" className=" py-3 text-center stylelink ">
              <div className="text-center">
                <img src={LINKIMAGES + this.props.Categories[i].category_image} style={{ width: 40, height: 40, objectFit: "contain" }} alt="logotype" className="m-auto" />
              </div>
              <p className="py-2 fw-500  fzcwhite text-center">{this.props.Categories[i].ar_name}</p>
            </Link>
            {(i += 1) !== (this.props.Categories.length) ?
              <Link to="/ChooseCampaign" className=" py-3 text-center stylelink ">
                <div className="text-center">
                  <img src={LINKIMAGES + this.props.Categories[i].category_image} style={{ width: 40, height: 40, objectFit: "contain" }} alt="logotype" className="m-auto" />
                </div>
                <p className="py-2 fw-500  fzcwhite text-center">{this.props.Categories[i].ar_name}</p>
              </Link> : <p></p>}
          </div>);
      }
    }

    return (
      <div className="Home ">
        <header className="shadow d-flex align-items-center justify-content-center">
          <div className=" text-center titlecover">
            <h2 className="fzcbrouwn fz37 fw500 py-3 ">احتفل بحياة أحبائك</h2>
            <Animated animationIn="fadeInDown" animationOut="fadeOut" isVisible={true} animationInDelay={1000}>
              <Link to="/ChooseCampaign" className="btn pt-1 bg-black bgcbrouwn fzcwhite fw500 px-4 fz20" >ابدأ حملة تبرعات</Link>
            </Animated>
          </div>
        </header>
        <section className="donations  ">
          <div className="container pt-2 ">
            <select className="fzcblack fz20 fw500 py-2 d-block mx-auto px-md-5 text-center selecthome" onChange={this.handleChangeselect} >
              {
                this.props.filters ? this.props.filters.map((x, i) => {
                  return (
                    <option className="fz14" key={i} value={x.value}>{x.filter_type}</option>

                  )
                }) : ''
              }
            </select>
            <CardDonation campaigns={this.props.data5Campaigns} clickfav={this.clickfav} clickunfav={this.clickunfav} isfav={this.state.isfav} unfav={this.state.unfav} />
            <Link to="/Campaigns" className="stylelink fzcm fz18 text-center fw500 d-block pt-2 pb-3">
              عرض كل الحملات
                <img src={arrowright} className="charityimg" alt="image" />
            </Link>
          </div>
        </section>

        <section className="ChooseCampaign bgcm" >
          <h3 className="fzcwhite black fz22 fw500 py-1 pt-4 m-0 text-center">ابدأ حملة تبرعات </h3>
          <p className="fzcwhite black fz12  py-1 m-0  text-center">فئات المشروعات المتاحة </p>
          <div className="container mt-4 py-2">

            {this.props.Categories.length > 0 ? <OwlCarousel
              className="owl-carousel owl-theme "
              items={8}
              margin={0}
              center={true}
              startPosition={this.props.Categories.length < 4 ? 0 : this.props.Categories.length < 8 ? 1 : this.props.Categories.length < 10 ? 2 : this.props.Categories.length < 12 ? 3 : 4}
              nav={true}
              responsive={this.state.responsive}
              navText={[` <img src=${arrowleftslider} alt="logotype" className="arrowslider" />`,
              `<img src=${arrowrightslider}  alt="logotype" className="arrowslider" />`
              ]}
            >
              {this.props.Categories.length > 0 ? types : ''}

            </OwlCarousel> : ''}
            <Link to="/ProjectsListing" className="stylelink fzcwhite fz18 text-center fw500 d-block pb-3">
              عرض كل المشروعات
                <img src={arrowleftwhite} className="charityimg" alt="image" />
            </Link>
          </div>

        </section>

        <section className="whyuseinmemoryof py-3 my-2">

          <h3 className="fzcblack black fz22 fw500 py-4 text-center">لماذا تستخدم inMemoryof</h3>
          <div className="resons container text-center">
            <div className="d-md-flex justify-content-around">
              <div className="col-md">
                <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true} animationInDelay={500}>

                  <div className="text-center">
                    <img src={Component} style={{ width: 70, height: 70 }} alt="logotype" className="m-auto" />
                  </div>
                  <p className="pt-1 fw500 fz30 m-0 text-center">دقيقة</p>
                  <p className="py-0 fw500  fz18 m-0 text-center">واحده لتنشئ حملة</p>
                </Animated>
              </div>
              <div className="hrv2"></div>
              <div className="col-md">
                <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true} animationInDelay={1000}>
                  <div className="text-center">
                    <img src={progressbar} style={{ width: 70, height: 70 }} alt="logotype" className="m-auto" />
                  </div>

                  <p className="pt-1 fw500 fz30 m-0 text-center">مقياس</p>
                  <p className="py-0 fw500  fz18 m-0 text-center">لمراقبة اداء الحملة</p>
                </Animated>

              </div>
              <div className="hrv2"></div>

              <div className="col-md">
                <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true} animationInDelay={1500}>
                  <div className="text-center">
                    <img src={mobile} style={{ width: 70, height: 70 }} alt="logotype" className="m-auto" />
                  </div>
                  <p className="pt-1 fw500 fz30 m-0 text-center">وسائل </p>
                  <p className="py-0 fw500  fz18 m-0 text-center">متعددة للدفع</p>
                </Animated>
              </div>
              <div className="hrv2"></div>

              <div className="col-md">
                <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true} animationInDelay={2000}>
                  <div className="text-center">
                    <img src={minutes} style={{ width: 70, height: 70 }} alt="logotype" className="m-auto" />
                  </div>
                  <p className="pt-1 fw500 fz30 m-0 text-center">التبرع</p>
                  <p className="py-0 fw500  fz18 m-0 text-center"> في 30 ثانية</p>
                </Animated>

              </div>

              <div className="hrv2"></div>
              <div className="col">
                <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true} animationInDelay={2500}>

                  <div className="text-center">
                    <img src={handcardcoin} style={{ width: 70, height: 70 }} alt="logotype" className="m-auto" />
                  </div>
                  <p className="pt-1 fw500 fz30 m-0 text-center">فى الحال</p>
                  <p className="py-0 fw500  fz18 m-0 text-center"> يصل التبرع للجمعية</p>
                </Animated>

              </div>
            </div>
            <Link to="/HowItWork" className="btn pt-1 stylelink border-balck fzcblack fw500 px-4 fz20 mb-4 mt-4" role="button" aria-pressed="true">اعرف كيف نعمل</Link>

          </div>


        </section>

        <section className="statistict  bgcblack">
          <div className="container py-4">
            <div className="  row py-2 m-0" id="counter">
              <div className="col-md-4 col-6 my-2">

                <div>
                  <div className="text-center">
                    <img src={money} style={{ width: 60 }} alt="logotype" className="m-auto" />
                  </div>
                  <p className=" counter-value pt-1 fw500 fzcwhite fz30 m-0 text-center" data-count={this.props.Achievements.total_donations}> 0</p>
                  <p className="py-0 fw500 fzcwhite fz18 m-0 text-center"> تم تمويلهم</p>
                </div>
              </div>
              <div className="col-md-4 col-6 my-2">
                <div>
                  <div className="text-center">
                    <img src={heartbox} style={{ width: 60 }} alt="logotype" className="m-auto" />
                  </div>
                  <p className="counter-value pt-1 fw500 fzcwhite fz30 m-0 text-center" data-count={this.props.Achievements.total_campaigns}>0</p>
                  <p className="py-0 fw500 fzcwhite  fz18 m-0 text-center">حملة تبرع</p>
                </div>
              </div>
              <div className="col-md-4 col-6  my-2">
                <div>
                  <div className="text-center">
                    <img src={enterprise} style={{ width: 60 }} alt="logotype" className="m-auto" />
                  </div>
                  <p className=" counter-value pt-1 fw500 fzcwhite fz30 m-0 text-center" data-count={this.props.Achievements.total_donators}>0</p>
                  <p className="py-0 fw500  fzcwhite fz18 m-0 text-center">متبرع</p>
                </div>
              </div>
            </div>
            <Link to="/Impact" className="stylelink fzcwhite fz18 text-center fw500 d-block pt-3">
              اطلع على المزيد من الإنجازات
                <img src={arrowleftwhite} className="charityimg" alt="image" />
            </Link>
          </div>

        </section>
        <section className="cooperationWith">
          <div className="container">
            <h3 className="fzcblack fz22 fw500 py-2 pt-4 text-center">بالتعاون مع</h3>
            {this.props.allNGOS.length > 0 ?
              <OwlCarousel
                className="owl-theme "
                loop={true}
                items={5}
                center
                dots={false}
                autoplay={true}
                responsive={this.state.responsive2}
                margin={0}
                autoplayTimeout={1000}
                nav={true}
                // responsiveclassName={true}
                // responsive={}
                navText={[` <img src=${mdarrowleft} alt="logotype" className="arrowslider" />`,
                `<img src=${mdarrowright}  alt="logotype" className="arrowslider" />`
                ]}
              >
                {this.props.allNGOS.map((x, i) => {
                  return (
                    <div className="item " key={i}>
                      <Link to={"/NGODetails/" + x.ar_name.replace(/ /g, "_") + "/" + x.id} className=" py-3 text-center stylelink ">
                        <div className="test-center ">
                          <img src={LINKIMAGES + x.ngo_image} style={{ width: 150, height: 150, objectFit: "contain" }} alt="NGO_image" className="m-auto" />
                        </div>
                      </Link>
                    </div>)
                })}



              </OwlCarousel> : ""}

            <Link to="/NGOsListing" className="stylelink fzcblack fz18 text-center fw500 d-block pb-4">
              عرض كل الجمعيات الخيرية
                <img src={arrowleftblack} className="charityimg" alt="image" />
            </Link>

          </div>
        </section>




        <Footer />

      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    data5Campaigns: state.Campaigns.data5Campaigns,
    Categories: state.Campaigns.Categories ? state.Campaigns.Categories : [],
    allNGOS: state.NGOS.allNGOS ? state.NGOS.allNGOS : [],
    uid: state.User.user ? state.User.user.user_id : "",
    filters: state.NGOS.filters,
    Achievements: state.NGOS.Achievements ? state.NGOS.Achievements : ''
  };
}
const mapDispatchToProps = ({
  userInfo: userAction.userInfo,
  get5Campaigns: get5Campaigns,
  getcategories: getcategories,
  getngos: getngos,
  postUserfavourite: userAction.postUserfavourite,
  deleteUserfavourite: userAction.deleteUserfavourite,
  getfilter: getfilter,
  getachievements: getachievements,
  thousands_separators
})
export default connect(mapStateToProps, mapDispatchToProps)(Home);
