import React from "react";
import "./Campaigns.scss";
import { Link } from "react-router-dom";

import { userAction, getfilter } from "../../redux/actions";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Footer from "../Components/Footer/Footer";
import arrowcm from "../../Images/arrowcm.svg";
import { connect } from "react-redux";
import { getAllCampaigns, resetAllCampaigns } from "../../redux/actions";
import CardDonation from "../Components/CardDonation/CardDonation";
import searchcmuted from "../../Images/searchcmuted.svg";

import addicon from "../../Images/addicon.svg";
import { Animated } from "react-animated-css";

class Campaigns extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      rowscount: 20,
      isfav: {},
      unfav: {},
      filterselected: "desc",
      search: ""
    };
    this.handleChangeselect = this.handleChangeselect.bind(this);
    this.handleDataAferSearch = this.handleDataAferSearch.bind(this);
  }
  componentDidMount() {

    this.props.getAllCampaigns(
      this.state.offset,
      this.state.rowscount,
      this.props.uid,
      this.state.filterselected,
      "",
      true
    );

    this.setState({ isfav: {}, unfav: {} });
    this.props.getfilter("campaigns");
  }
  handleChangeselect(e) {
    this.setState(
      {
        filterselected: e.target.value,
        offset: 0,
        rowscount: 20,
        search: "",
        searchbar: false,
      },
      this.props.getAllCampaigns(0, 20, this.props.uid, e.target.value, "", true)
    );
  }
  handleDataAferSearch(e) {

    this.setState(
      {
        search: e.target.value,
        offset: 0,
        rowscount: 20
      },
      this.props.getAllCampaigns(
        0,
        20,
        this.props.uid,
        this.state.filterselected,
        e.target.value,
        true
      )
    );
  }
  clickfav = x => {
    this.props.postUserfavourite(x.campaign_id);
    //  this.props.getAllCampaigns(this.state.offset,this.props.AllCampaigns.length,this.props.uid)
    this.setState({
      isfav: {
        ...this.state.isfav,
        [x.campaign_id]: true
      },
      unfav: {
        ...this.state.unfav,
        [x.campaign_id]: false
      }
    });
  };
  clickunfav = x => {
    this.props.deleteUserfavourite(x.campaign_id);
    this.setState({
      isfav: {
        ...this.state.isfav,
        [x.campaign_id]: false
      },
      unfav: {
        ...this.state.unfav,
        [x.campaign_id]: true
      }
    });
  };
  handelsearchbar = () => {
    this.setState({ searchbar: !this.state.searchbar, search: "" });
  };
  render() {
    // let width = window.innerWidth()

    document.title = "InMemoryOf | All Campaigns";
    document.getElementsByTagName("META")[1].content =
      "IN MEMORY OF was created to help family and friends commemorate their loved ones through memorial donations";
    document.documentElement.lang = "en";
    return (
      <div className="Campaigns">
        <div className="navhe"></div>
        <div className="container">
          <div className="py-2">
            <Link to="" className="fz12 fzcm px-2 fw500">
              الرئيسية
            </Link>
            <span className="fz12 fw500 mt-2">&gt; </span>
          </div>
          <p className="borderdashed m-0"></p>
          <div className="d-md-flex justify-content-between py-3">
            <h3 className="fzcblack black fz24 fw500  m-0 center-v col-lg-6 col-md-5 my-1 ">
              حملات قائمة
            </h3>

            {window.screen.width > 768 ?

              <div className="col-md-1 text-left center-v p-0 my-2 ">
                <img src={searchcmuted} alt="searchcmuted" onClick={this.handelsearchbar} style={{ width: 25 }} />
                {this.state.searchbar ?

                  <div className={" d-flex " + (this.state.searchbar ? " searchbarshow2" : " searchbar2")}>
                    <Animated animationIn="fadeInDown" animationOut="fadeOut" isVisible={true} animationInDelay="0" className="col">
                      <input type="search" className="form-control" value={this.state.search} onChange={this.handleDataAferSearch} />
                    </Animated>
                  </div>
                  : ''}
              </div>
              :
              <div className="col-md-1 text-left d-flex m-0 center-v p-0 my-2 ">
                <img src={searchcmuted} alt="searchcmuted" style={{ width: 25 }} />
                <div className=" col p-1">
                  <input type="search" className="form-control" value={this.state.search} onChange={this.handleDataAferSearch} />
                </div>
              </div>
            }

            <div className="col-lg-5 col-md-6  d-md-flex justify-content-between">
              <Link
                to="/ChooseCampaign"
                className="btn col mx-2 bordercm fzcm fzcwhite fw500  fz16 my-1 "
              >
                <img
                  src={addicon}
                  alt="arrow"
                  className="mx-1"
                  style={{ width: 18 }}
                />
                انشئ حملة جديدة
              </Link>
              <select
                className=" col mx-2 fzcmuted fw500 fz16 my-1 "
                onChange={this.handleChangeselect}
                value={this.state.filterselected}
              >
                {this.props.filters
                  ? this.props.filters.map((x, i) => {
                    return <option key={i} value={x.value}>{x.filter_type}</option>;
                  })
                  : ""}
              </select>
            </div>
          </div>
        </div>
        <section className="allCampaigns py-3">
          <div className="container">
            <CardDonation
              campaigns={this.props.AllCampaigns}
              clickfav={this.clickfav}
              clickunfav={this.clickunfav}
              isfav={this.state.isfav}
              unfav={this.state.unfav}
            />
            {this.props.campaignsCard &&
              <p className="fz16 text-center py-2 fw500 animationtext">{this.props.campaignsCard}</p>}
            {/* <p to="/" onClick={()=>this.props.getAllCampaigns()} className="btn bgcwhite pointer col mx-2 bordercm fzcm  fw500 px-4 my-3 fz18" >
          حمل المزيد
          <img src={arrowcm} alt="arrow"/>
          </p> */}
            {parseInt(this.props.total_campaigns) >
              this.props.AllCampaigns.length &&
              this.props.AllCampaigns.length > 0 ? (
                <p
                  style={{ height: 43 }}
                  onClick={this.props.Loading == "ADDMORE_L" ? '' : () =>
                    this.props.getAllCampaigns(
                      this.props.AllCampaigns.length,
                      this.state.rowscount,
                      this.props.uid,
                      this.state.filterselected,
                      this.state.search
                    )
                  }
                  className="btn bgcwhite pointer col-12 mx-0 bordercm fzcm  fw500 px-4 my-3 fz18"
                >
                  حمل المزيد
                  {this.props.Loading == "ADDMORE_L" ?
                    <div class="spinner-grow text-success mx-2" role="status" style={{ height: 20, width: 20 }}>
                      <span class="sr-only">Loading...</span>
                    </div>
                    :
                    <img src={arrowcm} alt="arrow" />
                  }
                </p>
              ) : (
                ""
              )}
          </div>
        </section>
        <Footer />
      </div >
    );
  }
}

const mapStateToProps = state => {
  return {
    uid: state.User.user ? state.User.user.user_id : "",
    Loading: state.Campaigns ? state.Campaigns.Loading : '',
    AllCampaigns: state.Campaigns ? state.Campaigns.AllCampaigns : [],
    total_campaigns: state.Campaigns.AllCampaigns
      ? state.Campaigns.AllCampaigns[0]
        ? state.Campaigns.AllCampaigns[0].total_campaigns
        : ""
      : 0,
    filters: state.NGOS.filters,
    campaignsCard: state.Campaigns ? state.Campaigns.messagedata ? state.Campaigns.messagedata.campaignsCard : false : false
  };
};
const mapDispatchToProps = {
  getAllCampaigns: getAllCampaigns,
  postUserfavourite: userAction.postUserfavourite,
  deleteUserfavourite: userAction.deleteUserfavourite,
  userInfo: userAction.userInfo,
  getfilter: getfilter,
  resetAllCampaigns: resetAllCampaigns
};
export default connect(mapStateToProps, mapDispatchToProps)(Campaigns);
