import axios from "axios";
import { history } from "../_helpers/history";
import { authHeader, authHeaderWithFD, authHeaderAPI } from "../_helpers/auth-header";
import Cookies from "js-cookie";

import {
  LINKAPIS,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAILURE,
  USER_CAMPAIGNS,
  USER_DONATIONS,
  USER_4CAMPAIGNS,
  USER_4DONATIONS,
  USERS_LOGIN_SUCCESS,
  USERS_LOGIN_FAILURE,
  USER_FAVORITE,
  USER_FAVORITE2,
  USER_4FAVORITE,
  HANDLE_ERROR_GLOBAL,
  USERS_LOGOUT,
  USER_INFO,
  USER_UPDATE,
  USER_POST_FAVORITE,
  USER_DELETE_FAVORITE,
  USER_UPDATE_RESET_M,
  CREATE_CAMPAIGN,
  USER_VERIFY,
  USER_VERIFYMESSAGE,
  ERROR_MESSAGE_BACK_END,
  RESET_ERRORS,
  DELETE_USER,
  RESET_PASSWORD,
  LOADING,
  RESET_USER_ERROR
} from "./types";
export const userAction = {
  Register,
  getUserCampaigns,
  UserDonations,
  getUserCampaigns4,
  UserDonations4,
  Login,
  Logout,
  userInfo,
  getUserfavourite,
  getUserfavourite2,
  UserFavourite4,
  UserUpdate,
  postUserfavourite,
  deleteUserfavourite,
  createCampaignItem,
  Loginandcreate,
  verifymail,
  Deleteuser,
  reseterroruser,
  Resetpassword
};

function Register(user_name, user_email, password) {
  return dispatch => {
    axios
      .post(`${LINKAPIS}/api/users`, {
        user_name: user_name,
        msisdn: false,
        password: password,
        email: user_email
      }, { headers: authHeaderAPI() })
      .then(res => {
        if (res.data.status == 1) {
          dispatch({ type: USER_REGISTER_SUCCESS, payload: res.data.data[0] });
          dispatch({ type: USER_REGISTER_FAILURE, payload: false });
          // console.log(res.data.data[0],"scjhsbchshsbckhsb")
          // localStorage.setItem("user",JSON.stringify(res.data.data[0]))
          // if(window.location.pathname.slice(1).split("/")[0]==="CreateCampaign"){
          // }else{
          //   history.push('/')
          // }
        } else if (res.data.status == 0) {
          dispatch({ type: USER_REGISTER_FAILURE, payload: res.data.message });
        } else {
          dispatch({ type: HANDLE_ERROR_GLOBAL });
        }
      })

      .catch(error => {
        dispatch(HandleCachError(error));
      });
  };
}

function Login(user_email, password) {
  return dispatch => {
    axios
      .post(`${LINKAPIS}/api/auth/login`, {
        email: user_email,
        password: password
      }, { headers: authHeaderAPI() })
      .then(res => {
        if (res.data.status == 1) {
          dispatch({ type: USERS_LOGIN_SUCCESS, payload: res.data.data[0] });
          Cookies.set('user', res.data.data[0]);

          if (window.location.pathname == "/Login") {
            history.push("/");
          }
        } else if (res.data.status == 0) {
          dispatch({ type: USERS_LOGIN_FAILURE, payload: res.data.message });
        } else {
          dispatch({ type: HANDLE_ERROR_GLOBAL });
        }
      })
      .catch(error => {
        dispatch(HandleCachError(error));
      });
  };
}

function Loginandcreate(user_email, password, campaign) {
  return dispatch => {
    axios
      .post(`${LINKAPIS}/api/auth/login`, {
        email: user_email,
        password: password
      }, { headers: authHeaderAPI() })
      .then(res => {
        if (res.data.status == 1) {
          dispatch({ type: USERS_LOGIN_SUCCESS, payload: res.data.data[0] });
          Cookies.set('user', res.data.data[0]);
          {
            dispatch(createCampaignItem(campaign));
          }
        } else if (res.data.status == 0) {
          dispatch({ type: USERS_LOGIN_FAILURE, payload: res.data.message });
        } else {
          dispatch({ type: HANDLE_ERROR_GLOBAL });
        }
      })
      .catch(error => {
        dispatch(HandleCachError(error));
      });
  };
}
function verifymail(token) {
  return dispatch => {
    axios
      .get(`${LINKAPIS}/api/verification?token=${token}`, { headers: authHeaderAPI() })
      .then(res => {

        if (res.data.status == 1) {
          dispatch({ type: USER_VERIFY, payload: true });
          dispatch({ type: USER_VERIFYMESSAGE, payload: res.data.message });

        } else {
          dispatch({ type: USER_VERIFY, payload: false });
          dispatch({ type: USER_VERIFYMESSAGE, payload: res.data.message });
        }
      })
      .catch(error => {
        HandleCachError(error)
      });
  };
}
function userInfo() {
  return dispatch => {
    axios
      .get(`${LINKAPIS}/api/users/details`, { headers: authHeader() })
      .then(res => {
        dispatch({ type: USER_INFO, payload: res.data.data[0] });
        Cookies.set('user', res.data.data[0]);
      })
      .catch(error => {
        dispatch(HandleCachError(error));
      });
  };
}
function Logout(x = "/") {
  return dispatch => {
    axios
      .post(`${LINKAPIS}/api/users/logout`, { headers: authHeaderAPI() })
      .then(res => {
        Cookies.remove("user");
        dispatch({ type: USERS_LOGOUT });
        history.push(x);
      })
      .catch(
        err => {
          Cookies.remove("user");
          dispatch({ type: USERS_LOGOUT });
          history.push(x)
        }
      );
  };
}
//myCampaigns
function getUserCampaigns(o, r) {
  return dispatch => {
    axios
      .get(`${LINKAPIS}/api/users/campaigns?offset=${o}&rowscount=${r}`, {
        headers: authHeader()
      })
      .then(res => dispatch({ type: USER_CAMPAIGNS, payload: res.data.data }))

      .catch(error => {
        dispatch(HandleCachError(error));
      });
  };
}
//myDonations
function UserDonations(o, r) {
  return dispatch => {
    axios
      .get(`${LINKAPIS}/api/users/donations?offset=${o}&rowscount=${r}`, {
        headers: authHeader()
      })
      .then(res => dispatch({ type: USER_DONATIONS, payload: res.data.data }))

      .catch(error => {
        dispatch(HandleCachError(error));
      });
  };
}

//myprofile
function getUserCampaigns4(o, r) {
  return dispatch => {
    axios
      .get(`${LINKAPIS}/api/users/campaigns?offset=${o}&rowscount=${r}`, {
        headers: authHeader()
      })
      .then(res => dispatch({ type: USER_4CAMPAIGNS, payload: res.data.data }))

      .catch(error => {
        dispatch(HandleCachError(error));
      });
  };
}
//myprofile
function UserDonations4(o, r) {
  return dispatch => {
    axios
      .get(`${LINKAPIS}/api/users/donations?offset=${o}&rowscount=${r}`, {
        headers: authHeader()
      })
      .then(res => dispatch({ type: USER_4DONATIONS, payload: res.data.data }))

      .catch(error => {
        dispatch(HandleCachError(error));
      });
  };
}
//myprofile
function UserFavourite4(o, r) {
  return dispatch => {
    axios
      .get(`${LINKAPIS}/api/users/favorites?offset=${o}&rowscount=${r}`, {
        headers: authHeader()
      })
      .then(res => dispatch({ type: USER_4FAVORITE, payload: res.data.data }))

      .catch(error => {
        dispatch(HandleCachError(error));
      });
  };
}
//Edit profile
function UserUpdate(u, m, h, p) {
  return dispatch => {
    const formData = new FormData();
    formData.set("user_name", u);
    formData.set("msisdn", m);
    formData.set("hide_data", h);
    formData.append("profile_pic", p);
    axios
      .put(
        `${LINKAPIS}/api/users`,
        formData,
        { headers: authHeaderWithFD() },
        { onUploadProgress: progressEvent => console.log(progressEvent) }
      )
      .then(res => {
        if (res.data.status == 1) {
          dispatch(userInfo());
          dispatch({ type: USER_UPDATE });
        } else {

        }
        setTimeout(() => {
          dispatch({ type: USER_UPDATE_RESET_M });
        }, 5000);
      })
      .catch(error => {
        dispatch(HandleCachError(error));
      });
  };
}
//myCampaigns
function getUserfavourite(o, r) {
  return dispatch => {
    axios
      .get(`${LINKAPIS}/api/users/favorites?offset=${o}&rowscount=${r}`, {
        headers: authHeader()
      })
      .then(res => {
        dispatch({ type: USER_FAVORITE, payload: res.data.data });
        setTimeout(() => {
          dispatch({ type: USER_UPDATE_RESET_M });
        }, 5000);
      })

      .catch(error => {
        dispatch(HandleCachError(error));
      });
  };
}
function getUserfavourite2(o, r) {
  return dispatch => {
    axios
      .get(`${LINKAPIS}/api/users/favorites?offset=${o}&rowscount=${r}`, {
        headers: authHeader()
      })
      .then(res => {
        dispatch({ type: USER_FAVORITE2, payload: res.data.data });
        setTimeout(() => {
          dispatch({ type: USER_UPDATE_RESET_M });
        }, 5000);
      })

      .catch(error => {
        dispatch(HandleCachError(error));
      });
  };
}
//cardDonation
function postUserfavourite(id) {
  return dispatch => {
    axios
      .post(
        `${LINKAPIS}/api/users/favorites`,
        {
          campaign_id: id
        },
        { headers: authHeader() }
      )
      .then(res => {
        dispatch({ type: USER_POST_FAVORITE });

        setTimeout(() => {
          dispatch({ type: USER_UPDATE_RESET_M });
        }, 5000);
      })
      .catch(error => {
        dispatch(HandleCachError(error));
      });
  };
}
//cardDonation
function deleteUserfavourite(id) {
  return dispatch => {
    axios
      .delete(`${LINKAPIS}/api/users/favorites`, {
        data: { campaign_id: id },
        headers: authHeader()
      })
      .then(res => {
        dispatch({ type: USER_DELETE_FAVORITE });
        setTimeout(() => {
          dispatch({ type: USER_UPDATE_RESET_M });
        }, 5000);
      })
      .catch(error => {
        dispatch(HandleCachError(error));
      });
  };
}
function Deleteuser() {

  return dispatch => {
    axios
      .delete(`${LINKAPIS}/api/users`, { headers: authHeader() })
      .then(res => {

        document.getElementById("closemodelafterdeleteuser").click(); // Click on the checkbox
        Cookies.remove("user");
        dispatch({ type: DELETE_USER });
        dispatch(Logout("/DeleteUser"));
      })
      .catch(error => HandleCachError(error));
  };
}
function reseterroruser() {
  return dispatch => {
    dispatch({ type: RESET_USER_ERROR });
  };
}
function Resetpassword(token, newpass) {
  return dispatch => {
    axios
      .post(`${LINKAPIS}/api/auth/resetpass?token=${token}`, { newpass }, { headers: authHeaderAPI() })
      .then(res => {
        dispatch({ type: RESET_PASSWORD, payload: res.data });
      })
      .catch(error => HandleCachError(error));
  };
}
export function createCampaignItem(campaign) {
  return function (dispatch) {
    dispatch({ type: LOADING, payload: "CREATECAMPAIGN_L" });

    const formData = new FormData();
    formData.set("project_id", campaign.project_id);
    formData.set("campaign_title", campaign.campaign_title);
    formData.set("campaign_description", campaign.campaign_description);
    formData.set("collected", campaign.collected);
    formData.set("type", campaign.type);
    formData.set("msisdn", campaign.phone);
    formData.set("user_id", campaign.user_id);
    formData.append("campaign_image", campaign.campaign_image);
    axios
      .post(`${LINKAPIS}/api/campaigns`, formData, { headers: authHeaderAPI() })
      .then(function (response) {
        if (response.data.status == 1) {
          dispatch({
            type: CREATE_CAMPAIGN,
            payload: response.data.data[0].campaign_id,
            campaign_title: campaign.campaign_title
          });
          dispatch({ type: LOADING, payload: false });

          document.getElementById("modalaftercreatecampaign").click(); // Click on the checkbox
        } else if (response.data.status == 0) {
        } else {
          dispatch({ type: LOADING, payload: false });
          dispatch({ type: HANDLE_ERROR_GLOBAL });
        }
      })
      .catch(error => {
        dispatch(HandleCachError(error));
      });
  };
}
function HandleCachError(error) {
  return dispatch => {
    if (!error.response) {
      dispatch({ type: HANDLE_ERROR_GLOBAL });
      setTimeout(() => {
        dispatch({ type: RESET_ERRORS });
      }, 5000);
    } else if (error.response.status === 401) {
      Cookies.remove("user");
      dispatch({ type: USERS_LOGOUT });
      history.push("/Login");
    } else if (error.response.data.message) {
      dispatch({
        type: ERROR_MESSAGE_BACK_END,
        payload: error.response.data.message
      });
      setTimeout(() => {
        dispatch({ type: RESET_ERRORS });
      }, 5000);
    } else {
      dispatch({ type: HANDLE_ERROR_GLOBAL });
      setTimeout(() => {
        dispatch({ type: RESET_ERRORS });
      }, 5000);
    }
  };
}
