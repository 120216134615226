import React from 'react';
import './CardMyDonation.scss';
import person2 from '../../../Images/person2.png'

import charity1 from '../../../Images/charity1.png'
import moment from 'moment';
import heart from '../../../Images/heart.svg'
import { Link } from "react-router-dom";
import { LINKIMAGES } from "../../../redux/actions/types";
import { Animated } from "react-animated-css";

class CardMyDonation extends React.Component {
  render() {
    return (
      <div className="CardMyDonation ">
        <div className=" row m-auto">

          {this.props.campaigns ? this.props.campaigns.length > 0 ? this.props.campaigns.map((x, i) => {
            return (
              <div className="col-1-5 my-2" key={i}>
                <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true} animationInDelay={this.props.campaigns.length < 20 ? i + "00" : ((i + 20) - this.props.campaigns.length) + "00"}>

                  <div className="card border  mx-auto">
                    <div className="text-center py-2">
                      <span className="fz24 fw600"> {this.props.thousands_separators(x.donation_amount)} </span>
                      <span className="fz14 "> ج.م </span>

                    </div>
                    <div className="card-body p-2 ">
                      <div className="row text-center m-0">
                        <div className="col-9 px-0 ">
                          <p className="fz12 text-center fw500 fzcm m-0">{x.project_ar_name}</p>
                        </div>
                        <div className="col-3 px-1 text-center d-flex align-items-center">
                          <img src={LINKIMAGES + x.ngo_image} className="charityimg" alt="image" />
                        </div>
                      </div>
                      <p className="fzcblack fz18 text-center fw500 py-1 m-0 ">{x.title}</p>
                      <p className="fzcmuted fz12 text-center   py-1 m-0 ">{moment(x.donation_date).format('LL')}</p>
                    </div>
                  </div>
                </Animated>

              </div>

            )
          }) : '' : ''}




          {this.props.create ?
            <Link to="/Campaigns" className="stylelink col-1-5  my-2">
              <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true} animationInDelay="1000">

                {
                  window.screen.width > 577 ?
                    <div className="card bordercm mx-auto" >
                      <div className="card-body p-2 text-center d-flex align-items-center">
                        <div>
                          <img src={heart} alt="heart" style={{ width: 25 }} className="" />
                          <p className="fzcm fz22 px-3 fw500  m-0">تبرع لحملة قائمة</p>
                        </div>
                      </div>
                    </div>


                    :

                    <div className="btn px-4 col mx-0 my-3 bordercm fzcm bgcwhite fzcwhite fw500  fz16" >
                      <img src={heart} alt="heart" className="mx-1" style={{ width: 20, height: 20 }} />
                      تبرع لحملة قائمة</div>

                }
              </Animated>

            </Link>
            : ''
          }

        </div>
      </div>
    );
  }
}
export default CardMyDonation;