import React from 'react';
import './FAQs.scss';
import { Link } from "react-router-dom";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Footer from '../Components/Footer/Footer'
import { connect } from 'react-redux';
import { getFAQs } from '../../redux/actions'


class FAQs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  componentDidMount() {
    this.props.getFAQs()
  }

  render() {
    document.title = "InMemoryOf | FAQs";
    document.getElementsByTagName("META")[1].content = "IN MEMORY OF was created to help family and friends commemorate their loved ones through memorial donations";
    document.documentElement.lang = 'en';
    return (
      <div className="FAQs">
        <div className="navhe"></div>
        <div className="container">
          <div className="py-2">
            <Link to="" className="fz12 fzcm px-2 fw500">الرئيسية</Link>
            <span className="fz12 fw500 mt-2">&gt;	</span>
          </div>
          <p className="borderdashed m-0"></p>
          <h3 className="fzcblack black fz24 fw500 py-3 ">أسئلة  شائعة</h3>
          <div className="row m-0 py-1 pb-5">
            <div className="col-md-6 pt-3">
              <p className="fz18 fw500">للمتبرعين</p>
              <div className="accordion mx-2" id="accordionExample">
                {this.props.allFAQs ? this.props.allFAQs.donators.map((x, i) => {
                  return (
                    <>
                      <p className="borderdashed m-0 "></p>
                      <div className="singlefaq py-2 px-1" data-toggle="collapse" data-target={"#collapse1" + i} aria-expanded="false" aria-controls="collapse" >
                        <div className="row m-0" id={"heading1" + i}>
                          <a className="img"></a>
                          <h2 className="mb-0 py-2 ss fz16 fzcm fw500">
                            {x.question}
                          </h2>
                        </div>
                        <div id={"collapse1" + i} className="collapse collapsed" aria-labelledby={"heading1" + i} data-parent="#accordionExample">
                          <div className="faqbody fz16  fw500">
                            {x.answer}
                          </div>
                        </div>
                      </div>
                      <p className="borderdashed m-0"></p>
                    </>
                  )
                }) : ''}

                <p className="borderdashed m-0"></p>

              </div>
            </div>
            <div className="col-md-6 pt-3">
              <p className="fz18 fw500">لمنشئي الحملات</p>
              <div className="accordion2 mx-2" id="accordionExample2">
                {this.props.allFAQs ? this.props.allFAQs.creators.map((x, i) => {
                  return (
                    <>
                      <p className="borderdashed m-0"></p>
                      <div className="singlefaq py-2 px-3" data-toggle="collapse" data-target={"#collapse2" + i} aria-expanded="false" aria-controls="collapse" >
                        <div className="row m-0" id={"heading2" + i}>
                          <a className="img"></a>
                          <h2 className="mb-0 py-2 ss fz16 fzcm fw500">
                            {x.question}
                          </h2>
                        </div>

                        <div id={"collapse2" + i} className="collapse collapsed" aria-labelledby={"heading2" + i} data-parent="#accordionExample">
                          <div className="faqbody fz16  fw500">
                            {x.answer}
                          </div>
                        </div>
                      </div>
                      <p className="borderdashed m-0"></p>
                    </>
                  )
                }) : ''}
                <p className="borderdashed m-0"></p>


              </div>

            </div>
          </div>
        </div>

        <section className="container">

        </section>

        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    allFAQs: state.NGOS.allFAQs
  };
}
const mapDispatchToProps = ({
  getFAQs: getFAQs
})
export default connect(mapStateToProps, mapDispatchToProps)(FAQs);