import React from 'react';
import './ChooseCampaign.scss';
import mdarrowleftblack from '../../Images/mdarrowrleftblack.svg'
import mdarrowrightblack from '../../Images/mdarrowrightblack.svg'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { LINKIMAGES } from "../../redux/actions/types";
import { Link } from "react-router-dom";
import { Animated } from "react-animated-css";

class Showbycategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  componentDidMount() {
    // setTimeout(() => {
    //   if(this.props.Categories.length>0){this.slider.slickGoTo(5)}
    // }, 1000)

  }

  handelslider = (id) => {
    this.props.resetProjectsBasedonCategory()
    this.props.handleshowProjects(id)
  }


  render() {
    const settings = {
      className: "center",
      centerMode: true,
      infinite: true,
      centerPadding: "60px",
      swipeToSlide: true,
      slidesToShow: this.props.Categories ? this.props.Categories.length < 7 ? this.props.Categories.length - 1 : 7 : '',
      speed: 500,
      focusOnSelect: true,
      // initialSlide: 0,
      afterChange: () => (this.handelslider(document.getElementById("slider1").getElementsByClassName("slick-current")[0].firstChild.firstChild.id)),
      // rtl: true ,
      nextArrow: <img src={mdarrowrightblack} alt="logotype" className="" />,
      prevArrow: <img src={mdarrowleftblack} alt="logotype" className="" />,
      responsive: [
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 5,

          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 3,

          }
        },
        {
          breakpoint: 300,
          settings: {
            slidesToShow: 1,

          }
        }
      ]
    };

    const settings2 = {
      className: "center",
      centerMode: true,
      infinite: false,
      centerPadding: "60px",
      swipeToSlide: true,
      // slidesToShow: 5,
      variableWidth: this.props.Projects ? this.props.Projects.length < 2 ? false : true : '',
      speed: 500,
      focusOnSelect: true,
      //*for old verison slider
      // afterChange: () => (this.props.Categories.length && this.props.setProjectId(document.getElementById("slider2").getElementsByClassName("slick-current")[0].firstChild.firstChild.id)),
      nextArrow: <img src={mdarrowrightblack} alt="logotype" className="" />,
      prevArrow: <img src={mdarrowleftblack} alt="logotype" className="" />,
      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
          }
        },
        {
          breakpoint: 300,
          settings: {
            slidesToShow: 1,
          }
        }
      ]
    };
    return (
      <div className="container Showbycategory" >
        <div id="slider1" className="slider1" onClick={this.props.CampaignTypeSelected ? console.log('') : () => this.props.Categories.length && this.handelslider(document.getElementById("slider1").getElementsByClassName("slick-current")[0].firstChild.firstChild.id)}>
          {this.props.Categories.length > 0 ?
            <Slider ref={slider => (this.slider = slider)} {...settings} >
              {this.props.Categories.map((x, i) => {
                return (
                  <div className="item " id={x.id} key={i} >
                    <div className=" py-0 text-center stylelink "  >
                      <div className="text-center">
                        <img src={LINKIMAGES + x.category_image} style={{ width: 40, height: 40, objectFit: "contain" }} alt="logotype" className="m-auto" />
                      </div>
                      <p className="py-2 fw500 fh56 fzcblock text-center">{x.ar_name}</p>
                    </div>
                  </div>
                )
              })}
            </Slider > : <p className="py-5 fz16 text-center"></p>}
        </div>

        <div className={this.props.CampaignTypeSelected ? "animationslidershow" : "animationsliderhide"}>
          <div className={this.props.CampaignTypeSelected ? "animationslidershow2" : "animationsliderhide2"}>
            <div className="triangle"></div>
            <hr />
          </div>
          {/*for old verison slider*/}
          {/* onClick={this.props.project_id ? console.log('') : () => this.props.setProjectId(document.getElementById("slider2").getElementsByClassName("slick-current")[0].firstChild.firstChild.id)} */}
          <div id="slider2" className="slider2"  >
            {this.props.Projects.length > 0 ?
              <Slider {...settings2}>
                {this.props.Projects.map((x, i) => {
                  return (
                    <Link to={`/CreateCampaign/${x.project_ar_name && x.project_ar_name.replace(/ /g, "_")}/${x.project_id}`} className="item col-1-5 stylelink " id={x.project_id} key={i}>
                      <div className=" " style={{ height: "190px" }} >
                        <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true} animationInDelay={this.props.Projects.length < 20 ? i + "00" : ((i + 20) - this.props.Projects.length) + "00"}>
                          <div className="projectitem  p-3 my-3 bgcwhite text-center mx-auto " >
                            <div className="text-center">
                              <img src={LINKIMAGES + x.ngo_image} style={{ width: 70, height: 70, objectFit: "contain", border: " 1px solid #F1F1F1" }} alt="logotype" className="m-auto" />
                            </div>
                            <p className="py-1 fw500 fz16 fzcblock text-center fh56  m-0">{x.project_ar_name}</p>
                            <p className="py-1 fw500 fz22 fzcblock text-center m-0">  {this.props.thousands_separators(x.project_amount)} <span className="fz12">ج.م</span></p>
                          </div>
                        </Animated>
                      </div>
                    </Link>
                  )
                })}
              </Slider> : <p className="fz16 text-center py-2 fw500 animationtext">لا يوجد  حملات تبرع لهذا القسم حتى الآن</p>}
          </div>
        </div>
      </div>
    );
  }
}

export default Showbycategory;