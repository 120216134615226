import React from 'react';
import './TransactionFailed.scss';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import Footer from '../Components/Footer/Footer'
import error from "../../Images/error.svg";
class TransactionFailed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  componentDidMount() {
  }
  render() {
    return (
      <div className="TransactionFailed">
        <div className="navhe"></div>
        <section className="formlogin py-4 my-5 col-lg-4 col-md-5 mx-auto px-lg-4 text-center">
          {/* <img src={success} alt="" style={{ width: 130 }} /> */}
          <img src={error} alt="" style={{ width: 130 }} />
          <h3 className="fzcblack  fz24 fw500 text-center py-3 ">لم يتم اتمام عملية التحويل بنجاح </h3>
          <Link to="/Campaigns" className="fz14 fzcm">حاول مره اخري </Link>
        </section>
        <button id="closemodal" data-dismiss="modal" style={{display:"none"}}></button>
        <Footer />
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
  };
}
const mapDispatchToProps = ({
})
export default connect(mapStateToProps, mapDispatchToProps)(TransactionFailed);