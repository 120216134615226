import React from 'react';
import './Terms.scss';
import { Link } from "react-router-dom";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Footer from '../Components/Footer/Footer'
import { connect } from 'react-redux';

class Terms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  componentDidMount() {

  }


  render() {
    document.title = "InMemoryOf | Terms";
    document.getElementsByTagName("META")[1].content = "IN MEMORY OF was created to help family and friends commemorate their loved ones through memorial donations";
    document.documentElement.lang = 'en';
    return (
      <div className="Terms">
        <div className="navhe"></div>
        <div className="container">
          <div className="py-2">
            <Link to="" className="fz12 fzcm px-2 fw500">الرئيسية</Link>
            <span className="fz12 fw500 mt-2">&gt;	</span>
          </div>
          <p className="borderdashed m-0"></p>
          <h3 className="fzcblack black fz24 fw500 py-3 ">الشروط والأحكام</h3>
          <p className=" fz16 fw500 ">تعتبر الشروط والأحكام المذكورة ادناه عقدا قد وقع بينك كمستخدم (طرف اول) وبين شركة تقاليد للبرمجيات المالكة للموقع كمالك للشبكة (طرف ثاني). وهذه الشروط والأحكام تنطبق في كل مرة تقوم بالدخول على الشبكة. فعلي كل مستخدم للشبكة او زائر للموقع قراءة تلك الشروط بعناية مع الفهم الكامل لها. كما يرجى ملاحظة أنه مجرد الدخول على الشبكة، يعتبر هذا قبول رسمي على هذه الشروط والأحكام</p>
          <p className="fz16 fzcm fw500">شروط وأحكام النشر علي الموقع</p>
          <ul className="fz16 fw500">
            <li>في حال انشاء حملة تبرع جماعي علي الموقع بواسطة احد مستخدمي الموقع فعلي الناشر مراعاة حقوق الملكية الفكرية لأي شخص او مؤسسة اخري ومراعاة حُرمة الموت والتأكد من صحة اي خبر يتم نشره</li>
            <li> في حال نشر صور أو معلومات شخصية عن المتوفي تكون تحت المسؤلية الشخصية للناشر ويجب عليه مراعاة حقوق الملكية الفكرية والشخصية </li>
            <li>في حال نشر احد مستخدمي الموقع لخبر كاذب تقع المسؤلية كاملةً علي المستخدم و قد يتعرض للمسآلة القانونية. ويكفل الموقع الحق في مشاركة البيانات الشخصية للناشر مع من وقع عليه الضرر</li>
            <li> يقوم الموقع بالتعاون مع مؤسسات خيرية معروفة لتوفير اكبر عدد من المشروعات الخيرية لمستخدمي الموقع وكل مشروع يتم نشر إسم المؤسسة الخيرية المسؤلة عنه للشفافية علماً بأن مشروعات التبرع يتم تنفيذها وتفعيلها عن طريق المؤسسات الخيرية ولا تقوم الشركة بتنفيذ المشاريع الخيرية</li>
          </ul>
          <p className="fz16 fzcm fw500">شروط وأحكام النشر علي الموقع</p>
          <ul className="fz16 fw500">
            <li>	جميع التبرعات تذهب مباشرةً لحسابات المؤسسات الخيرية ولا يقوم الموقع بجمع التبرعات نيابةً عنهم في أي حال من الأحوال</li>
            <li>	في حال التبرع بالرسائل النصية فعلي المتبرع التأكد من كود الحملة ورقم المؤسسة الخيرية المراد التبرع لها حيث انها لا يمكننا تعديل توجيه التبرع بعد الإرسال </li>
            <li>	في حال التبرع عن طريق البطاقة الإئتمانية فجميع التبرعات تذهب مباشرةً لحساب المؤسسة الخيرية عن طريق صفحة الدفع البنكية الخاصة بالمؤسسة الخيرية مع ضمان حفظ سرية المعلومات بضمان البنك</li>
            <li>	لا يمكن رد أو إعادة توجيه التبرع لحملة اخري بعد تأكيد تحصيل مبلغ التبرع</li>
          </ul>
        </div>

        <section className="container">

        </section>

        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {

  };
}
const mapDispatchToProps = ({
})
export default connect(mapStateToProps, mapDispatchToProps)(Terms);