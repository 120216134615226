import React from 'react';
import './Footer.scss';
import creditcard from '../../../Images/credit-card.svg'
import funds from '../../../Images/funds.svg'
import speechbubble from '../../../Images/speech-bubble.svg'
import twitterlogo from '../../../Images/twitterlogo.svg'
import facebooklogo from '../../../Images/facebooklogo.svg'
import logoblack from '../../../Images/logoblack.png'

import { Link } from "react-router-dom";

class Footer extends React.Component {
  render() {
    return (
      <footer>
        <div className="startdonations bgcm" >
          <h3 className="fzcwhite black fz18 fw300 py-3 m-0 text-center">
            يتم تحصيل جميع التبرعات بواسطة  المؤسسات الخيرية  عن طريق الكاش
      &ensp;<img src={funds} style={{ width: 27 }} alt="logotype" className="m-auto" />
            &ensp;
            او البطاقة الإئتمانية
      &ensp;<img src={creditcard} style={{ width: 27 }} alt="logotype" className="m-auto" />
            &ensp;
            او الرسائل النصية
      &ensp;<img src={speechbubble} style={{ width: 27 }} alt="logotype" className="m-auto" />
            &ensp;

      </h3>
        </div>

        <div className="container">
          <div className="row m-0 py-3">
            <div className="col-md-2  col-6 fw500">
              <Link to="/ChooseCampaign" className="stylelink d-block fzcmuted py-1 fz14">
                أنشئ حملة جديدة
                </Link>
              <Link to="/Campaigns" className="stylelink d-block fzcmuted py-1 fz14">
                تبرع لحملات قائمة
                </Link>
              <Link to="/ProjectsListing" className="stylelink d-block fzcmuted py-1 fz14">
                المشروعات المتاحة
                </Link>
              <Link to="/NGOsListing" className="stylelink d-block fzcmuted py-1 fz14">
                الجمعيات الخيرية
                </Link>
            </div>
            <div className="col-md-2  col-6 fw500">
              <Link to="/PressCoverage" className="stylelink d-block fzcmuted py-1 fz14">
                من نحن
                </Link>
              <Link to="/ContactUs" className="stylelink d-block fzcmuted py-1 fz14">
                اتصل بنا
                </Link>
              <Link to="/FAQs" className="stylelink d-block fzcmuted py-1 fz14 ">
                أسئلة شائعة
                </Link>
              <Link to="/HowItWork" className="stylelink d-block fzcmuted py-1 fz14">
                كيف نعمل
                </Link>
            </div>
            <div className="col-md-2  col-6 fw500">
              <Link to="/SuccessTips" className="stylelink d-block fzcmuted py-1 fz14">
                نصائح للنجاح
                </Link>
              <Link to="/TrustandSafety" className="stylelink d-block fzcmuted py-1 fz14 ">
                الثقة والسلامة
                </Link>
              <Link to="/Impact" className="stylelink d-block fzcmuted py-1 fz14 ">
                الإنجازات
                </Link>

            </div>
            <div className="col-2">

            </div>
            <div className="col-2">

            </div>
            <div className="col-md-2 col-6 mx-auto center-v">
              <img src={logoblack} alt="logotype" className="m-auto w-100  d-block" />
            </div>
          </div>
          <hr className="my-0 mx-auto" style={{ width: "99.5%" }} />

          <div className="d-md-flex justify-content-between py-2 mb-0">
            <div className="ml-auto d-md-flex p-2 fzcmuted fw500  ">
              <p className="fzcmuted fw500 fz14 py-1 m-0">جميع الحقوق محفوظة © 2020</p>
              <Link to="/Terms" className="stylelink mx-4 fzcmuted py-1 fw500 fz14 m-0">
                الشروط والأحكام
                </Link>
            </div>

            <div className="mr-auto p-2 bd-highlight mq-sm-center">
              <a target="_blank" href="https://www.facebook.com/inmemoryofapp/" className="stylelink">
                &ensp;<img src={facebooklogo} style={{ height: 25 }} alt="logotype" className="m-auto" />
              </a>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;