import React from 'react';
import './SuccessTips.scss';
import { Link } from "react-router-dom";
import Footer from '../Components/Footer/Footer'
import { connect } from 'react-redux';
import projectimg from '../../Images/projectimg.png';
import addicon from '../../Images/addicon.svg'

class SuccessTips extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  componentDidMount() {

  }


  render() {
    document.title = "InMemoryOf | Success Tips";
    document.getElementsByTagName("META")[1].content = "IN MEMORY OF was created to help family and friends commemorate their loved ones through memorial donations";
    document.documentElement.lang = 'en';
    return (
      <div className="SuccessTips">
        <div className="navhe"></div>
        <div className="container">
          <div className="py-2">
            <Link to="" className="fz12 fzcm px-2 fw500">الرئيسية</Link>
            <span className="fz12 fw500 mt-2">&gt;	</span>
          </div>
          <p className="borderdashed m-0"></p>
          <h3 className="fzcblack black fz24 fw500 py-3 ">نصائح لنجاح الحملة</h3>
        </div>
        <section className="container">
          {/* <header></header> */}
          <div className="row m-0 py-md-5  ">
            <div className="col-md-3 px-md-4  px-1">
              <div className="row mb-2 d-flex  ">
                <div className=" col-3 "><div className="stepnumber fzcm fz30">1</div></div>
                <p className="col-9 steptitle px-1 m-0 fz16   fw500 ">اعلن عن كود الحملة للمقربين من المتوفي شخصياً في خلال ٤٨ من تاريخ إنشاء الحملة  </p>
              </div>
              <p className="fw500 fz16 ">
              </p>
            </div>
            <div className="col-md-3 px-md-4  px-1">
              <div className="row mb-2 d-flex ">
                <div className=" col-3 "><div className="stepnumber fzcm fz30">2</div></div>
                <p className="col-9 steptitle px-1 m-0 fz16   fw500 ">
                  انشر الحملة على وسائل التواصل الاجتماعي ليصل رابط الحملة لجميع معارف المتوفي ممن لا تعرفهم شخصياً                    </p>

              </div>
            </div>
            <div className="col-md-3 px-md-4  px-1">
              <div className="row mb-2 d-flex ">
                <div className=" col-3 "><div className="stepnumber fzcm fz30">3</div></div>
                <p className="col-9 steptitle px-1 m-0 fz16   fw500 ">
                  أضف صورة شخصية ونبذة عن المتوفي ليتأكد الجميع من صحة الرابط والحملة                    </p>
              </div>

            </div>
            <div className="col-md-3 px-md-4  px-1">
              <div className="row mb-2 d-flex ">
                <div className=" col-3 "><div className="stepnumber fzcm fz30">4</div></div>
                <p className="col-9 steptitle px-1 m-0 fz16   fw500 ">
                  يفضل عدم إنشاء أكثر من حملة في نفس الوقت حيث انه قد يتسسب ذلك في تشتيت المتبرعين وعدم ظهور الحملات في اولوية الحملات علي الموقع                   </p>
              </div>

            </div>
            <div className="col-md-3 px-md-4  px-1">
              <div className="row mb-2 d-flex ">
                <div className=" col-3 "><div className="stepnumber fzcm fz30">5</div></div>
                <p className="col-9 steptitle px-1 m-0 fz16   fw500  ">
                  اجعلها عادة أن تتبرع - احفظ الكود على هاتفك وكلما تذكرتم المتوفى قوموا بإرسال الكود والتبرع لتجلب ذكراه الخير لكل محتاج                        </p>
              </div>

            </div>
            <div className="col-md-3 px-md-4  px-1">
              <div className="row mb-2 d-flex ">
                <div className=" col-3 "><div className="stepnumber fzcm fz30">6</div></div>
                <p className="col-9 steptitle px-1 m-0 fz16   fw500  ">
                  في كل مناسبة سعيدة مثل أعياد الميلاد قوموا بالتبرع ومهاداة المتوفى بعمل خيري لتخليد ذكراه                     </p>
              </div>

            </div>
          </div>
        </section>


        <div className="mb-5 mx-auto text-center">
          <Link to="/ChooseCampaign" className="btn px-5 py-2 stylelink  bordercm fzcm fzcwhite  fw500 fz18   my-3" role="button" aria-pressed="true" >
            <img src={addicon} alt="arrow" className="mx-1" style={{ width: 20, height: 20 }} />

            أنشئ حملة تبرعات
           </Link>

        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {

  };
}
const mapDispatchToProps = ({
})
export default connect(mapStateToProps, mapDispatchToProps)(SuccessTips);