import React from "react";
import { Link } from "react-router-dom";
import Footer from '../Components/Footer/Footer'
import { history } from "../../redux/_helpers/history";
import axios from "axios";
class PathNotFound extends React.Component {
  componentDidMount() {
    console.log(window.location.pathname.split("/")[1])

    if (window.location.pathname.split("/").length < 3) {
      axios
        .get(`http://inmemoryof.com/megakheir/api/GetCampiagnID/${window.location.pathname.split("/")[1]}`)
        .then(res => {
          history.push(`/CampaignDonation/${window.location.pathname.split("/")[1]}/${res.data}`)
        })
        .catch(error => {
          if (error.response.status !== 200) {
            if (window.location.pathname !== "/") {
              history.push("/PathNotFound")
            }
          }
        }
        );
    } else {
      history.push("/PathNotFound")
    }
  }
  render() {
    return (
      <div className="PathNotFound">
        <div className="navhe"></div>
        <div className="container">
          <div className="py-2">
            <Link to="" className="fz12 fzcm px-2 fw500">الرئيسية</Link>
            <span className="fz12 fw500 mt-2">&gt;	</span>
          </div>
          <p className="borderdashed m-0"></p>

          <div className="  text-center">

            <p className="fz49 my-1 fw500">! Oops </p>
            <p className="fz16my-1 fw500"> 404 - Page not found</p>
            <p className="col-md-5 mx-auto fz14 fw500">The page you are looking for might have been removed had its name changed or temporarily unavailable</p>

          </div>
          <div className="mb-5 mx-auto text-center">
            <Link to="/" className="btn px-5 py-2 stylelink fzcwhite  fw500 fz18 bgcm  my-3" role="button" aria-pressed="true" >
              Back to homepage
           </Link>
          </div>
        </div>
        <Footer />

      </div>
    );
  }
}
export default PathNotFound;
