import React from 'react';
import './Verifymail.scss';
import { Link } from "react-router-dom";
import { userAction } from '../../redux/actions'
import { connect } from 'react-redux';


import Footer from '../Components/Footer/Footer'


import facebookwhite from '../../Images/facebookwhite.svg'


class Verifymail extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      user_email: "",
      password: "",
      erroruser_email: "",
      errorpassword: "",
    };
  }
  componentDidMount() {
    this.props.verifymail(this.props.match.params.token)
  }



  render() {
    return (
      <div className="Login">
        <div className="navhe"></div>
        <section className="formlogin py-4 my-5 col-lg-4 col-md-5 col-11 mx-auto px-lg-4 text-center">
          {this.props.verify ?
            <>
              <h3 className="fzcblack  fz24 fw500 text-center py-3 ">{this.props.verifyMessage}</h3>
              <Link to="/Login" className="fz14 fzcm">تسجيل الدخول من هنا</Link>
            </>
            :
            <>
              <h3 className="fzcblack  fz24 fw500 text-center py-3 ">{this.props.verifyMessage}</h3>
              <Link to="/Register" className="fz14 fzcm">تسجيل حساب من هنا</Link>
            </>

          }
        </section>





        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    errorMessage: state.User.errorMessage,
    verify: state.User.verify ? state.User.verify : '',
    verifyMessage: state.User ? state.User.verifyMessage : ''
  };
}
const mapDispatchToProps = ({
  verifymail: userAction.verifymail
})
export default connect(mapStateToProps, mapDispatchToProps)(Verifymail);