import React from 'react';
import './Impact.scss';
import { Link } from "react-router-dom";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Footer from '../Components/Footer/Footer'
import { connect } from 'react-redux';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Loadable from '@loadable/component';
import mdarrowleftblack from '../../Images/mdarrowrleftblack.svg'
import mdarrowrightblack from '../../Images/mdarrowrightblack.svg'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { getcategories } from '../../redux/actions'
import { LINKIMAGES } from "../../redux/actions/types";
import { getProjectsBasedonCategory, resetProjectsBasedonCategory, thousands_separators } from '../../redux/actions'

const OwlCarousel = Loadable(() => import('react-owl-carousel'));


class Impact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      responsive2: {
        "0": {
          items: 1

        },
        "480": {
          items: 2

        },
        "768": {
          items: 3

        },
        "900": {
          items: 4

        },
        "1200": {
          items: 5

        }
      }
    }
  }
  componentDidMount() {
    this.props.getcategories()

  }
  handelshowprojects = (id) => {
    this.props.resetProjectsBasedonCategory()
    this.props.getProjectsBasedonCategory(id, 0, "")
  }


  render() {
    document.title = "InMemoryOf | Impact";
    document.getElementsByTagName("META")[1].content = "IN MEMORY OF was created to help family and friends commemorate their loved ones through memorial donations";
    document.documentElement.lang = 'en';
    // const settings1 = {
    //   className: "center",
    //   centerMode: true,
    //   infinite: true,
    //   swipeToSlide: true,
    //   slidesToShow: 5,
    //   speed: 500,
    //   // focusOnSelect: true,
    //   // initialSlide: 0,
    //   centerPadding: "60px",

    //   // afterChange: () => (this.handelslider(document.getElementById("slider1").getElementsByClassName("slick-current")[0].firstChild.firstChild.id)),
    //   // rtl: true ,
    //   nextArrow: <img src={mdarrowrightblack} alt="logotype" className="" />,
    //   prevArrow: <img src={mdarrowleftblack} alt="logotype" className="" />,
    //   responsive: [
    //     {
    //       breakpoint: 900,
    //       settings: {
    //         slidesToShow: 5,
    //         centerPadding: "0px",

    //       }
    //     },
    //     {
    //       breakpoint: 480,
    //       settings: {
    //         slidesToShow: 3,
    //         centerPadding: "0px",

    //       }
    //     },
    //     {
    //       breakpoint: 300,
    //       settings: {
    //         slidesToShow: 1,

    //       }
    //     }
    //   ]
    // };
    const settings2 = {
      centerMode: true,
      infinite: true,
      swipeToSlide: true,
      slidesToShow: this.props.Categories ? this.props.Categories.length < 7 ? this.props.Categories.length - 1 : 7 : '',
      speed: 500,
      focusOnSelect: true,
      afterChange: () => (this.handelshowprojects(document.getElementById("sliderCategories").getElementsByClassName("slick-current")[0].firstChild.firstChild.id)),
      onInit: () => (this.handelshowprojects(this.props.Categories[0].id)),
      // afterChange: () => (this.props.setProjectId(document.getElementById("slider2").getElementsByClassName("slick-current")[0].firstChild.firstChild.id)),
      nextArrow: <img src={mdarrowrightblack} alt="logotype" className="" />,
      prevArrow: <img src={mdarrowleftblack} alt="logotype" className="" />,
      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 7,
          }
        },
        {
          breakpoint: 599,
          settings: {
            slidesToShow: 1,
          }
        },
        {
          breakpoint: 300,
          settings: {
            slidesToShow: 1,
          }
        }
      ]
    };
    return (
      <div className="Impact">
        <div className="navhe"></div>
        <div className="container">
          <div className="py-2">
            <Link to="" className="fz10 fzcm px-2 fw500">الرئيسية</Link>
            <span className="fz10 fw500 mt-2">&gt;	</span>
          </div>
          <p className="borderdashed m-0"></p>
          <h3 className="fzcblack black fz28 fw500 py-3 ">الإنجازات </h3>

        </div>

        <section className="container">
          {this.props.Projects.length > 0 ?
            <OwlCarousel
              className="owl-carousel owl-theme slider1"
              items={5}
              margin={10}
              // startPosition={2}
              responsive={this.state.responsive2}
              center
              nav={true}
              animateIn={true}
              dots={false}
              id="items"
              navText={[` <img src=${mdarrowleftblack} alt="logotype" className="mdarrowleftblack" />`,
              `<img src=${mdarrowrightblack} alt="logotype" className="mdarrowleftblack" />`
              ]}>




              {this.props.Projects.map((x, i) => {
                return (

                  <div className="item ">
                    <div to="" className="  py-0   stylelink ">
                      <div className="text-center">
                        <p className="fzcm fz28 m-0 fw500"> {this.props.thousands_separators(x.donations_collected)} <span className="fzcmuted fz10 fw500 mb-2">ج.م</span></p>
                        <p className="fzcmuted fz10 fw500 mb-2">تم تمويلها</p>
                      </div>
                      <div className="row m-0 d-flex align-items-center">
                        <img src={LINKIMAGES + x.ngo_image} style={{ width: "100%" }} alt="logotype" className="m-auto col-3 p-0" />
                        <p className="py-2 fw500 fz10 fzcmuted col-9 m-0">{x.project_ar_name}</p>
                      </div>
                    </div>
                  </div>
                )
              })}
            </OwlCarousel>
            : <p className="fz16 text-center py-2 fw500 animationtext">لا يوجد حملات تبرع لهذا القسم حتى الآن</p>}
          <div className="relative">
            <div className="triangle"></div>
            <hr />
          </div>



          <div id="sliderCategories" className="sliderCategories py-5">
            {this.props.Categories.length > 0 ?
              < Slider {...settings2}>

                {this.props.Categories.map((x, i) => {
                  return (
                    <div className="item  " id={x.id} key={i} >
                      <p className="py-1 fw500 fz16 fzcblock text-center m-0">{x.ar_name}</p>
                    </div>
                  )
                })}

              </Slider > : ''}
          </div>



        </section>

        <Footer />
      </div >
    );
  }
}

const mapStateToProps = state => {
  return {
    Categories: state.Campaigns.Categories ? state.Campaigns.Categories : [],
    Projects: state.Campaigns.Projects ? state.Campaigns.Projects : [],

  };
}
const mapDispatchToProps = ({
  getcategories: getcategories,
  getProjectsBasedonCategory: getProjectsBasedonCategory,
  resetProjectsBasedonCategory: resetProjectsBasedonCategory,
  thousands_separators

})
export default connect(mapStateToProps, mapDispatchToProps)(Impact);