import React from "react";
import "./ProjectsListing.scss";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getfilter,
  getProjectsId,
  getcategories,
  getProjectsBasedonCategory,
  resetProjectsBasedonCategory,
  thousands_separators
} from "../../redux/actions";

import Footer from "../Components/Footer/Footer";
import searchcmuted from "../../Images/searchcmuted.svg";
import arrowcm from "../../Images/arrowcm.svg";
import close from "../../Images/close.svg";
import addicon from "../../Images/addicon.svg";
import addwhiteicon from "../../Images/addwhiteicon.svg";
import smsblack from "../../Images/smsblack.svg";
import moneyblack from "../../Images/moneyblack.svg";
import fundsblack from "../../Images/fundsblack.svg";
import creditcardblack from "../../Images/creditcardblack.svg";
import enterpriseblack from "../../Images/enterpriseblack.svg";
import moment from "moment";

import mdarrowleftblack from "../../Images/mdarrowrleftblack.svg";
import mdarrowrightblack from "../../Images/mdarrowrightblack.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { LINKIMAGES } from "../../redux/actions/types";
import { Animated } from "react-animated-css";

class ProjectsListing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      CampaignTypeSelected: false,
      accessKey: 3,
      offset: 0,
      rowscounts: 20,
      cat_id: false,
      filterselected: "",
      search: ""
    };
    this.handleChangeselect = this.handleChangeselect.bind(this);
    this.handleDataAferSearch = this.handleDataAferSearch.bind(this);
    this.handelslider = this.handelslider.bind(this);


  }
  componentDidMount() {
    this.props.getcategories();
    this.props.getfilter("/ngos/projects");
  }
  handelslider = (id) => {
    //  this.props.resetProjectsBasedonCategory()
    this.setState((state, props) => ({
      cat_id: id,
      filterselected: props.filters[0].value
    }
    ),
      () => this.props.getProjectsBasedonCategory(
        id,
        this.state.offset,
        this.state.rowscounts,
        this.props.filters[0] ? this.props.filters[0].value : "default",
        "",
        true

      )
    )


  };

  getProjectsId = id => {
    this.props.getProjectsId(id);
  };
  handleclosemodle = () => {
    document.getElementById("closemodal").click();
  };
  handelsearchbar = () => {
    this.setState({ searchbar: !this.state.searchbar });
  };
  handleChangeselect = e => {

    this.setState(
      {
        filterselected: e.target.value,
        offset: 0,
        rowscount: 20,
        search: "",
        searchbar: false
      },
      this.props.getProjectsBasedonCategory(
        this.state.cat_id,
        0,
        20,
        e.target.value,
        "",
        true
      )
    );
  }
  handleDataAferSearch(e) {
    // this.props.resetProjectsBasedonCategory();
    this.setState(
      {
        search: e.target.value,
        offset: 0,
        rowscount: 20,
        CampaignTypeSelected: true
      },
      this.props.getProjectsBasedonCategory(
        this.state.cat_id,
        0,
        20,
        this.state.filterselected,
        e.target.value,
        true
      )
    );
    if (e.target.value === "") {
      this.setState({ CampaignTypeSelected: false });
    }
  }
  render() {
    document.title = "InMemoryOf | All Projects";
    document.getElementsByTagName("META")[1].content =
      "IN MEMORY OF was created to help family and friends commemorate their loved ones through memorial donations";
    document.documentElement.lang = "en";
    const settings = {
      className: "center",
      centerMode: true,
      infinite: true,
      centerPadding: "60px",
      swipeToSlide: true,
      slidesToShow: this.props.Categories ? this.props.Categories.length < 7 ? this.props.Categories.length - 1 : 7 : '',
      speed: 500,
      focusOnSelect: true,
      afterChange: () => this.props.Categories.length &&
        this.handelslider(
          document
            .getElementById("slider3")
            .getElementsByClassName("slick-current")[0].firstChild.firstChild.id
        ),
      // rtl: true ,
      nextArrow: <img src={mdarrowrightblack} alt="logotype" className="" style={{ display: "none" }} />,
      prevArrow: <img src={mdarrowleftblack} alt="logotype" className="" style={{ display: "none" }} />,
      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 5,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 3,
          }
        },
        {
          breakpoint: 300,
          settings: {
            slidesToShow: 1,
            nextArrow: <img src={mdarrowrightblack} alt="logotype" className="" style={{ display: "none" }} />,
            prevArrow: <img src={mdarrowleftblack} alt="logotype" className="" style={{ display: "none" }} />,
          }
        }
      ]
    };
    return (
      <div className="ProjectsListing">
        <div className="navhe"></div>
        <div className="container">
          <div className="py-2">
            <Link to="" className="fz12 fzcm px-2 fw500">
              الرئيسية
            </Link>
            <span className="fz12 fw500 mt-2">&gt; </span>
          </div>
          <p className="borderdashed m-0"></p>
          <div className="d-md-flex justify-content-end py-3">
            <h3 className="fzcblack black fz24 fw500  m-0  my-1 center-v col-lg-6 col-md-5">
              المشروعات
            </h3>
            {window.screen.width > 768 ?
              <div className="col-md-1 text-left  my-1 center-v p-0 my-2 ">
                <img src={searchcmuted} alt="searchcmuted" onClick={this.handelsearchbar} style={{ width: 25 }} />
                {this.state.searchbar ?

                  <div className={" d-flex " + (this.state.searchbar ? " searchbarshow2" : " searchbar2")}>
                    <Animated animationIn="fadeInDown" animationOut="fadeOut" isVisible={true} animationInDelay="0" className="col">
                      <input type="search" className="form-control" value={this.state.search} onChange={this.handleDataAferSearch} />
                    </Animated>
                  </div>
                  : ''}

              </div>



              :
              <div className="col-md-1 text-left  my-1 d-flex m-0 center-v p-0 my-2 ">
                <img src={searchcmuted} alt="searchcmuted" style={{ width: 25 }} />
                <div className=" col p-1">
                  <input type="search" className="form-control" value={this.state.search} onChange={this.handleDataAferSearch} />
                </div>
              </div>
            }
            <div className="col-lg-5 col-md-6  d-md-flex justify-content-between">
              <Link
                to="/ChooseCampaign"
                className="btn col mx-2 my-1 bordercm fzcm fzcwhite fw500  fz16"
              >
                <img
                  src={addicon}
                  alt="addicon"
                  style={{ width: 18 }}
                  className="mx-1"
                />
                انشئ حملة جديدة
              </Link>
              <select
                className=" col mx-2  my-1 fzcmuted fw500 fz16"
                value={this.state.filterselected}
                onChange={this.handleChangeselect}
              >
                {this.props.filters
                  ? this.props.filters.map((x, i) => {
                    return <option value={x.value} key={i}>{x.filter_type}</option>;
                  })
                  : ""}
              </select>
            </div>
          </div>
        </div>

        <section className="allProjects py-3">
          <div className="container ">
            <p
              className="fz18 fw500 text-center"
              style={this.state.search !== "" ? { display: "none" } : {}}
            >
              اختر حملة
            </p>
            <div
              id="slider3"
              className="slider3"
              onClick={
                this.state.CampaignTypeSelected
                  ? console.log("")
                  : () => this.props.Categories.length &&

                    this.setState({ CampaignTypeSelected: true }, () => this.handelslider(
                      document
                        .getElementById("slider3")
                        .getElementsByClassName("slick-current")[0].firstChild
                        .firstChild.id
                    ))
              }
              style={this.state.search !== "" ? { display: "none" } : {}}
            >
              {this.props.Categories.length > 0 ? (
                <Slider {...settings}>
                  {this.props.Categories.map((x, i) => {
                    return (
                      <div className="item " id={x.id} key={i}>
                        <div to="" className=" py-0 text-center stylelink ">
                          <div className="text-center">
                            <img
                              src={LINKIMAGES + x.category_image}
                              style={{
                                width: 40,
                                height: 40,
                                objectFit: "contain"
                              }}
                              alt="logotype"
                              className="m-auto"
                            />
                          </div>
                          <p className="py-2 fw500 fh56 fzcblock text-center">
                            {x.ar_name}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              ) : (
                  <p className="py-5 fz16 text-center"></p>
                )}
            </div>

            <div
              className={
                this.state.CampaignTypeSelected ? "datashow" : "datahide"
              } >
              <div className="relative">
                <div className="triangle"></div>
                <hr />
              </div>

              <div className="row mx-auto ">
                {this.props.Projects.length > 0 ? (
                  this.props.Projects.map((x, i) => {
                    return (
                      <div
                        className="col-1-5"
                        key={i}
                        onClick={() => this.getProjectsId(x.project_id)}
                      >
                        <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true} animationInDelay={this.props.Projects.length < 20 ? i + "00" : ((i + 20) - this.props.Projects.length) + "00"}>

                          <div
                            className="projectitem  p-3 my-3 bgcwhite text-center mx-auto "
                            data-toggle="modal"
                            data-target=".bd-example-modal-lg"
                          >
                            <div className="text-center">
                              <img
                                src={LINKIMAGES + x.ngo_image}
                                style={{
                                  width: 70,
                                  height: 70,
                                  objectFit: "contain",
                                  border: " 1px solid #F1F1F1"
                                }}
                                alt="logotype"
                                className="m-auto"
                              />
                            </div>
                            <p className="py-1 fw500 fz16 fzcblock text-center m-0 fh56">
                              {x.project_ar_name}
                            </p>
                            <p className="py-1 fw500 fz22 fzcblock text-center m-0">
                              {" "}
                              {this.props.thousands_separators(x.project_amount)} <span className="fz12">ج.م</span>
                            </p>
                          </div>
                        </Animated>
                      </div>
                    );
                  })
                ) : (
                    <p className="fz16 text-center mx-auto  py-2 fw500 animationtext">

                    </p>
                  )}
              </div>

              {parseInt(this.props.total_projects) >
                this.props.Projects.length && this.props.Projects.length > 0 ? (
                  <p
                    onClick={() =>
                      this.props.getProjectsBasedonCategory(
                        this.state.cat_id,
                        this.props.Projects.length,
                        this.state.rowscounts,
                        this.state.filterselected,
                        this.state.search

                      )
                    }
                    className="btn bgcwhite pointer col mx-0 bordercm fzcm  fw500 px-4 my-3 fz18"
                  >
                    حمل المزيد
                  <img src={arrowcm} alt="arrow" />
                  </p>
                ) : (
                  ""
                )}
            </div>
          </div>
        </section>

        {/* modal */}
        <div
          className="modal fade bd-example-modal-lg"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="text-left px-2">
                <img
                  src={close}
                  id="closemodal"
                  alt="close"
                  className="mt-1 ml-0"
                  data-dismiss="modal"
                  style={{ width: 30 }}
                />
              </div>

              <div className="row m-0">
                <div className="col-md-10">
                  <div className="row ">
                    <div className="col-md-4 my-2">
                      <figure className="figure">
                        <img
                          src={LINKIMAGES + this.props.ProjectsDetails.project_image}
                          className=""
                          alt="project_image"
                          style={{
                            width: "100%",
                            height: 110,
                            objectFit: "contain"
                          }}
                        />
                      </figure>
                    </div>
                    <div className="col-md-8 ">
                      <p className="fz16 fw500 my-2">
                        {this.props.ProjectsDetails.project_ar_name}
                      </p>
                      <p className="borderdashed m-0"></p>
                      <div className="row my-1">
                        <p className="col-3 fz14 fw500 my-1 ">هدف الحملة</p>
                        <div className="col-8">
                          <span className="fw500 fz22  my-1">
                            {this.props.ProjectsDetails.project_amount}{" "}
                          </span>{" "}
                          <span className="fw500 my-1">ج.م</span>
                        </div>
                      </div>
                      <p className="borderdashed m-0"></p>
                      <div className="row mx-0  my-2">
                        <p className="col-3   fz14 fw500 my-1 px-0">
                          وسائل الدفع
                        </p>
                        <div className="col-8 px-0 fzcmuted">
                          <img
                            src={fundsblack}
                            style={{
                              height: 27,
                              width: 27,
                              objectFit: "contain"
                            }}
                            className="mx-2"
                          />
                          |
                          <img
                            src={creditcardblack}
                            style={{
                              height: 27,
                              width: 27,
                              objectFit: "contain"
                            }}
                            className="mx-2"
                          />
                          |
                          <img
                            src={smsblack}
                            style={{
                              height: 27,
                              width: 27,
                              objectFit: "contain"
                            }}
                            className="mx-2"
                          />
                        </div>
                      </div>
                    </div>
                    <p className=" fz12 fzcmuted px-4">
                      {this.props.ProjectsDetails.project_description}
                    </p>
                  </div>
                </div>
                <div className="col-md-2 text-center">
                  <p className="fz10 my-1 fw500">احد مشروعات</p>
                  <img
                    src={LINKIMAGES + this.props.ProjectsDetails.ngo_image}
                    style={{
                      width: 75,
                      height: 75,
                      objectFit: "contain",
                      border: " 1px solid #F1F1F1",
                      boxShadow: " 0px 3px 6px #00000029"
                    }}
                    alt="logotype"
                    className="m-auto"
                  />
                  <p className="fz10 fzcmuted  my-1 mt-2 ">
                    تاريخ انشاء المشروع
                  </p>
                  <p className="fz10 fw500 my-1">
                    {" "}
                    {moment(this.props.ProjectsDetails.created_at).format("LL")}
                  </p>
                </div>
              </div>
              <div className="px-4">
                <p className="borderdashed m-0"></p>
                <p className="fz16 fw500 my-1">ارقام تخص المشروع</p>
                <div className="row m-0 py-3">
                  <div className="col-sm-2  text-center">
                    <img
                      src={moneyblack}
                      style={{ width: 50, height: 50, objectFit: "contain" }}
                      alt="logotype"
                      className="m-auto"
                    />
                    <p className="fz22 fw500 m-0">
                      {this.props.ProjectsDetails.donations_collected}
                    </p>
                    <p className="fz14  fw500 m-0">تبرعات</p>
                  </div>
                  <div className="col-sm-2 text-center">
                    <img
                      src={enterpriseblack}
                      style={{ width: 50, height: 50, objectFit: "contain" }}
                      alt="logotype"
                      className="m-auto"
                    />
                    <p className="fz22 fw500 m-0">
                      {this.props.ProjectsDetails.units_completed}
                    </p>
                    <p className="fz14  fw500 m-0">وحدات مكتمله</p>
                  </div>
                </div>
                <p className="borderdashed m-0"></p>
                <div className="text-center">
                  {this.props.ProjectsDetails &&
                    <Link
                      to={"/CreateCampaign/" + this.props.ProjectsDetails.project_ar_name.replace(/ /g, "_") + "/" + this.props.ProjectsDetails.project_id}
                      onClick={this.handleclosemodle}
                      className="btn  px-md-5 px-2 mx-2  bgcm fzcwhite fw500  fz16 mt-4 mb-4"
                    >
                      <img
                        src={addwhiteicon}
                        alt="arrow"
                        style={{ width: 18 }}
                        className="mx-1"
                      />
                      انشئ حملة
                  </Link>}
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div >
    );
  }
}

const mapStateToProps = state => {
  return {
    Categories: state.Campaigns.Categories ? state.Campaigns.Categories : [],
    Projects: state.Campaigns.Projects ? state.Campaigns.Projects : [],
    // AllProjects:state.Campaigns.AllProjects?state.Campaigns.AllProjects:[],
    ProjectsDetails: state.Campaigns.ProjectsDetails
      ? state.Campaigns.ProjectsDetails[0]
      : "",
    total_projects: state.Campaigns.Projects
      ? state.Campaigns.Projects[0]
        ? state.Campaigns.Projects[0].total_projects
        : ""
      : 0,
    filters: state.NGOS.filters
  };
};
const mapDispatchToProps = {
  getcategories: getcategories,
  // getAllProjects:getAllProjects,
  getProjectsBasedonCategory: getProjectsBasedonCategory,
  resetProjectsBasedonCategory: resetProjectsBasedonCategory,
  getProjectsId: getProjectsId,
  getfilter: getfilter,
  thousands_separators
};
export default connect(mapStateToProps, mapDispatchToProps)(ProjectsListing);
