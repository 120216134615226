import React from "react";
import "./EditProfile.scss";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { userAction } from "../../redux/actions";
import Footer from "../Components/Footer/Footer";
import Visibility from "../../Images/Visibility.svg";
import close from "../../Images/close.svg";
import { LINKIMAGES } from "../../redux/actions/types";
class EditProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      info: {
        user_name: "",
        email: "",
        profile_pic: false,
        msisdn: "",
        hide_data: false
      }
    };
  }
  componentDidMount() {
    this.props.userInfo();
    this.setState({ info: this.props.user });
  }
  componentWillReceiveProps() { }
  handleChange = e => {
    this.setState({
      // ...this.state,
      info: {
        ...this.state.info,
        [e.target.name]: e.target.value
      }
    });
  };
  onChangeimage = e => {
    this.setState(
      {
        // ...this.state,
        info: {
          ...this.state.info,
          profile_pic: e.target.files[0],
          updateimg: true
        },
        sizeimage: e.target.files[0].size
      },
      () => this.state.info
    );
  };
  handlehide_data = () => {
    this.setState(
      {
        info: { ...this.state.info, hide_data: !this.state.info.hide_data }
      });
  };
  validate = () => {
    let erroruser_name = "";
    let errormsisdn = "";
    let errorsizeimage = "";

    if (this.state.info.user_name.length < 2) {
      erroruser_name = "ادخل الاسم  صحيح";
    }
    if (this.state.sizeimage > 3e6) {
      errorsizeimage = "MP3 يجب ان تكون مساحة الصوره اقل من  ";
    }
    if (!this.state.info.profile_pic) {
      errorsizeimage = "يجب وضع صوره ";
    }
    if (isNaN(this.state.info.msisdn)) {
      errormsisdn = "رقم الهاتف غير صحيح يجب ان يبداء ب *********01";
    }
    if (this.state.info.msisdn) {
      if (this.state.info.msisdn.length > 0) {
        if (this.state.info.msisdn.length !== 11) {
          errormsisdn = "رقم الهاتف غير صحيح يجب ان يبداء ب *********01";
        }
      }
    }
    if (erroruser_name || errormsisdn || errorsizeimage) {
      this.setState({ erroruser_name, errormsisdn, errorsizeimage });
      return false;
    }
    return true;
  };
  handlesubmit = e => {
    e.preventDefault();
    const isValid = this.validate();
    if (isValid) {
      this.props.UserUpdate(
        this.state.info.user_name,
        this.state.info.msisdn,
        this.state.info.hide_data,
        this.state.info.profile_pic
      );
      // this.props.userInfo()
      this.setState({
        erroruser_name: "",
        errormsisdn: ""
      });
    }
  };
  render() {
    document.title = "InMemoryOf | Edit Profile";
    document.getElementsByTagName("META")[1].content =
      "IN MEMORY OF was created to help family and friends commemorate their loved ones through memorial donations";
    document.documentElement.lang = "en";

    return (
      <div className="EditProfile">
        <form onSubmit={this.handlesubmit}>
          <div className="navhe"></div>
          <div className="container">
            <div className="py-2">
              <Link to="" className="fz12 fzcm px-2 fw500">
                الرئيسية
              </Link>
              <span className="fz12 fw500 mt-2">&gt; </span>
              <Link to="" className="fz12 fzcm px-2 fw500">
                حسابى
              </Link>
              <span className="fz12 fw500 mt-2">&gt; </span>
            </div>
            <p className="borderdashed m-0"></p>
            <h3 className="fzcblack black fz24 fw500 py-3">اعدادات الحساب</h3>
          </div>
          <section className="personaldata">
            <div className="container">
              <p className="fz18 fw500 ">البيانات الشخصية</p>
              <div className="row m-0">
                <div className="col-md-5">
                  <div className="form-group  m-0 py-1 row">
                    <label
                      htmlFor="user_name"
                      className="col-md-3 px-0 col-form-label d-flex justify-content-between"
                    >
                      الاسم<span className="require">*</span>
                    </label>
                    <div className="col-sm-9 col-md-8 d-flex align-items-center">
                      <input
                        type="text"
                        className="form-control"
                        id="user_name"
                        placeholder=""
                        name="user_name"
                        value={this.state.info.user_name}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div
                      style={{ fontSize: 12, color: "red" }}
                      className="py-1 m-0"
                    >
                      {this.state.erroruser_name}
                    </div>
                  </div>

                  <div className="form-group  m-0 py-1 row">
                    <label
                      htmlFor="email"
                      className="col-md-3 px-0 col-form-label d-flex justify-content-between"
                    >
                      البريد الالكترونى <span className="require">*</span>
                    </label>
                    <div className="col-sm-9 col-md-8 d-flex align-items-center">
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder=""
                        value={this.state.info.email}
                        disabled
                      />
                    </div>
                  </div>
                  {/* <div className="form-group  m-0 py-1 row">
                              <label htmlFor="confirmpassword" className="col-md-3 px-0 col-form-label d-flex justify-content-between">اللغة<span className="require">*</span></label>
                              <div className="col-sm-9 col-md-8 d-flex align-items-center">
                              <select  className="form-control" >
                                 <option>cairo</option>
                              </select>
                              </div>
                        </div> */}
                </div>
                <div className="col-md-5">
                  <form>
                    <div className="form-group  m-0 py-1 row pb-2" >
                      <label
                        htmlFor="msisdn"
                        className="col-md-3 px-0 col-form-label d-flex justify-content-between"
                      >
                        الهاتف<span className="require">*</span>
                      </label>
                      <div className="col-sm-9 col-md-8 d-flex align-items-center">
                        <input
                          type="text"
                          className="form-control"
                          id="msisdn"
                          placeholder=""
                          name="msisdn"
                          value={this.state.info.msisdn}
                          onChange={this.handleChange}
                        />
                      </div>

                      <div
                        style={{ fontSize: 12, color: "red" }}
                        className="py-1 m-0"
                      >
                        {this.state.errormsisdn}
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-lg-2 mq-sm-center mt-2">
                  <figure className="figure m-0">
                    <img
                      src={
                        this.state.info.updateimg
                          ? URL.createObjectURL(this.state.info.profile_pic)
                          : LINKIMAGES + this.state.info.profile_pic
                      }
                      className="figure-img img-fluid rounded-circle "
                      alt="profile image"
                      style={{
                        width: "120px",
                        height: 120,
                        objectFit: "contain",
                        boxShadow: "0px 0px 25px #E1E1E1"
                      }}
                    />
                    <p
                      className="fzcm fz18 underline text-center fw500 pointer"
                      onClick={() =>
                        document.getElementById("profile_pic").click()
                      }
                    >
                      تعديل
                    </p>
                    <input
                      type="file"
                      name="profile_pic"
                      id="profile_pic"
                      onChange={this.onChangeimage}
                      style={{ display: "none" }}
                    />
                  </figure>
                  <div
                    style={{ fontSize: 12, color: "red" }}
                    className="py-1 m-0"
                  >
                    {this.state.errorsizeimage}
                  </div>
                </div>
              </div>
              <p className="borderdashed"></p>
            </div>
          </section>

          <section className="changeinfo">
            <div className="container">
              <div className="row m-0 py-3 d-flex justify-content-between">
                <div className="col-md-5">
                  <p className="fz18 fw500 m-0  mb-1">مسح الحساب</p>
                  <p className="fzcmuted fz12 m-0 fw500">
                    مسح حسابك سوف يؤدى الى فقد كل بيانات حملاتك و تبرعاتك و لن
                    تستطيع الدخول باستخدام بيانات للموقع مره اخرى
                  </p>
                  <div
                    className="modal fade ModalSuccess"
                    id="exampleModal"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="exampleModal"
                    aria-hidden="true"
                  >
                    <div
                      className="modal-dialog  modal-dialog-scrollable"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-body">
                          <div className="text-left">
                            <img
                              src={close}
                              alt="close"
                              className="mt-1 ml-0"
                              data-dismiss="modal"
                              id="closemodelafterdeleteuser"
                              style={{ width: 30 }}
                            />
                          </div>
                          <div className="modal-body text-center  p-0">
                            <p className=" fzcm fz20 fw500">
                              {" "}
                              هل انت متاكد من مسح الحساب
                            </p>
                            <p className="fzcmuted fz12 fw500">
                              مسح حسابك سوف يؤدى الى فقد كل بيانات حملاتك و
                              تبرعاتك و لن تستطيع الدخول باستخدام بيانات للموقع
                              مره اخرى
                            </p>
                            <div>
                              <button type="button"
                                onClick={this.props.Deleteuser}
                                className=" btn bgcm w49 fzcwhite fz20 px-4 my-4 fw500"
                              >
                                نعم
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-5 text-left center-v">
                  <p
                    className="fz14 fw500 underline fw500"
                    style={{ color: "#FF0000" }}
                    data-toggle="modal"
                    data-target="#exampleModal"
                  >
                    مسح الحساب نهائياً
                  </p>
                </div>
              </div>
              <p className="borderdashed  m-1"></p>
              <div className="row m-0 py-3 d-flex justify-content-between">
                <div className="col-md-5">
                  <p className="fz18 fw500 m-0  mb-1">
                    اظهار بياناتى على الموقع
                  </p>
                  <p className="fzcmuted fz12 m-0 fw500">
                    تلقائيا يظهر اسمك و صورتك بجوار قيمة تبرعاتك للجميع على
                    الموقع , فإذا كنت تريد إخفاء بياناتك و ان تكون تبرعاتك باسم
                    فاعل خير فقم بإطفاء علامة الرؤية
                  </p>
                </div>
                <div className="col-md-5 text-left center-v">
                  <img
                    src={Visibility}
                    className={
                      "figure-img img-fluid m-1 " +
                      (!this.state.info.hide_data ? " " : " Cmuted")
                    }
                    onClick={this.handlehide_data}
                    alt="twitterwhite"
                    style={{ width: 30, height: 30, objectFit: "contain" }}
                  />
                </div>
              </div>
              <p className="borderdashed m-1"></p>
            </div>
          </section>

          <div className="container text-center">
            <button
              type="submit"
              className="btn text-center stylelink fzcm bordercm fw500 px-5 fz20 mb-4 mt-4"
            >
              حفظ الاعدادات
            </button>
          </div>
        </form>

        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.User.user
  };
};
const mapDispatchToProps = {
  userInfo: userAction.userInfo,
  UserUpdate: userAction.UserUpdate,
  Deleteuser: userAction.Deleteuser
};
export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
