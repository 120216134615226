import {
  FETCH_5CAMPAIGNS,
  FETCH_ALL_CAMPAIGNS,
  FETCH_CATEGORIES,
  FETCH_CATEGORY_PROJECTS,
  FETCH_ALL_PROJECTS,
  FETCH_PROJECTS_ID,
  FETCH_CAMPAIGN_DETAILS,
  FETCH_CAMPAIGN_DONATIONS_DETAILS,
  RESET_ALL_PROJECTS,
  FETCH_ALL_PROJECTS2,
  CREATE_DONATION,
  FETCH_CITIES,
  FETCH_AREAS,
  LOADING,
  GETMESSAGE,
  RESET_ALL_CAMPAIGNS,
  RESET_CATEGORY_PROJECTS
} from "../actions/types";
const initialState = {
  data5Campaigns: [],
  AllCampaigns: [],
  AllProjects: [],
  Projects: [],
  Areas: [],
  cities: [],

}
export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_CAMPAIGNS:
      return {
        ...state,
        AllCampaigns: state.AllCampaigns.concat(action.payload)
      };
    case RESET_ALL_CAMPAIGNS:
      return {
        ...state,
        AllCampaigns: []
      };
    case FETCH_5CAMPAIGNS:
      return {
        ...state,
        data5Campaigns: action.payload
      };
    case FETCH_CATEGORIES:
      return {
        ...state,
        Categories: action.payload
      };
    case FETCH_CATEGORY_PROJECTS:
      return {
        ...state,
        Projects: state.Projects.concat(action.payload)
      };
    case RESET_CATEGORY_PROJECTS:
      return {
        ...state,
        Projects: []
      };
    case FETCH_ALL_PROJECTS:
      return {
        ...state,
        AllProjects: state.AllProjects.concat(action.payload)
      };
    case FETCH_ALL_PROJECTS2:
      return {
        ...state,
        AllProjects: action.payload
      };
    case FETCH_PROJECTS_ID:
      return {
        ...state,
        ProjectsDetails: action.payload
      };
    case FETCH_CAMPAIGN_DETAILS:
      return {
        ...state,
        campaignDetails: action.payload
      };
    case FETCH_CAMPAIGN_DONATIONS_DETAILS:
      return {
        ...state,
        campaignDonationsDetails: action.payload
      };

    case RESET_ALL_PROJECTS:
      return {
        ...state,
        AllProjects: []

      };
    case CREATE_DONATION:
      return {
        ...state,
        resCreateDonation: action.payload

      };
    case FETCH_CITIES:
      return {
        ...state,
        cities: action.payload

      };
    case FETCH_AREAS:
      return {
        ...state,
        Areas: action.payload

      };
    case LOADING:
      return {
        ...state,
        Loading: action.payload
      }
    case GETMESSAGE:
      return {
        ...state,
        messagedata: { ...state.messagedata, ...action.payload }
      }
    default:
      return state;
  }
};
