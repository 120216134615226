import React from 'react';
import './ChooseCampaign.scss';
import { Link } from "react-router-dom";
import prearrow from '../../Images/prearrow.svg'
import { getcategories, getProjectsBasedonCategory, getAllProjects2, resetProject, resetProjectsBasedonCategory, thousands_separators } from '../../redux/actions'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Footer from '../Components/Footer/Footer'
import Steps from '../Components/Steps/Steps'
import Showbycategory from './Showbycategory'
import Showall from './Showall'
import { connect } from 'react-redux';
import { getfilter } from "../../redux/actions";


class ChooseCampaign extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showall: false,
      CampaignTypeSelected: false,
      project_id: false,
      filterselected: "default",
    };
    this.handleChangeselect = this.handleChangeselect.bind(this);
  }
  componentDidMount() {
    this.props.getcategories()
    this.props.getAllProjects2(0, "", "default")
    this.props.getfilter("/ngos/projects");

  }

  handleshowstyle = () => {
    this.setState({ showall: !this.state.showall })
  }
  handleshowProjects = (id) => {
    // this.props.resetProjectsBasedonCategory()
    this.props.getProjectsBasedonCategory(id, 0, "", "default", "", true)
    this.setState({ CampaignTypeSelected: true })
  }
  // setProjectId = (id) => {
  //   this.setState({ project_id: id })
  // }
  // setProjectId2 = (id) => {
  //   this.setState({ project_id: id })
  //   this.props.getAllProjects2(0, "", this.state.filterselected)

  // }
  handleChangeselect(e) {
    this.props.resetProjectsBasedonCategory()
    this.setState(
      {
        filterselected: e.target.value,
      },
      this.props.getAllProjects2(
        0, "",
        e.target.value
      )
    );
  }
  render() {
    document.title = "InMemoryOf | Choose Campaign";
    document.getElementsByTagName("META")[1].content = "IN MEMORY OF was created to help family and friends commemorate their loved ones through memorial donations";
    document.documentElement.lang = 'en';
    return (
      <div className="ChooseCampaign">
        <div className="navhe"></div>
        <div className="container">
          <div className="py-2">
            <Link to="" className="fz12 fzcm px-2 fw500">الرئيسية</Link>
            <span className="fz12 fw500 mt-2">&gt;	</span>
            <Link to="/Campaigns" className="fz12 fzcm px-2 fw500">اكتشف</Link>
            <span className="fz12 fw500 mt-2">&gt;	</span>
          </div>
          <p className="borderdashed m-0"></p>
          <h3 className="fzcblack black fz24 fw500 py-3 ">ابدأ حملة تبرعات</h3>
        </div>
        <div className="container">
          <Steps step={1} />
        </div>
        <section className="typesDonations">
          <div className="btnshow m-auto text-center py-3 container row m-0 ">
            <div className="col-md-4"></div>
            <div className="col-md-4 mb-2">
              <span className="  align-items-end">
                <button className={"btn fz20 px-3 left " + (this.state.showall ? "active" : '')} onClick={this.handleshowstyle}>عرض الكل</button>
                <button className={"btn fz20 px-3 right " + (this.state.showall ? "" : 'active')} onClick={this.handleshowstyle}>حسب الفئة</button>
              </span>
            </div>
            <div className="col-md-4 d-md-flex justify-content-end align-items-center mq-sm-center mx-auto">
              {this.state.showall ?
                <select className="fzcmuted mq-w75"
                  value={this.state.filterselected}
                  onChange={this.handleChangeselect}>

                  {this.props.filters
                    ? this.props.filters.map((x, i) => {
                      return <option key={i} value={x.value}>{x.filter_type}</option>;
                    })
                    : ""}
                </select> : ''}
            </div>
          </div>
          {this.state.showall ? <Showall AllProjects={this.props.AllProjects} project_id={this.state.project_id} thousands_separators={this.props.thousands_separators} /> :
            <Showbycategory resetProjectsBasedonCategory={this.props.resetProjectsBasedonCategory} project_id={this.state.project_id} Categories={this.props.Categories} thousands_separators={this.props.thousands_separators} Projects={this.props.Projects} CampaignTypeSelected={this.state.CampaignTypeSelected} handleshowProjects={this.handleshowProjects} />}


        </section>
        {/* <div className="text-center py-3">
          {this.state.project_id ?
            <Link to={"/CreateCampaign/" + this.state.project_id} className={"btn btnw bgcm fzcwhite fw500 px-4 fz20 text-center " + (this.state.project_id ? "animationslidershow2" : "animationsliderhide2")} >التالي
                    <img src={prearrow} className="" style={{ width: 25 }} alt="arrow" />
            </Link>
            : ""}
        </div> */}
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    Categories: state.Campaigns.Categories ? state.Campaigns.Categories : [],
    Projects: state.Campaigns.Projects ? state.Campaigns.Projects : [],
    AllProjects: state.Campaigns.AllProjects ? state.Campaigns.AllProjects : [],
    filters: state.NGOS.filters

  };
}
const mapDispatchToProps = ({
  getcategories: getcategories,
  getAllProjects2: getAllProjects2,
  getProjectsBasedonCategory: getProjectsBasedonCategory,
  resetProjectsBasedonCategory: resetProjectsBasedonCategory,
  resetProject: resetProject,
  getfilter: getfilter,
  thousands_separators

})
export default connect(mapStateToProps, mapDispatchToProps)(ChooseCampaign);